import { HeatingLoadBuildingResult } from '@/features/math/calculateBuildingHeatLoad'
import { calculateFactorSperr } from '@/features/math/factor/calculateFactorSperr'

interface HeatingLoadBuildingProps {
  // gebäudeheizlast
  qH_BuildingHeatingLoad: number
  // dauert der Sperrzeiten see https://www.westnetz.de/de/faq/waermepumpen.html
  hoursDisabled: number
  qWW: number
}

export const calculateHeatingLoadKnownLoadMultiFamilyWithWarmWater = ({
  qH_BuildingHeatingLoad: qH,
  hoursDisabled,
  qWW,
}: HeatingLoadBuildingProps): HeatingLoadBuildingResult => {
  console.log('calculateHeatingLoadKnownLoadSingleFamilyWithWarmWater', {
    qH_BuildingHeatingLoad: qH,
    hoursDisabled: hoursDisabled,
    qWW: qWW,
  })
  let qwp = 0
  const fSperr = calculateFactorSperr({ hoursDisabled })

  // Ich kenne die Gebäudeheizlast
  // Eingabe: qH = 1-50[kW]
  if (!qH || qH < 1) {
    throw Error('needs buildingHeatingLoad (qH)')
  }

  // QWP[W] = qww + (fsperr x qH[kW] x 1000)
  console.debug({
    fSperr: fSperr,
    qH: qH,
    qWW: qWW,
  })
  qwp = qWW * 1000 + fSperr * qH * 1000

  // convert to kW -> QWP[W]÷ 1000
  qwp = qwp / 1000

  return {
    kW: parseFloat('' + qwp.toFixed(3)),
  }
}
