import { WarningIcon } from '@chakra-ui/icons'
import type { FormControlProps } from '@chakra-ui/react'
import { FormControl, FormErrorMessage, FormLabel, Text } from '@chakra-ui/react'

/**
 *  wrap a simple child (field)
 */
export interface FormGroupPropsInterface extends FormControlProps {
  label?: string
  errorMessage?: string
  helper?: string
  showError?: boolean
}

export const FormGroup = ({
  children,
  errorMessage,
  helper,
  id,
  isRequired,
  label,
  showError,
  ...props
}: FormGroupPropsInterface) => {
  return (
    <FormControl mb="6" mt="2" isInvalid={showError} isRequired={isRequired} {...props}>
      {!!label && <FormLabel htmlFor={id}>{label}</FormLabel>}
      {!!helper && (
        <Text color="gray.400" fontSize="sm" mt="0" mb="3">
          {helper}
        </Text>
      )}
      {children}
      <FormErrorMessage id={`${id}-error`}>
        <WarningIcon mr="2" />
        {errorMessage}
      </FormErrorMessage>
    </FormControl>
  )
}
