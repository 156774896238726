import { createEntityAdapter } from '@reduxjs/toolkit'
import type { TagDescription } from '@reduxjs/toolkit/query/react'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

const postsAdapter = createEntityAdapter({
  sortComparer: (a: HeadPumpConfiguratorTemperature, b: HeadPumpConfiguratorTemperature) => b.zip.localeCompare(a.zip),
})

const initialState = postsAdapter.getInitialState()

export interface HeadPumpConfiguratorTemperature {
  id?: number
  zip: string
  city: string
  temperature: number
  temperatureAnnualMeanOutdoor: number
  altitude: number
  tryRegion: number
  comment: string
}

export const standardTemperatureApiSlice = createApi({
  reducerPath: 'standardTemperatureApiSlice',
  baseQuery: fetchBaseQuery({
    baseUrl: import.meta.env.VITE_API_ENDPOINT_ZIP_CODES_TO_TEMPERATURE,
  }),
  tagTypes: ['NormOutsideTemp'],
  keepUnusedDataFor: 999999,
  endpoints: (builder) => ({
    getTemperaturesByZip: builder.query({
      query: (zip) => {
        let prefix = 'filter_zip'
        if (import.meta.env.MODE === 'ci' || import.meta.env.MODE === 'test') {
          // we use "zip_like" because json server hand filter results!
          prefix = 'zip_like'
        }

        return `?${prefix}=${zip}`
      },
      transformResponse: (responseData: HeadPumpConfiguratorTemperature[]) => {
        const loadedPosts = responseData.map((post) => {
          // inject zip as ID
          if (!post?.id) post.id = parseInt(post.zip)
          return post
        })
        return postsAdapter.setAll(initialState, loadedPosts)
      },

      providesTags: (result): readonly TagDescription<'NormOutsideTemp'>[] => {
        if (typeof result === 'undefined') {
          throw new Error('result is broken')
        }
        return [
          { type: 'NormOutsideTemp', id: 'LIST' },
          ...result.ids.map((id): { id: number | string; type: 'NormOutsideTemp' } => ({
            type: 'NormOutsideTemp',
            id,
          })),
        ]
      },
    }),
  }),
})

export const { useGetTemperaturesByZipQuery, useLazyGetTemperaturesByZipQuery } = standardTemperatureApiSlice
