import { HeatPumpInterface } from '@/store/features/heatPumpApiSlice'
import { HeatPumpPackageInterface } from '@/store/features/heatPumpPackagesApiSlice'
import type { EntityState, PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

const initialState: PayloadInterface = {
  heatPumpPackages: [],
  relevantPumps: [],
}
interface PayloadInterface {
  heatPumpPackages: HeatPumpPackageInterface[] | EntityState<HeatPumpPackageInterface>
  relevantPumps: HeatPumpInterface[]
}
const heatPumpPackagesSlice = createSlice({
  name: 'heatPumpPackagesSlice',
  initialState: initialState,
  reducers: {
    setHeatPumpPackages: (state, param: PayloadAction<PayloadInterface>) => {
      state.heatPumpPackages = param.payload.heatPumpPackages
      state.relevantPumps = param.payload.relevantPumps
    },
  },
})

const { actions, reducer } = heatPumpPackagesSlice
export const { setHeatPumpPackages } = actions
export default reducer
