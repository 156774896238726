import { HeatPumpPackageInterface } from '@/store/features/heatPumpPackagesApiSlice'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react'

interface ApiResponse {
  items: HeatPumpPackageDetailsInterface[]
  wishlist: string
  cart_url: string
  categories: []
}

export interface Links {
  normal: string
}

export interface Manufacturer {
  id: number | null
  name: string
  image: string
  imageSmall: string
}

export interface Images {
  thumb: string
  normal: string
  big: string
}

export interface Stock {
  count: number
  limit: number | null
  shipping_time: number
  next_shipping_date: string | null
  next_shipping_is_confirmed: boolean
  next_shipping_message: string | null
  next_shipping_message2: string | null
}

export interface Prices {
  currency: string
  catalog: number
  customer?: number | null
}

export interface HeatPumpPackageDetailsInterface {
  id: number
  available: boolean
  purchasable: boolean
  external_id: string
  title: string
  title_html: string
  description: string
  links: Links
  manufacturer: Manufacturer
  bom: boolean
  images: Images
  stock: Stock
  is_plus_deal: boolean
  hazard_material: boolean
  prices: Prices
  vpe?: number
  key_attributes: string[]
}

// const pumpSetDetailsAdapter = createEntityAdapter({
//   sortComparer: (a: HeatPumpPackageDetailsInterface, b: HeatPumpPackageDetailsInterface) =>
//     b.external_id.localeCompare(a.external_id),
// })
// const initialState = pumpSetDetailsAdapter.getInitialState()

export const heatPumpPackagesDetailsApiSlice = createApi({
  reducerPath: 'heatPumpPackagesDetailsApiSlice',
  baseQuery: fetchBaseQuery({
    baseUrl: import.meta.env.VITE_API_ENDPOINT_HEAT_PUMP_SETS_DETAILS,
    credentials: 'include',
  }),
  tagTypes: ['HeatPumpSetDetails'],
  keepUnusedDataFor: 999999,
  endpoints: (builder) => ({
    getHeatPumpPackagesDetailsByHeatPumpPackages: builder.query<
      HeatPumpPackageDetailsInterface[],
      HeatPumpPackageInterface[]
    >({
      query: (heatPumpSets: HeatPumpPackageInterface[]) => {
        return {
          method: 'GET',
          // http://localhost:3000/heat-pump-packs?articleId[]=91704&articleId[]=91841&articleId[]=91847&articleId[]=91851&articleId[]=91708&articleId[]=91862&articleId[]=91722&articleId[]=91887&articleId[]=91889&articleId[]=91893&articleId[]=91901&articleId[]=91771&articleId[]=91943&articleId[]=91946&articleId[]=91952&articleId[]=91958&articleId[]=91774&articleId[]=91777&articleId[]=91785&articleId[]=92007&articleId[]=92012&articleId[]=92015&articleId[]=92019&articleId[]=91789&articleId[]=92207&articleId[]=92208&articleId[]=92211&articleId[]=92214&articleId[]=92217&articleId[]=92241&articleId[]=92244&articleId[]=92246&articleId[]=92247&articleId[]=92250&articleId[]=92276&articleId[]=92277&articleId[]=92280&articleId[]=92282&articleId[]=92315&articleId[]=92316&articleId[]=92319
          url: '?articleId[]=' + heatPumpSets.map((item) => item.productId).join('&articleId[]='),
          headers: {
            accept: 'application/json',
          },
        }
      },
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      transformResponse(baseQueryReturnValue: ApiResponse) {
        if (baseQueryReturnValue.items === null) {
          console.warn('getHeatPumpPackagesDetailsByHeatPumpPackages: no details items in api response')
        }
        return baseQueryReturnValue.items ?? [] //pumpSetDetailsAdapter.setAll(initialState, baseQueryReturnValue.items)
      },
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: 'HeatPumpSetDetails' as const, id })),
              { type: 'HeatPumpSetDetails', id: 'LIST' },
            ]
          : [{ type: 'HeatPumpSetDetails', id: 'LIST' }],
    }),
  }),
})

export const { useLazyGetHeatPumpPackagesDetailsByHeatPumpPackagesQuery } = heatPumpPackagesDetailsApiSlice
