import { scrollParentWindowToTop } from '@/features/helper/iFrameHelper'
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons'
import type { ButtonProps, StackProps } from '@chakra-ui/react'
import { Box, Button, Grid, Heading, Stack } from '@chakra-ui/react'
import type { Step } from '@formiz/core'
import { useForm } from '@formiz/core'

const PreviousButton = (props: ButtonProps) => {
  const form = useForm({ subscribe: 'form' })

  if (form.isFirstStep) {
    return <Box />
  }

  return (
    <Button
      name={'Back'}
      size="sm"
      onClick={() => {
        form.prevStep()
        scrollParentWindowToTop()
      }}
      variant="ghost"
      className={'stepper-back'}
      {...props}
    >
      <ChevronLeftIcon data-testid={'btn_back'} color={'#fff'} rounded={'full'} boxSize={8} bgColor={'brand.500'} />
    </Button>
  )
}

const NextButton = (props: ButtonProps) => {
  const form = useForm({ subscribe: 'form' })
  return (
    <Button
      onClick={() => {
        scrollParentWindowToTop()
      }}
      type="submit"
      size="sm"
      // colorScheme="brand"
      data-testid={'btn_next'}
      name={'Next'}
      className={form.isLastStep ? 'stepper-next stepper-submit' : 'stepper-next'}
      isDisabled={form.isLastStep ? true : !form.isStepValid}
      {...props}
    >
      <ChevronRightIcon rounded={'full'} boxSize={8} color={'#fff'} bgColor={'brand.500'} />
    </Button>
  )
}

export const StepperWrapper = ({ title, children, ...rest }: StackProps) => (
  <Stack {...rest} className={'stepper-wrapper'}>
    {title && <Heading fontSize="md">{title}</Heading>}
    <Box bg="gray.50" p="4" borderRadius="md">
      <Grid templateColumns="1fr 2fr 1fr" alignItems="center">
        <Box>
          <PreviousButton />
        </Box>
        {children}
        <Box textAlign="right">
          <NextButton />
        </Box>
      </Grid>
    </Box>
  </Stack>
)

export interface StepMatrixInterface {
  [key: number]: StepMatrixGroupsInterface
}

export interface StepMatrixGroupsInterface {
  stepNames: string[]
  fieldNames: string[]
  groupName: string
}

interface StepMatrixResultItemInterface {
  groupId: number
  step: Step
}

export interface StepMatrixResultInterface {
  [key: string]: StepMatrixResultItemInterface[]
}
