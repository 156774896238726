import AppConfig from '@/app/AppConfig'
import { FormFieldNameEnum } from '@/features/fields/FormFieldNameEnum'
import { calculateWarmWaterHeatingPersons } from '@/features/math/warmWater/calculateWarmWaterHeatingPersons'
import { addFormGroup } from '@/store/features/formGroupsSlice'
import { setFilterOption } from '@/store/features/heatPumpSetFilterSlice'
import { useAppDispatch } from '@/store/store'
import { FormizStep, useForm } from '@formiz/core'
import { UseFieldProps } from '@formiz/core/dist/types/field.types'
import { isInRangeNumber } from '@formiz/validations'
import { useEffect } from 'react'
import { injectIntl } from 'react-intl'
import { IntlShape } from 'react-intl/src/types'
import IconNo from '../../assets/icons/oeg-konfigurator-icon-nein.svg'
import IconYesSolarWW from '../../assets/icons/oeg-konfigurator-icon-solarthermie.svg'
import IconYesWW from '../../assets/icons/oeg-konfigurator-icon-warmwasser.svg'
import { EditableFiledWithWrapper } from '../fields/EditableFiledWithWrapper'
import { SelectSingleOptionWithRadioCards } from '../fields/SelectSingleOptionWithRadioCards'
import { BuildingTypesKeysEnum } from './StepChooseBuildingType'
import { StepMatrixInterface } from '@/features/layout/footer/StepperWrapper'
import { formResetter } from '@/features/helper/formResetter'

interface StepWarmWaterProductionPropsInterface extends UseFieldProps {
  intl: IntlShape
  isVisible: boolean
  stepMatrix: StepMatrixInterface
  stepGroup: number
}

const StepWarmWaterProduction = (props: StepWarmWaterProductionPropsInterface) => {
  const { name, isVisible, intl, stepMatrix, stepGroup } = props
  const label = intl.formatMessage({ id: 'Trinkwassererwärmung' })
  const myForm = useForm()

  const dispatch = useAppDispatch()
  useEffect(() => {
    if (isVisible) {
      const fields = [FormFieldNameEnum.selectUseToGenerateWarmWater]

      if (
        myForm.flatValues.selectUseToGenerateWarmWater === 'yes' &&
        myForm.flatValues[FormFieldNameEnum.selectBuildingType] === BuildingTypesKeysEnum.singleFamilyHouse
      ) {
        fields.push(FormFieldNameEnum.warmWaterNumberOfPersonsPerBuilding)
        fields.push(FormFieldNameEnum.selectUseWarmWaterSolarHeatingSupport)
      }

      if (
        myForm.flatValues.selectUseToGenerateWarmWater === 'yes' &&
        myForm.flatValues[FormFieldNameEnum.selectBuildingType] === BuildingTypesKeysEnum.multiFamilyHouse
      ) {
        fields.push(FormFieldNameEnum.warmWaterNumberOfPersonsPerResidence)
        fields.push(FormFieldNameEnum.selectUseWarmWaterSolarHeatingSupport)
      }

      if (
        myForm.flatValues.selectUseToGenerateWarmWater === 'yes' &&
        myForm.flatValues[FormFieldNameEnum.selectBuildingType] === BuildingTypesKeysEnum.nonResidentialBuilding
      ) {
        fields.push(FormFieldNameEnum.warmWaterNumberHeatingLoadQwwDay)
        fields.push(FormFieldNameEnum.warmWaterNumberHeatingLoadQwwDayHours)
      }

      dispatch(
        addFormGroup({
          name: name,
          label: label,
          fields: fields,
        })
      )
    }
  }, [dispatch, isVisible, name, label, myForm.flatValues])

  const isNoOptionDisabled = () =>
    myForm.flatValues[FormFieldNameEnum.knownGasOrOilOrPowerConsumeValueIncludesWarmWater] === 'yes'

  return (
    <FormizStep name={name} label={label} isEnabled={isVisible} data-testid={name}>
      <SelectSingleOptionWithRadioCards
        label={intl.formatMessage({ id: 'Soll das System auch für die Trinkwassererwärmung verwendet werden?' })}
        required={intl.formatMessage({ id: 'Bitte wählen' })}
        name={FormFieldNameEnum.selectUseToGenerateWarmWater}
        injectOnChange={(value) => {
          dispatch(
            setFilterOption({
              name: 'filterOptionDrinkingWaterHeating',
              value: value === 'yes',
            })
          )

          // this is a special case:
          // we need to reset filter and values after this change
          if (value === 'no') {
            formResetter(
              stepMatrix,
              myForm.values,
              [stepGroup, stepGroup + 1],
              FormFieldNameEnum.selectUseToGenerateWarmWater,
              value,
              (newValues, value) => {
                newValues[FormFieldNameEnum.selectUseToGenerateWarmWater] = value
                myForm.setFieldsValues(newValues)

                dispatch(
                  setFilterOption({
                    name: 'maxPersonsDrinkingWater',
                    value: 0,
                  })
                )

                dispatch(
                  setFilterOption({
                    name: 'filterOptionAdditionalDrinkingWaterHeating',
                    value: false,
                  })
                )
              }
            )
          }
        }}
        autoSubmitStepValue={'no'}
        options={[
          {
            imageComponent: IconYesWW,
            value: 'yes',
            label: intl.formatMessage({ id: 'Ja' }),
          },
          {
            imageComponent: IconNo,
            value: 'no',
            label: intl.formatMessage({ id: 'Nein' }),
            isDisabled: isNoOptionDisabled(),
          },
        ]}
      />
      {/*  wenn ja: */}
      {myForm.flatValues.selectUseToGenerateWarmWater === 'yes' &&
      myForm.flatValues[FormFieldNameEnum.selectBuildingType] === BuildingTypesKeysEnum.singleFamilyHouse ? (
        <EditableFiledWithWrapper
          name={FormFieldNameEnum.warmWaterNumberOfPersonsPerBuilding}
          headline={intl.formatMessage({ id: 'Wie viele Personen wohnen insgesamt im Gebäude?' })}
          label={intl.formatMessage({ id: 'Anzahl der Personen' })}
          required={intl.formatMessage({ id: 'Pflichtangabe' })}
          minValue={AppConfig.form.warmWaterNumberOfPersonsPerBuildingMin}
          maxValue={AppConfig.form.warmWaterNumberOfPersonsPerBuildingMax}
          type={'number'}
          placeholder={'0'}
          validations={[
            {
              rule: isInRangeNumber(
                AppConfig.form.warmWaterNumberOfPersonsPerBuildingMin,
                AppConfig.form.warmWaterNumberOfPersonsPerBuildingMax
              ),
              message: intl.formatMessage(
                { id: 'Die Anzahl der Personen sollte zwischen {s} und {s1} liegen' },
                {
                  s: AppConfig.form.warmWaterNumberOfPersonsPerBuildingMin,
                  s1: AppConfig.form.warmWaterNumberOfPersonsPerBuildingMax,
                }
              ),
            },
          ]}
          injectedOnChange={(value) => {
            dispatch(
              setFilterOption({
                name: 'maxPersonsDrinkingWater',
                value: value,
              })
            )
          }}
        />
      ) : null}

      {myForm.flatValues.selectUseToGenerateWarmWater === 'yes' &&
      myForm.flatValues[FormFieldNameEnum.selectBuildingType] === BuildingTypesKeysEnum.multiFamilyHouse ? (
        <EditableFiledWithWrapper
          name={FormFieldNameEnum.warmWaterNumberOfPersonsPerResidence}
          headline={intl.formatMessage({
            id: 'Wie viele Personen wohnen durchschnittlich in den einzelnen Wohnungen?',
          })}
          label={intl.formatMessage({ id: 'Durchschnittlich Anzahl pro Wohnung' })}
          required={intl.formatMessage({ id: 'Pflichtangabe' })}
          minValue={AppConfig.form.warmWaterNumberOfPersonsPerResidenceMin}
          maxValue={AppConfig.form.warmWaterNumberOfPersonsPerResidenceMax}
          type={'number'}
          placeholder={'0'}
          validations={[
            {
              rule: isInRangeNumber(
                AppConfig.form.warmWaterNumberOfPersonsPerResidenceMin,
                AppConfig.form.warmWaterNumberOfPersonsPerResidenceMax
              ),
              message: intl.formatMessage(
                { id: 'Die Anzahl der Personen sollte zwischen {s} und {s1} liegen' },
                {
                  s: AppConfig.form.warmWaterNumberOfPersonsPerResidenceMin,
                  s1: AppConfig.form.warmWaterNumberOfPersonsPerResidenceMax,
                }
              ),
            },
          ]}
          injectedOnChange={(value) => {
            dispatch(
              setFilterOption({
                name: 'maxPersonsDrinkingWater',
                value: calculateWarmWaterHeatingPersons({
                  warmWaterNumberOfPersonsPerResidence: typeof value === 'string' ? parseFloat(value) : value,
                  numberOfResidentialUnits: myForm.flatValues[FormFieldNameEnum.numberOfResidentialUnits] ?? 1,
                }),
              })
            )
          }}
        />
      ) : null}

      {myForm.flatValues.selectUseToGenerateWarmWater === 'yes' &&
      myForm.flatValues[FormFieldNameEnum.selectBuildingType] === BuildingTypesKeysEnum.nonResidentialBuilding ? (
        <>
          <EditableFiledWithWrapper
            name={FormFieldNameEnum.warmWaterNumberHeatingLoadQwwDay}
            headline={intl.formatMessage({
              id: 'Wie hoch ist der tägliche Energiebedarf für Trinkwassererwärmung?',
            })}
            label={intl.formatMessage({ id: 'Eingabe in kWh/Tag' })}
            required={intl.formatMessage({ id: 'Pflichtangabe' })}
            type="number"
            minValue={AppConfig.form.warmWaterNumberHeatingLoadQwwDayMin}
            maxValue={AppConfig.form.warmWaterNumberHeatingLoadQwwDayMax}
            placeholder={'0'}
            validations={[
              {
                rule: isInRangeNumber(
                  AppConfig.form.warmWaterNumberHeatingLoadQwwDayMin,
                  AppConfig.form.warmWaterNumberHeatingLoadQwwDayMax
                ),
                message: intl.formatMessage(
                  { id: 'Der tägliche Energiebedarf für Trinkwassererwärmung sollte zwischen {s} und {s1} liegen' },
                  {
                    s: AppConfig.form.warmWaterNumberHeatingLoadQwwDayMin,
                    s1: AppConfig.form.warmWaterNumberHeatingLoadQwwDayMax,
                  }
                ),
              },
            ]}
          />

          <EditableFiledWithWrapper
            name={FormFieldNameEnum.warmWaterNumberHeatingLoadQwwDayHours}
            headline={intl.formatMessage({
              id: 'Wie viele Stunden pro Tag soll Trinkwassererwärmung zur Verfügung stehen?',
            })}
            label={intl.formatMessage({ id: 'Stunden pro Tag (1-24)' })}
            required={intl.formatMessage({ id: 'Pflichtangabe' })}
            type="number"
            minValue={1}
            maxValue={24}
            placeholder={'0'}
            validations={[
              {
                rule: isInRangeNumber(1, 24),
                message: intl.formatMessage(
                  { id: 'Der tägliche Energiebedarf für Trinkwassererwärmung sollte zwischen {s} und {s1} liegen' },
                  { s: 1, s1: 24 }
                ),
              },
            ]}
          />
        </>
      ) : null}

      {myForm.flatValues.selectUseToGenerateWarmWater === 'yes' ? (
        <SelectSingleOptionWithRadioCards
          label={intl.formatMessage({ id: 'Soll Trinkwassererwärmung über Solar möglich sein?' })}
          required={intl.formatMessage({ id: 'Bitte wählen' })}
          name={FormFieldNameEnum.selectUseWarmWaterSolarHeatingSupport}
          injectOnChange={(value) => {
            dispatch(
              setFilterOption({
                name: 'filterOptionAdditionalDrinkingWaterHeating',
                value: value === 'yes',
              })
            )
          }}
          //autoSubmitStepValue={'no'}
          options={[
            {
              imageComponent: IconYesSolarWW,
              value: 'yes',
              label: intl.formatMessage({ id: 'Ja' }),
            },
            {
              imageComponent: IconNo,
              value: 'no',
              label: intl.formatMessage({ id: 'Nein' }),
            },
          ]}
        />
      ) : null}
    </FormizStep>
  )
}

export default injectIntl(StepWarmWaterProduction)
