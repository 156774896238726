import { FormFieldNameEnum } from '@/features/fields/FormFieldNameEnum'
import { getHeatingLoad, getHeatingLoadOriginal } from '@/store/features/userDataSlice'
import { useAppSelector } from '@/store/store'
import { Box, Center, Table, TableContainer, Tbody, Td, Text, Tr } from '@chakra-ui/react'
import { useForm } from '@formiz/core'

const InfoSummary = () => {
  const queryParams = new URLSearchParams(window.location.search)
  const isSummaryEnabled = queryParams.get('summaryInfo') || false

  const heatingLoadValue = useAppSelector(getHeatingLoad)
  const heatingLoadValueOriginal = useAppSelector(getHeatingLoadOriginal)

  const myForm = useForm()

  if (!isSummaryEnabled) {
    return <></>
  }

  return (
    <>
      <Box shadow="md" border={'1px'} borderColor="gray.200" bg="gray.300">
        <Center>
          <Text as="i" mt={2}>
            Interne Informationen
          </Text>
        </Center>
        <TableContainer>
          <Table variant="simple" size="sm">
            <Tbody>
              <Tr>
                <Td>
                  Heizlast (
                  {myForm.flatValues[FormFieldNameEnum.knownBuildingHeatingLoadValue] ? 'eingegeben' : 'berechnet'})
                </Td>
                <Td>{heatingLoadValue}</Td>
              </Tr>
              <Tr>
                <Td>Heizlast original</Td>
                <Td>{heatingLoadValueOriginal}</Td>
              </Tr>
              <Tr>
                <Td>Heizlast verändert</Td>
                <Td>{heatingLoadValue !== heatingLoadValueOriginal ? 'Ja' : 'Nein'}</Td>
              </Tr>
              <Tr>
                <Td>Normtemperatur über PLZ</Td>
                <Td>
                  {myForm.flatValues[FormFieldNameEnum.normTempFinder]
                    ? 'Ja' + ' (' + myForm.flatValues[FormFieldNameEnum.normTempFinder].zip + ')'
                    : 'Nein'}
                </Td>
              </Tr>
            </Tbody>
          </Table>
        </TableContainer>
      </Box>
    </>
  )
}

export default InfoSummary
