// EnergyEfficiencyEnum.niedrigenergiehaus
import { EnergySavingCheckboxGroupValuesEnum } from '@/features/fields/EnergySavingCheckboxGroup'
import { FormFieldNameEnum } from '@/features/fields/FormFieldNameEnum'
import { HeatingSystemTypeEnum } from '@/features/heatingLoad/FieldsEnergySourceAndConsumption'
import { BuildingTypesKeysEnum } from '@/features/steps/StepChooseBuildingType'
import { ConfiguratorModesKeysEnum } from '@/features/steps/StepChooseProjectType'
import { EnergyEfficiencyEnum, StepHeatingLoadValuesEnum } from '@/features/steps/StepHeatingLoad'
import type { IntlShape } from 'react-intl'
import { injectIntl } from 'react-intl'

interface PropertiesInterface {
  value: string | number
  intl: IntlShape
}

export const translate = (intl: IntlShape, value: string | number): string => {
  const isNumeric = (value: string): boolean => {
    return /^-?\d+$/.test(value) || !isNaN(parseFloat(value))
  }

  if (isNumeric(value.toString())) {
    return value.toString()
  } else {
    switch (value) {
      // form values
      // StepChooseProjectType
      case ConfiguratorModesKeysEnum.newBuilding:
        return intl.formatMessage({ id: 'Neubau' })
      case ConfiguratorModesKeysEnum.modernisation:
        return intl.formatMessage({ id: 'Modernisierung' })
      // StepChooseBuildingType
      case BuildingTypesKeysEnum.singleFamilyHouse:
        return intl.formatMessage({ id: 'Einfamilienhaus' })
      case BuildingTypesKeysEnum.multiFamilyHouse:
        return intl.formatMessage({ id: 'Mehrfamilienhaus' })
      case BuildingTypesKeysEnum.nonResidentialBuilding:
        return intl.formatMessage({ id: 'Nichtwohngebäude' })
      // StepHeatingLoad
      case StepHeatingLoadValuesEnum.heatingLoadCalculation:
        return intl.formatMessage({ id: 'Heizlast berechnen' })
      case StepHeatingLoadValuesEnum.heatingLoadIsKnown:
        return intl.formatMessage({ id: 'Ich kenne die Heizlast' })
      case StepHeatingLoadValuesEnum.heatingLoadByConsumption:
        return intl.formatMessage({ id: 'Ich kenne den Verbrauch' })
      case EnergyEfficiencyEnum.niedrigenergiehaus:
        return intl.formatMessage({ id: 'Niedrigenergiehaus' })
      case EnergyEfficiencyEnum.kfWEffizienzhaus:
        return intl.formatMessage({ id: 'KfW-Effizienzhaus' })
      case EnergyEfficiencyEnum.passivhaus:
        return intl.formatMessage({ id: 'Passivhaus' })
      // StepHeatingLoadModernisation
      case HeatingSystemTypeEnum.other:
        return intl.formatMessage({ id: 'Sonstige' })
      case HeatingSystemTypeEnum.gas:
        return intl.formatMessage({ id: 'Gas' })
      case HeatingSystemTypeEnum.oil:
        return intl.formatMessage({ id: 'Heizöl' })
      case HeatingSystemTypeEnum.wp:
        return intl.formatMessage({ id: 'Wärmepumpe' })
      // EnergySavingCheckboxGroup
      case EnergySavingCheckboxGroupValuesEnum.hasEnergySavingNone:
        return intl.formatMessage({ id: 'Keine' })
      case EnergySavingCheckboxGroupValuesEnum.energySavingWalls:
        return intl.formatMessage({ id: 'Dämmung Wände' })
      case EnergySavingCheckboxGroupValuesEnum.energySavingRoof:
        return intl.formatMessage({ id: 'Dämmung Dach' })
      case EnergySavingCheckboxGroupValuesEnum.energySavingWindows:
        return intl.formatMessage({ id: 'neue Fenster' })
      // booleans
      case 'yes':
        return intl.formatMessage({ id: 'Ja' })
      case 'no':
        return intl.formatMessage({ id: 'Nein' })

      case 'Ergebnis':
        return intl.formatMessage({ id: 'Ergebnis' })

      // special
      case 'none':
        return ''

      // form field names WACHTUNG GRID VALUES ggf kürzer
      case FormFieldNameEnum.selectProjectType:
        return intl.formatMessage({ id: 'Projektart' })
      case FormFieldNameEnum.selectBuildingType:
        return intl.formatMessage({ id: 'Gebäudeart' })
      case FormFieldNameEnum.numberOfResidentialUnits:
        return intl.formatMessage({ id: 'Wohneinheiten' })
      case FormFieldNameEnum.buildingConstructionYear:
        return intl.formatMessage({ id: 'Baujahr' })
      case FormFieldNameEnum.energySavingCheckboxGroup:
        return intl.formatMessage({ id: 'Energiesparmaßnahmen' })
      case FormFieldNameEnum.knownNormTemp:
        return intl.formatMessage({ id: 'Normaußentemperatur' })
      case FormFieldNameEnum.normTemp:
        return intl.formatMessage({ id: 'Normaußentemperatur' })
      case FormFieldNameEnum.normTempFinder:
        return intl.formatMessage({ id: 'Normaußentemperatur' })
      case FormFieldNameEnum.knownGasOrOilOrPowerConsumeValueIncludesWarmWater:
        return intl.formatMessage({ id: 'inkl. Trinkwassererwärmung' })
      case FormFieldNameEnum.knownGasOrOilOrPowerConsumeValue:
        return intl.formatMessage({ id: 'Verbrauch' })
      case FormFieldNameEnum.selectUseToGenerateWarmWater:
        return intl.formatMessage({ id: 'Trinkwassererwärmung' })
      case FormFieldNameEnum.warmWaterNumberOfPersonsPerBuilding:
        return intl.formatMessage({ id: 'Personen im Gebäude' })
      case FormFieldNameEnum.warmWaterNumberOfPersonsPerResidence:
        return intl.formatMessage({ id: 'Personen pro Wohneinheit' })
      case FormFieldNameEnum.selectUseWarmWaterSolarHeatingSupport:
        return intl.formatMessage({ id: 'Solar-Unterstützung' })
      case FormFieldNameEnum.warmWaterNumberHeatingLoadQwwDay:
        return intl.formatMessage({ id: 'Energiebedarf für Trinkwassererwärmung' })
      case FormFieldNameEnum.warmWaterNumberHeatingLoadQwwDayHours:
        return intl.formatMessage({ id: 'Trinkwassererwärmung h/Tag' })
      case FormFieldNameEnum.selectCurrentUseOfSolarToGenerateWarmWater:
        return intl.formatMessage({ id: 'Solarthermie' })
      case FormFieldNameEnum.selectCurrentWarmWaterHeatingSystemType:
        return intl.formatMessage({ id: ' Trinkwassererwärmung bisher' })
      case FormFieldNameEnum.selectCurrentWarmWaterHeatingSystemConsume:
        return intl.formatMessage({ id: 'Verbrauch der Trinkwassererwärmung' })
      case FormFieldNameEnum.selectCurrentHeatingSystemType:
        return intl.formatMessage({ id: 'Momentanes Heizsystem' })
      case FormFieldNameEnum.heatingLoadSource:
        return intl.formatMessage({ id: 'Heizlast' })
      case FormFieldNameEnum.selectUseSolarHeatingSupport:
        return intl.formatMessage({ id: 'Heizungsunterstützung' })
      case FormFieldNameEnum.selectUseAreaCooling:
        return intl.formatMessage({ id: 'Raumkühlung' })
      case FormFieldNameEnum.knownBuildingHeatingLoadValue:
        return intl.formatMessage({ id: 'Heizlast' })
      case FormFieldNameEnum.habitableSpace:
        return intl.formatMessage({ id: 'Wohnfläche' })
      case FormFieldNameEnum.energyEfficiency:
        return intl.formatMessage({ id: 'Energieeffizienz' })
      case FormFieldNameEnum.selectUseToGenerateSolarEnergy:
        return intl.formatMessage({ id: 'PV-Anlage' })
      case FormFieldNameEnum.selectUseEnergyWithTimeLocks:
        return intl.formatMessage({ id: 'Sperrzeiten' })
      case FormFieldNameEnum.energyWithTimeLocksHours:
        return intl.formatMessage({ id: 'Dauer' })

      default:
        console.info('Missing value translation of: ' + value)
        return value.toString()
    }
  }
}

const TranslationsHelper = ({ value, intl }: PropertiesInterface) => {
  return <>{translate(intl, value)}</>
}

export default injectIntl(TranslationsHelper)
