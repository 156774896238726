import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react'
import { createSelector } from 'reselect'

export interface HeatPumpMatrixValueInterface {
  ambientTemperature: number
  heatingCapacityValue: number
  isMinimalLoad: boolean
}

export interface HeatPumpMatrixCalculationValueInterface {
  maxPreheatValue: number
  isMinimalLoad: boolean
  heatingCapacityAtSub2Degrees: number
  heatingCapacityAtSub7Degrees: number
  heatingCapacityAtSub10Degrees: number
  heatingCapacityAtSub15Degrees: number
}

export interface HeatPumpInterface {
  id: number
  oegNumber: string
  values: { [key: string]: HeatPumpMatrixValueInterface[] }
  valuesRelevantForPower: HeatPumpMatrixCalculationValueInterface[]
}

export type HeatPumpMatrixResponse = HeatPumpInterface[]

export const heatPumpsApiSlice = createApi({
  reducerPath: 'heatPumpsApiSlice',
  baseQuery: fetchBaseQuery({
    baseUrl: import.meta.env.VITE_API_ENDPOINT_HEAT_PUMPS,
  }),
  tagTypes: ['HeatPumpSet'],
  keepUnusedDataFor: 999999,
  endpoints: (builder) => ({
    getHeatPumps: builder.query<HeatPumpInterface[], void>({
      query: () => {
        return {
          method: 'GET',
          url: '',
          headers: {
            accept: 'application/json',
          },
        }
      },
      transformResponse: (responseData: HeatPumpInterface[]) => {
        return responseData
      },
    }),
  }),
})

export const { useGetHeatPumpsQuery, useLazyGetHeatPumpsQuery } = heatPumpsApiSlice

// Calling `someEndpoint.select(someArg)` generates a new selector that will return
// the query result object for a query with those parameters.
// To generate a selector for a specific query argument, call `select(theQueryArg)`.
// In this case, the users query has no params, so we don't pass anything to select()
export const selectPumpResult = heatPumpsApiSlice.endpoints.getHeatPumps.select()

export const selectAllPumps = createSelector(selectPumpResult, (pumpResult) => pumpResult.data)
