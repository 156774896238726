import { FilterCheckbox } from '@/features/fields/filter/FilterCheckbox'
import { FilterFlagsInterface } from '@/features/heatPumpSet/HeatPumpSets'
import { IsDesktopDevice } from '@/features/layout/DetectDeviceParams'
import {
  selectFilteredHeatPumpPackages,
  selectHeatPumpSetsWithMoreThanOneTank,
  selectHeatPumpSetsWithOneTank,
  selectHeatPumpSetsWithPurePlusHeatExchanger,
  selectHeatPumpSetsWithSgOptimized,
  selectHeatPumpSetsWithTankLyingDown,
  selectHeatPumpSetsWithTankStandingUp,
  selectHeatPumpSetsWithTemperature35,
  selectHeatPumpSetsWithTemperature45,
  selectHeatPumpSetsWithTemperature55,
  selectHeatPumpSetsWithTemperature70,
} from '@/selector/heatPumpPackages'
import { HeatPumpPackageInterface } from '@/store/features/heatPumpPackagesApiSlice'
import {
  getFilterFlags,
  resetUserFilter,
  setFilterOption,
  userFiltersAreUsed,
} from '@/store/features/heatPumpSetFilterSlice'
import { useAppSelector } from '@/store/store'
import { Accordion, AccordionButton, AccordionItem, AccordionPanel, Box, Heading, Link } from '@chakra-ui/react'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import type { IntlShape } from 'react-intl/src/types'
import { useDispatch } from 'react-redux'

export const HeatPumpSetsFilter = ({ intl }: { intl: IntlShape }) => {
  const dispatch = useDispatch()
  const isDesktop = IsDesktopDevice()

  const getFilteredCount = (items: HeatPumpPackageInterface[]) => {
    return ' (' + items.length + ')'
  }

  const overAllCount = useAppSelector(selectFilteredHeatPumpPackages).length

  const matchesOverallCount = (items: HeatPumpPackageInterface[]): boolean => {
    return overAllCount > 0 && overAllCount === items.length
  }

  const itemsData = {
    filterOptionMoreThanOneTank: useAppSelector(selectHeatPumpSetsWithMoreThanOneTank),
    filterOptionWithOneTank: useAppSelector(selectHeatPumpSetsWithOneTank),
    filterOptionTankStanding: useAppSelector(selectHeatPumpSetsWithTankStandingUp),
    filterOptionTankLyingDown: useAppSelector(selectHeatPumpSetsWithTankLyingDown),
    filterOptionPurePlusFreshWaterHeatExchanger: useAppSelector(selectHeatPumpSetsWithPurePlusHeatExchanger),
    filterOptionSgOptimized: useAppSelector(selectHeatPumpSetsWithSgOptimized),
    filterOptionTemperature35: useAppSelector(selectHeatPumpSetsWithTemperature35),
    filterOptionTemperature45: useAppSelector(selectHeatPumpSetsWithTemperature45),
    filterOptionTemperature55: useAppSelector(selectHeatPumpSetsWithTemperature55),
    filterOptionTemperature70: useAppSelector(selectHeatPumpSetsWithTemperature70),
  }

  const matchCount = {
    filterOptionMoreThanOneTank: matchesOverallCount(itemsData.filterOptionMoreThanOneTank),
    filterOptionWithOneTank: matchesOverallCount(itemsData.filterOptionWithOneTank),
    filterOptionTankStanding: matchesOverallCount(itemsData.filterOptionTankStanding),
    filterOptionTankLyingDown: matchesOverallCount(itemsData.filterOptionTankLyingDown),
    filterOptionPurePlusFreshWaterHeatExchanger: matchesOverallCount(
      itemsData.filterOptionPurePlusFreshWaterHeatExchanger
    ),
    filterOptionSgOptimized: matchesOverallCount(itemsData.filterOptionSgOptimized),
    filterOptionTemperature35: matchesOverallCount(itemsData.filterOptionTemperature35),
    filterOptionTemperature45: matchesOverallCount(itemsData.filterOptionTemperature45),
    filterOptionTemperature55: matchesOverallCount(itemsData.filterOptionTemperature55),
    filterOptionTemperature70: matchesOverallCount(itemsData.filterOptionTemperature70),
  }

  const filterFlags: FilterFlagsInterface = useAppSelector(getFilterFlags)
  const filterWereUsedResult: boolean = useAppSelector(userFiltersAreUsed)

  const isChecked = (filterName: string): boolean => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return filterFlags[filterName] || matchCount[filterName]
  }
  const isDisabled = (filterName: string) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return itemsData[filterName].length === 0 || (matchCount[filterName] && filterFlags[filterName] === false)
  }

  const changeCallback = (e: React.ChangeEvent<HTMLInputElement>, filterName: string) => {
    dispatch(
      setFilterOption({
        name: filterName,
        value: e.target.checked,
      })
    )
  }

  return (
    <Accordion allowToggle={!isDesktop} defaultIndex={isDesktop ? 0 : undefined}>
      <AccordionItem>
        <Box className="filter__detail" border={'1px'} borderColor={'lightgray'}>
          <AccordionButton>
            <Box className="filter__header" flex="1" bg={'brand.backgroundColor'}>
              <Heading lineHeight={'1.5'} size={'md'} p={'8px'} color={'#fff !important'}>
                <FormattedMessage id="Detailfilter" /> ({overAllCount})
              </Heading>
            </Box>
          </AccordionButton>
          <AccordionPanel pb={4}>
            <Box
              flex="1"
              className="filter__detail-reset"
              data-testid={'reset'}
              borderBottom={'1px'}
              borderBottomColor={'lightgray'}
              paddingBottom={'10px'}
            >
              {filterWereUsedResult ? (
                <Link
                  onClick={() => {
                    dispatch(resetUserFilter())
                  }}
                >
                  <FormattedMessage id="alle Zurücksetzen" />
                </Link>
              ) : (
                ''
              )}
            </Box>
            <Box
              className="filter__detail-box"
              data-testid={'filterTemperature'}
              borderBottom={'1px'}
              borderBottomColor={'lightgray'}
              paddingBottom={'10px'}
            >
              <Heading size={'sm'} mb={'4px'} mt={'5'} color={'brand.subHeadline'}>
                <FormattedMessage id="Vorlauftemperaturen" />
              </Heading>
              <FilterCheckbox
                name={'filterOptionTemperature35'}
                label={'35 °C ' + getFilteredCount(itemsData.filterOptionTemperature35)}
                disabled={isDisabled('filterOptionTemperature35')}
                checked={isChecked('filterOptionTemperature35')}
                onChangeCallback={(e: React.ChangeEvent<HTMLInputElement>) => {
                  changeCallback(e, 'filterOptionTemperature35')
                }}
              />
              <FilterCheckbox
                name={'filterOptionTemperature45'}
                label={'45 °C ' + getFilteredCount(itemsData.filterOptionTemperature45)}
                disabled={isDisabled('filterOptionTemperature45')}
                checked={isChecked('filterOptionTemperature45')}
                onChangeCallback={(e: React.ChangeEvent<HTMLInputElement>) => {
                  changeCallback(e, 'filterOptionTemperature45')
                }}
              />
              <FilterCheckbox
                name={'filterOptionTemperature55'}
                label={'55 °C ' + getFilteredCount(itemsData.filterOptionTemperature55)}
                disabled={isDisabled('filterOptionTemperature55')}
                checked={isChecked('filterOptionTemperature55')}
                onChangeCallback={(e: React.ChangeEvent<HTMLInputElement>) => {
                  changeCallback(e, 'filterOptionTemperature55')
                }}
              />
              <FilterCheckbox
                name={'filterOptionTemperature70'}
                label={'70 °C' + getFilteredCount(itemsData.filterOptionTemperature70)}
                disabled={isDisabled('filterOptionTemperature70')}
                checked={isChecked('filterOptionTemperature70')}
                onChangeCallback={(e: React.ChangeEvent<HTMLInputElement>) => {
                  changeCallback(e, 'filterOptionTemperature70')
                }}
              />
            </Box>

            <Box
              className="filter__detail-box"
              data-testid={'filterSpace'}
              borderBottom={'1px'}
              borderBottomColor={'lightgray'}
              paddingBottom={'10px'}
            >
              <Heading size={'sm'} mb={'4px'} mt={'5'} color={'brand.subHeadline'}>
                <FormattedMessage id="Platzbedarf" />
              </Heading>
              <FilterCheckbox
                name={'filterOptionMoreThanOneTank'}
                label={
                  intl.formatMessage({ id: '2 Speicher' }) + getFilteredCount(itemsData.filterOptionMoreThanOneTank)
                }
                disabled={isDisabled('filterOptionMoreThanOneTank')}
                checked={isChecked('filterOptionMoreThanOneTank')}
                onChangeCallback={(e: React.ChangeEvent<HTMLInputElement>) => {
                  changeCallback(e, 'filterOptionMoreThanOneTank')
                }}
              />
              <FilterCheckbox
                name={'filterOptionWithOneTank'}
                label={intl.formatMessage({ id: '1 Speicher' }) + getFilteredCount(itemsData.filterOptionWithOneTank)}
                disabled={isDisabled('filterOptionWithOneTank')}
                checked={isChecked('filterOptionWithOneTank')}
                onChangeCallback={(e: React.ChangeEvent<HTMLInputElement>) => {
                  changeCallback(e, 'filterOptionWithOneTank')
                }}
              />
            </Box>
            <Box
              className="filter__detail-box"
              data-testid={'filterTankPostion'}
              borderBottom={'1px'}
              borderBottomColor={'lightgray'}
              paddingBottom={'10px'}
            >
              <Heading size={'sm'} mb={'4px'} mt={'5'} color={'brand.subHeadline'}>
                <FormattedMessage id="Aufstellung" />
              </Heading>
              <FilterCheckbox
                name={'filterOptionTankStanding'}
                label={intl.formatMessage({ id: 'stehend' }) + getFilteredCount(itemsData.filterOptionTankStanding)}
                disabled={isDisabled('filterOptionTankStanding')}
                checked={isChecked('filterOptionTankStanding')}
                onChangeCallback={(e: React.ChangeEvent<HTMLInputElement>) => {
                  changeCallback(e, 'filterOptionTankStanding')
                }}
              />
              <FilterCheckbox
                name={'filterOptionTankLyingDown'}
                label={intl.formatMessage({ id: 'liegend' }) + getFilteredCount(itemsData.filterOptionTankLyingDown)}
                disabled={isDisabled('filterOptionTankLyingDown')}
                checked={isChecked('filterOptionTankLyingDown')}
                onChangeCallback={(e: React.ChangeEvent<HTMLInputElement>) => {
                  changeCallback(e, 'filterOptionTankLyingDown')
                }}
              />
            </Box>

            <Box
              className="filter__detail-box"
              data-testid={'merkmale'}
              borderBottom={'1px'}
              borderBottomColor={'lightgray'}
              paddingBottom={'10px'}
            >
              <Heading size={'sm'} mb={'4px'} mt={'5'} color={'brand.subHeadline'}>
                <FormattedMessage id="Merkmale" />
              </Heading>
              <FilterCheckbox
                name={'filterOptionPurePlusFreshWaterHeatExchanger'}
                label={
                  intl.formatMessage({ id: 'Pure+ Frischwassertechnik Edelstahlwärmetauscher' }) +
                  getFilteredCount(itemsData.filterOptionPurePlusFreshWaterHeatExchanger)
                }
                disabled={isDisabled('filterOptionPurePlusFreshWaterHeatExchanger')}
                checked={isChecked('filterOptionPurePlusFreshWaterHeatExchanger')}
                onChangeCallback={(e: React.ChangeEvent<HTMLInputElement>) => {
                  changeCallback(e, 'filterOptionPurePlusFreshWaterHeatExchanger')
                }}
              />
              <FilterCheckbox
                name={'filterOptionSgOptimized'}
                label={intl.formatMessage({ id: 'SG Optimised' }) + getFilteredCount(itemsData.filterOptionSgOptimized)}
                disabled={isDisabled('filterOptionSgOptimized')}
                checked={isChecked('filterOptionSgOptimized')}
                onChangeCallback={(e: React.ChangeEvent<HTMLInputElement>) => {
                  changeCallback(e, 'filterOptionSgOptimized')
                }}
              />
            </Box>
          </AccordionPanel>
        </Box>
      </AccordionItem>
    </Accordion>
  )
}
