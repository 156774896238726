import { calculateHeatingLoad } from './calculateHeatingLoad'
import { calculatePAdditional } from './calculatePAdditional'
import { calculatePreHeatingLoad, PreHeatingLoadResult } from './calculatePreHeatingLoad'

interface CalcHeadingLoadParams {
  valueOfNormOutsideTemp: number
  buildingHeatingLoad: number
  heatingCapacityInKwAtSub2Degrees: number
  heatingCapacityInKwAtSub7Degrees: number
  heatingCapacityInKwAtSub10Degrees: number
  heatingCapacityInKwAtSub15Degrees: number
}

export interface CalculateHeatPumpPowerConsumeResult {
  pAdditional: number
  preHeatingLoad: PreHeatingLoadResult
}

export const calculateHeatPumpPowerConsume = ({
  valueOfNormOutsideTemp,
  buildingHeatingLoad,
  heatingCapacityInKwAtSub2Degrees,
  heatingCapacityInKwAtSub7Degrees,
  heatingCapacityInKwAtSub10Degrees,
  heatingCapacityInKwAtSub15Degrees,
}: CalcHeadingLoadParams): CalculateHeatPumpPowerConsumeResult => {
  const heatingLoad = calculateHeatingLoad({
    valueOfNormOutsideTemp,
    buildingHeatingLoad,
  })

  // calc pre heating values for 35°
  const preHeatingLoad = calculatePreHeatingLoad({
    heatingCapacityInKwAtSub7Degrees,
    heatingCapacityInKwAtSub2Degrees,
    heatingLoad,
  })

  // PZusatz = 	6,09
  // preHeatingValue_Y1=preHeatingTemp_m1*preHeatingTemp_x1+preHeatingTemp_b1

  // calcPZusatz 35°C
  const pAdditionalResult = calculatePAdditional({
    heatingCapacityInKwAtSub10Degrees,
    heatingCapacityInKwAtSub15Degrees,
    valueOfNormOutsideTemp,
    buildingHeatingLoad,
  })

  console.debug('calculateHeatPumpPowerConsume', {
    valueOfNormOutsideTemp: valueOfNormOutsideTemp,
    buildingHeatingLoad: buildingHeatingLoad,
    heatingCapacityInKwAtSub2Degrees: heatingCapacityInKwAtSub2Degrees,
    heatingCapacityInKwAtSub7Degrees: heatingCapacityInKwAtSub7Degrees,
    heatingCapacityInKwAtSub10Degrees: heatingCapacityInKwAtSub10Degrees,
    heatingCapacityInKwAtSub15Degrees: heatingCapacityInKwAtSub15Degrees,
    heatingLoad: heatingLoad,
    preHeatingLoad: preHeatingLoad,
    pAdditionalResult: pAdditionalResult,
  })

  return {
    pAdditional: pAdditionalResult.pAdditional,
    preHeatingLoad: preHeatingLoad,
  }
}
