import { StatsTableContainer } from '@/features/dashboard/StatsTableContainer'
import { useGetFullHistoryQuery, useGetHistoryNoPumpsQuery } from '@/store/features/userStatsApiSlice'
import {
  CSSReset,
  Center,
  Container,
  Spinner,
  Tab,
  TabIndicator,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react'
import { useState } from 'react'
import { injectIntl } from 'react-intl'
import { Paginator } from '@/features/layout/navigation/Paginator'

const AppDashboard = () => {
  const [tabIndex, setTabIndex] = useState(0)
  const [page, setPage] = useState(1)
  const [pageNoPumps, setPageNoPumps] = useState(1)
  const { data: userStatsData, isLoading, isFetching } = useGetFullHistoryQuery(page, { skip: tabIndex !== 0 })
  const {
    data: userStatsNoPumpsData,
    isLoading: isLoadingNoPumps,
    isFetching: isFetchingNoPumpsFound,
  } = useGetHistoryNoPumpsQuery(pageNoPumps, {
    skip: tabIndex !== 1,
  })

  const Loader = () => (
    <Container minHeight="560px">
      <Center h="calc(70vh)">
        <Spinner boxSize={100} thickness="10px" speed="0.85s" color="brand.500" />
      </Center>
    </Container>
  )

  return (
    <Container minWidth="100%">
      <CSSReset />
      <Tabs position="relative" variant="unstyled" onChange={(index) => setTabIndex(index)}>
        <TabList>
          <Tab>Zusammenfassung erreicht</Tab>
          <Tab>Keine Pumpen gefunden</Tab>
        </TabList>
        <TabIndicator mt="-1.5px" height="2px" bg="brand.default" borderRadius="1px" />
        <TabPanels>
          <TabPanel>
            {isLoading || isFetching ? <Loader /> : null}
            {!isLoading && !isFetching && userStatsData ? (
              <Paginator
                page={page}
                setCurrentPageCallback={setPage}
                totalItemCount={userStatsData['hydra:totalItems']}
              />
            ) : null}
            {!isLoading && !isFetching && userStatsData ? (
              <StatsTableContainer userStatsData={userStatsData['hydra:member']} />
            ) : null}
          </TabPanel>
          <TabPanel>
            {isLoadingNoPumps || isFetchingNoPumpsFound ? <Loader /> : null}
            {!isLoadingNoPumps && !isFetchingNoPumpsFound && userStatsNoPumpsData ? (
              <Paginator
                page={pageNoPumps}
                setCurrentPageCallback={setPageNoPumps}
                totalItemCount={userStatsNoPumpsData['hydra:totalItems']}
              />
            ) : null}
            {!isLoadingNoPumps && !isFetchingNoPumpsFound && userStatsNoPumpsData ? (
              <StatsTableContainer userStatsData={userStatsNoPumpsData['hydra:member']} />
            ) : null}
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Container>
  )
}

export default injectIntl(AppDashboard)
