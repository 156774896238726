import AppConfig from '@/app/AppConfig'
import IconGas from '@/assets/icons/oeg-konfigurator-icon-gas.svg'
import IconOel from '@/assets/icons/oeg-konfigurator-icon-oel.svg'
import IconOthers from '@/assets/icons/oeg-konfigurator-icon-sonstige-heizsysteme.svg'
import IconWP from '@/assets/icons/oeg-konfigurator-icon-waermepumpe.svg'
import { FormFieldNameEnum } from '@/features/fields/FormFieldNameEnum'
import { useForm } from '@formiz/core'
import { isInRangeNumber } from '@formiz/validations'
import { ReactNode } from 'react'
import type { IntlShape } from 'react-intl'
import { EditableFiledWithWrapper } from '../fields/EditableFiledWithWrapper'
import { SelectSingleOptionWithRadioCards } from '../fields/SelectSingleOptionWithRadioCards'

export enum HeatingSystemTypeEnum {
  gas = 'gas',
  oil = 'oil',
  wp = 'wp',
  other = 'other',
}

export const FieldsEnergySourceAndConsumption = ({ intl, children }: { intl: IntlShape; children?: ReactNode }) => {
  const myForm = useForm()
  return (
    <>
      <SelectSingleOptionWithRadioCards
        label={intl.formatMessage({ id: 'Womit heizen Sie zurzeit?' })}
        required={intl.formatMessage({ id: 'Bitte wählen' })}
        name={FormFieldNameEnum.selectCurrentHeatingSystemType}
        options={[
          {
            imageComponent: IconGas,
            value: HeatingSystemTypeEnum.gas,
            label: intl.formatMessage({ id: 'Gas' }),
          },
          {
            imageComponent: IconOel,
            value: HeatingSystemTypeEnum.oil,
            label: intl.formatMessage({ id: 'Heizöl' }),
          },
          {
            imageComponent: IconWP,
            value: HeatingSystemTypeEnum.wp,
            label: intl.formatMessage({ id: 'Wärmepumpe' }),
          },
          {
            imageComponent: IconOthers,
            value: HeatingSystemTypeEnum.other,
            label: intl.formatMessage({ id: 'Sonstige' }),
          },
        ]}
      />

      {myForm.flatValues.selectCurrentHeatingSystemType &&
        myForm.flatValues.selectCurrentHeatingSystemType === HeatingSystemTypeEnum.wp && (
          <>
            <EditableFiledWithWrapper
              name={FormFieldNameEnum.knownGasOrOilOrPowerConsumeValue}
              headline={intl.formatMessage({ id: 'Ich kenne den Wärmepumpen - Stromverbrauch' })}
              label={intl.formatMessage({ id: 'Eingabe in kWh/Jahr' })}
              type="number"
              minValue={AppConfig.form.heatingElectricityKwhYearMin}
              maxValue={AppConfig.form.heatingElectricityKwhYearMax}
              required={true}
              placeholder={'0'}
              validations={[
                {
                  rule: isInRangeNumber(
                    AppConfig.form.heatingElectricityKwhYearMin,
                    AppConfig.form.heatingElectricityKwhYearMax
                  ),
                  message: intl.formatMessage(
                    { id: 'Der Wärmepumpen Stromverbrauch sollte zwischen {s} und {s1} liegen' },
                    { s: AppConfig.form.heatingElectricityKwhYearMin, s1: AppConfig.form.heatingElectricityKwhYearMax }
                  ),
                },
              ]}
            />
          </>
        )}

      {myForm.flatValues.selectCurrentHeatingSystemType &&
        myForm.flatValues.selectCurrentHeatingSystemType === HeatingSystemTypeEnum.gas && (
          <>
            <EditableFiledWithWrapper
              name={FormFieldNameEnum.knownGasOrOilOrPowerConsumeValue}
              headline={intl.formatMessage({ id: 'Ich kenne den Gasverbrauch' })}
              label={intl.formatMessage({ id: 'Eingabe in kWh/Jahr' })}
              type="number"
              minValue={AppConfig.form.heatingGasKwhYearMin}
              maxValue={AppConfig.form.heatingGasKwhYearMax}
              required={true}
              placeholder={'0'}
              validations={[
                {
                  rule: isInRangeNumber(AppConfig.form.heatingGasKwhYearMin, AppConfig.form.heatingGasKwhYearMax),
                  message: intl.formatMessage(
                    { id: 'Der Gasverbrauch sollte zwischen {s} und {s1} liegen' },
                    { s: AppConfig.form.heatingGasKwhYearMin, s1: AppConfig.form.heatingGasKwhYearMax }
                  ),
                },
              ]}
            />
          </>
        )}

      {myForm.flatValues.selectCurrentHeatingSystemType &&
        myForm.flatValues.selectCurrentHeatingSystemType === HeatingSystemTypeEnum.oil && (
          <>
            <EditableFiledWithWrapper
              name={FormFieldNameEnum.knownGasOrOilOrPowerConsumeValue}
              headline={intl.formatMessage({ id: 'Ich kenne den Ölverbrauch' })}
              label={intl.formatMessage({ id: 'Eingabe in Liter/Jahr' })}
              type="number"
              minValue={AppConfig.form.heatingOilLiterYearMin}
              maxValue={AppConfig.form.heatingOilLiterYearMax}
              required={true}
              placeholder={'0'}
              validations={[
                {
                  rule: isInRangeNumber(AppConfig.form.heatingOilLiterYearMin, AppConfig.form.heatingOilLiterYearMax),
                  message: intl.formatMessage(
                    { id: 'Der Ölverbrauch sollte zwischen {s} und {s1} liegen' },
                    { s: AppConfig.form.heatingOilLiterYearMin, s1: AppConfig.form.heatingOilLiterYearMax }
                  ),
                },
              ]}
            />
          </>
        )}

      {myForm.flatValues.selectCurrentHeatingSystemType &&
        myForm.flatValues.selectCurrentHeatingSystemType === HeatingSystemTypeEnum.other && (
          <>
            <EditableFiledWithWrapper
              name={FormFieldNameEnum.knownGasOrOilOrPowerConsumeValue}
              headline={intl.formatMessage({ id: 'Ich kenne den Wärmeenergieverbrauch' })}
              label={intl.formatMessage({ id: 'kWh/Jahr' })}
              type="number"
              minValue={AppConfig.form.heatingOtherKwhYearMin}
              maxValue={AppConfig.form.heatingOtherKwhYearMax}
              required={true}
              placeholder={'0'}
              validations={[
                {
                  rule: isInRangeNumber(AppConfig.form.heatingOtherKwhYearMin, AppConfig.form.heatingOtherKwhYearMax),
                  message: intl.formatMessage(
                    { id: 'Der Wärmeenergieverbrauch sollte zwischen {s} und {s1} liegen' },
                    { s: AppConfig.form.heatingOtherKwhYearMin, s1: AppConfig.form.heatingOtherKwhYearMax }
                  ),
                },
              ]}
            />
          </>
        )}
      {children}
    </>
  )
}
