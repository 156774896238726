import IconWall from '@/assets/icons/oeg-konfigurator-icon-aussenwaende.svg'
import IconRoof from '@/assets/icons/oeg-konfigurator-icon-dach.svg'
import IconWindows from '@/assets/icons/oeg-konfigurator-icon-fenster.svg'
import {
  Box,
  chakra,
  CheckboxGroup,
  Flex,
  Heading,
  HStack,
  Image,
  Text,
  useCheckbox,
  useCheckboxGroup,
  UseCheckboxProps,
} from '@chakra-ui/react'
import { useField } from '@formiz/core'
import { UseFieldProps } from '@formiz/core/dist/types/field.types'
import { isNotEmptyArray } from '@formiz/validations'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { IntlShape } from 'react-intl/src/types'
import IconNo from '../../assets/icons/oeg-konfigurator-icon-nein.svg'

const CheckboxWithImageLabel = (
  props: UseCheckboxProps & UseFieldProps & { label: string; name: string; imageUrl: React.Component | string }
) => {
  const { state, getCheckboxProps, getInputProps, getLabelProps } = useCheckbox(props)
  const { label, imageUrl, name } = props
  const labelProps = { label }

  return (
    <chakra.label
      display="flex"
      flexDirection="column"
      alignItems="center"
      gridColumnGap={2}
      w={250}
      // border="1px solid"
      rounded="lg"
      px={3}
      py={1}
      cursor="pointer"
      className={'checkbox__' + name + ' checkbox__label'}
      data-testid={'checkbox_container_' + name}
      {...labelProps}
    >
      <input {...getInputProps()} hidden data-testid={'checkbox_' + name} />
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      <Flex
        alignItems="center"
        justifyContent="center"
        w={4}
        h={4}
        {...getCheckboxProps()}
        className={'checkbox__box' + (state.isChecked ? 'checkbox__is_checked' : '')}
      ></Flex>
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      <Text color="gray.700" {...getLabelProps()} className={'checkbox__image'} data-testid={'checkbox_label_' + name}>
        {typeof imageUrl === 'object' ? (
          <Image borderRadius="full" boxSize="150px" src={imageUrl.render()?.toString()} alt={label} />
        ) : (
          <Image borderRadius="full" boxSize="150px" src={imageUrl} alt={label} />
        )}
      </Text>
      <p className={'checkbox__label-text'} data-checked={state.isChecked ? 'checkbox__is_checked' : ''}>
        {label}
      </p>
    </chakra.label>
  )
}

export enum EnergySavingCheckboxGroupValuesEnum {
  hasEnergySavingNone = 'hasEnergySavingNone',
  energySavingWindows = 'energySavingWindows',
  energySavingWalls = 'energySavingWalls',
  energySavingRoof = 'energySavingRoof',
  hasEnergySavingWindows = 'hasEnergySavingWindows',
  hasEnergySavingRoof = 'hasEnergySavingRoof',
  hasEnergySavingWalls = 'hasEnergySavingWalls',
}

export const EnergySavingCheckboxGroup = (props: { intl: IntlShape; name: string }) => {
  const { intl, name } = props

  const fieldProps: UseFieldProps = {
    name: name,
    validations: [
      {
        rule: isNotEmptyArray(),
        message: intl.formatMessage({ id: 'Es muss eine Option gewählt werden' }),
      },
      {
        rule: (value: unknown): boolean => {
          if (!Array.isArray(value)) {
            return false
          }
          if (Array.isArray(value) && value.includes(EnergySavingCheckboxGroupValuesEnum.hasEnergySavingNone)) {
            if (value.length !== 1) {
              return false
            }
          }
          return true
        },
        message: intl.formatMessage({ id: 'Ungültige Kombination' }),
      },
    ],
  }

  const { setValue, value: groupValue } = useField(fieldProps)
  const { getCheckboxProps } = useCheckboxGroup({
    onChange: (value) => {
      if (!Array.isArray(value)) {
        setValue([])
        return
      }

      if (
        value.length > 1 &&
        value.includes(EnergySavingCheckboxGroupValuesEnum.hasEnergySavingNone) &&
        groupValue &&
        groupValue.length === 1 &&
        groupValue.includes(EnergySavingCheckboxGroupValuesEnum.hasEnergySavingNone)
      ) {
        const newValue: string[] = []
        value.map((valueData) => {
          if (valueData !== EnergySavingCheckboxGroupValuesEnum.hasEnergySavingNone) {
            newValue.push(valueData.toString())
          }
        })
        setValue(newValue)
      } else {
        if (value.includes(EnergySavingCheckboxGroupValuesEnum.hasEnergySavingNone) && value.length > 1) {
          // remove others
          setValue([EnergySavingCheckboxGroupValuesEnum.hasEnergySavingNone])
        } else {
          setValue(value)
        }
      }
    },
    value: groupValue ?? [],
  })

  return (
    <>
      <Box p={5} shadow="md" borderWidth="1px">
        <Heading fontSize="xl">
          <FormattedMessage id={'Welche Energiesparmaßnahmen sollen berücksichtigt werden?'} />
        </Heading>
      </Box>

      <div data-testid={name}>
        <CheckboxGroup /*defaultValue={['naruto', 'kakashi']}*/ data-testid={name}>
          <HStack
            spacing={[1, 5]}
            direction={['column', 'row']}
            className={'checkbox__' + name + ' checkbox__container'}
          >
            <CheckboxWithImageLabel
              name={EnergySavingCheckboxGroupValuesEnum.hasEnergySavingWindows}
              label={intl.formatMessage({ id: 'neue Fenster' })}
              imageUrl={IconWindows}
              {...getCheckboxProps({ value: EnergySavingCheckboxGroupValuesEnum.energySavingWindows })}
              isInvalid={
                Array.isArray(groupValue) &&
                groupValue.includes(EnergySavingCheckboxGroupValuesEnum.hasEnergySavingNone)
              }
            />
            <CheckboxWithImageLabel
              name={EnergySavingCheckboxGroupValuesEnum.hasEnergySavingRoof}
              imageUrl={IconRoof}
              label={intl.formatMessage({ id: 'Dämmung Dach' })}
              {...getCheckboxProps({ value: EnergySavingCheckboxGroupValuesEnum.energySavingRoof })}
              isInvalid={
                Array.isArray(groupValue) &&
                groupValue.includes(EnergySavingCheckboxGroupValuesEnum.hasEnergySavingNone)
              }
            />
            <CheckboxWithImageLabel
              name={EnergySavingCheckboxGroupValuesEnum.hasEnergySavingWalls}
              imageUrl={IconWall}
              label={intl.formatMessage({ id: 'Dämmung Wände' })}
              {...getCheckboxProps({ value: EnergySavingCheckboxGroupValuesEnum.energySavingWalls })}
              isInvalid={
                Array.isArray(groupValue) &&
                groupValue.includes(EnergySavingCheckboxGroupValuesEnum.hasEnergySavingNone)
              }
            />
            <CheckboxWithImageLabel
              name={EnergySavingCheckboxGroupValuesEnum.hasEnergySavingNone}
              imageUrl={IconNo}
              label={intl.formatMessage({ id: 'Keine' })}
              {...getCheckboxProps({ value: EnergySavingCheckboxGroupValuesEnum.hasEnergySavingNone })}
              required={Array.isArray(groupValue) && groupValue.length === 0}
            />
          </HStack>
        </CheckboxGroup>
      </div>
    </>
  )
}
