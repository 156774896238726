/// <reference types="vite-svg-loader" />
import { FormFieldNameEnum } from '@/features/fields/FormFieldNameEnum'
import { addFormGroup } from '@/store/features/formGroupsSlice'
import { useAppDispatch } from '@/store/store'
import { FormizStep } from '@formiz/core'
import { UseFieldProps } from '@formiz/core/dist/types/field.types'
import { useEffect } from 'react'
import { injectIntl } from 'react-intl'
import { IntlShape } from 'react-intl/src/types'
import IconModernisierung from '../../assets/icons/oeg-konfigurator-icon-modernisierung.svg'
import IconNeubau from '../../assets/icons/oeg-konfigurator-icon-neubau.svg'
import { SelectSingleOptionWithRadioCards } from '../fields/SelectSingleOptionWithRadioCards'

export enum ConfiguratorModesKeysEnum {
  newBuilding = 'newBuilding',
  modernisation = 'modernisation',
}

interface StepChooseProjectTypePropsInterface extends UseFieldProps {
  intl: IntlShape
  isVisible: boolean

  label: string
}

const StepChooseProjectType = (props: StepChooseProjectTypePropsInterface) => {
  const { name, label, isVisible, intl } = props

  const dispatch = useAppDispatch()
  useEffect(() => {
    if (isVisible) {
      dispatch(
        addFormGroup({
          name: name,
          label: label,
          fields: [FormFieldNameEnum.selectProjectType],
        })
      )
    }
  }, [dispatch, isVisible, label, name])

  return (
    <FormizStep name={name} label={label} isEnabled={isVisible} data-testid={name}>
      <SelectSingleOptionWithRadioCards
        autoSubmitStep={true}
        label={intl.formatMessage({ id: 'Was planen Sie für ein Projekt?' })}
        required={intl.formatMessage({ id: 'Bitte wählen' })}
        allowChange={false}
        name={FormFieldNameEnum.selectProjectType}
        options={[
          {
            value: ConfiguratorModesKeysEnum.newBuilding,
            label: intl.formatMessage({ id: 'Neubau' }),
            imageComponent: IconNeubau,
          },
          {
            value: ConfiguratorModesKeysEnum.modernisation,
            label: intl.formatMessage({ id: 'Modernisierung' }),
            imageComponent: IconModernisierung,
          },
        ]}
      />
    </FormizStep>
  )
}

export default injectIntl(StepChooseProjectType)
