interface PropertiesInterface {
  knownOilConsume: number
  fWindows: number
  fRoof: number
  fWalls: number
  fSperr: number
  tVoll:
    | number
    | 2100 /* single with heating */
    | 1800 /* single without heating */
    | 2000 /* multi family with heating */
    | 1700 /* multi family without heating */
}

/**
 * 18) Ich kenne den Ölverbrauch (500 - 8000 Liter/Jahr)
 *     bei Heizöl EL = 10 kWh/l -> -15% Verlust ηKessel = 0,85 und tvoll = 2100 h/a
 */
export const calculateLoadFromConsumedOil = ({
  knownOilConsume: consume,
  fWindows,
  fRoof,
  fWalls,
  fSperr,
  tVoll,
}: PropertiesInterface): { kW: number } => {
  const nKessel = 0.85
  const fkWhLiter = 10
  const qWP = ((consume * nKessel * fkWhLiter) / tVoll) * fWindows * fRoof * fWalls * fSperr

  return {
    kW: parseFloat(qWP.toFixed(3)),
  }
}
