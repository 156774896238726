import { StatisticDataInterface } from '@/features/statistic/StatisticUsageTracker'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react'

export interface UserStatsInterface extends StatisticDataInterface {
  id: number
  ip: string
  created: string // '2023-03-20T09:26:28+01:00'
  preFillValues?: { [key: string]: string[] }
}

export interface ListResponse<T> {
  '@context': string
  '@id': string
  '@type': string
  'hydra:totalItems': number
  'hydra:member': T[]
}

export const userStatsApiSlice = createApi({
  reducerPath: 'userStatsApiSlice',
  baseQuery: fetchBaseQuery({
    baseUrl: import.meta.env.VITE_API_ENDPOINT_STATISTIC_USAGE_STATS,
  }),
  tagTypes: ['UserStats'],
  keepUnusedDataFor: 999999,
  endpoints: (builder) => ({
    getFullHistory: builder.query<ListResponse<UserStatsInterface>, number | void>({
      query: (page = 1) => {
        return {
          method: 'GET',
          url: `?reason=SUMMARY&order[created]=desc&page=${page}`,
          headers: {
            accept: 'application/ld+json',
          },
        }
      },
      providesTags: (result) => {
        return result
          ? [
              // Provides a tag for each post in the current page,
              // as well as the 'PARTIAL-LIST' tag.
              ...result['hydra:member'].map(({ id }) => ({ type: 'UserStats' as const, id })),
              { type: 'UserStats', id: 'PARTIAL-LIST' },
            ]
          : [{ type: 'UserStats', id: 'PARTIAL-LIST' }]
      },
    }),
    getHistoryNoPumps: builder.query<ListResponse<UserStatsInterface>, number | void>({
      query: (page = 1) => {
        return {
          method: 'GET',
          url: `?reason=NO-PUMPS&order[created]=desc&page=${page}`,
          headers: {
            accept: 'application/ld+json',
          },
        }
      },
      providesTags: (result) => {
        return result
          ? [
              // Provides a tag for each post in the current page,
              // as well as the 'PARTIAL-LIST' tag.
              ...result['hydra:member'].map(({ id }) => ({ type: 'UserStats' as const, id })),
              { type: 'UserStats', id: 'PARTIAL-LIST-NP' },
            ]
          : [{ type: 'UserStats', id: 'PARTIAL-LIST-NP' }]
      },
    }),
    getSummariesOfCustomer: builder.query<UserStatsInterface[], void>({
      query: (customerId) => {
        return {
          method: 'GET',
          url: '?reason=SUMMARY&customerId=' + customerId,
          headers: {
            accept: 'application/json',
          },
        }
      },
      providesTags: (result) =>
        result
          ? [...result.map(({ id }) => ({ type: 'UserStats' as const, id })), { type: 'UserStats', id: 'LIST' }]
          : [{ type: 'UserStats', id: 'LIST' }],
    }),
    getPreFillDataById: builder.query<UserStatsInterface, number>({
      query: (preFillId) => {
        return {
          method: 'GET',
          url: '/' + preFillId,
          headers: {
            accept: 'application/json',
          },
        }
      },
      transformResponse(userStats: UserStatsInterface) {
        if (!userStats || typeof userStats.formValues === 'undefined') {
          throw Error('invalid data')
        }
        let formValuesRaw = {}

        for (const dataPoint of userStats.formValues ?? []) {
          if (typeof dataPoint['selectProjectType'] === 'string') {
            formValuesRaw = dataPoint
          }
        }
        userStats.preFillValues = formValuesRaw

        return userStats
      },
    }),
    sendUsageStats: builder.mutation<UserStatsInterface, Partial<UserStatsInterface>>({
      query(statisticData: StatisticDataInterface) {
        return {
          url: import.meta.env.VITE_API_ENDPOINT_STATISTIC_USAGE,
          method: 'POST',
          body: JSON.stringify(statisticData, (key, value) => (typeof value === 'undefined' ? null : value)),
        }
      },
      // Invalidates all Post-type queries providing the `LIST` id - after all, depending of the sort order,
      // that newly created post could show up in any lists.
      invalidatesTags: [{ type: 'UserStats', id: 'LIST' }],
    }),
  }),
})

export const {
  useGetFullHistoryQuery,
  useLazyGetPreFillDataByIdQuery,
  useGetPreFillDataByIdQuery,
  useGetHistoryNoPumpsQuery,
  useSendUsageStatsMutation,
} = userStatsApiSlice
