import { HeatingLoadBuildingResult } from '@/features/math/calculateBuildingHeatLoad'
import { calculateFactorSperr } from './../../../factor/calculateFactorSperr'

interface HeatingLoadBuildingProps {
  qH_BuildingHeatingLoad: number
  // dauert der Sperrzeiten see https://www.westnetz.de/de/faq/waermepumpen.html
  hoursDisabled: number
}

/**
 * QWP[W] = fsperr x qH[kW] x 1000
 *
 * @param normTempOutside
 * @param qH
 * @param hoursDisabled
 */
export const calculateHeatingLoadKnownLoadMultiFamily = ({
  qH_BuildingHeatingLoad: qH,
  hoursDisabled,
}: HeatingLoadBuildingProps): HeatingLoadBuildingResult => {
  console.log('calculateHeatingLoadKnownLoadMultiFamily', {
    qH: qH,
    hoursDisabled: hoursDisabled,
  })
  let qwp = 0
  const fSperr = calculateFactorSperr({ hoursDisabled })

  // Ich kenne die Gebäudeheizlast
  // Eingabe: qH = 1-50[kW]
  if (!qH || qH < 1) {
    throw Error('needs buildingHeatingLoad (qH)')
  }

  // QWP[W] = fsperr x qH[kW] x 1000
  qwp = fSperr * qH * 1000

  // convert to kW -> QWP[W]÷ 1000
  qwp = qwp / 1000

  return {
    kW: parseFloat('' + qwp.toFixed(3)),
  }
}
