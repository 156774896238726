import { FormFieldNameEnum } from '@/features/fields/FormFieldNameEnum'
import { addFormGroup } from '@/store/features/formGroupsSlice'
import { useAppDispatch } from '@/store/store'
import { FormizStep, useForm } from '@formiz/core'
import { UseFieldProps } from '@formiz/core/dist/types/field.types'
import { useEffect } from 'react'
import { injectIntl } from 'react-intl'
import { IntlShape } from 'react-intl/src/types'
import { EnergySavingCheckboxGroup } from '../../fields/EnergySavingCheckboxGroup'
import { BuildingTypesKeysEnum } from '../StepChooseBuildingType'
import { ConfiguratorModesKeysEnum } from '../StepChooseProjectType'

interface StepEnergySavingMeasuresPropsInterface extends UseFieldProps {
  intl: IntlShape
}

const StepModernisationEnergySavingMeasures = (props: StepEnergySavingMeasuresPropsInterface) => {
  const { name, intl } = props
  const label = intl.formatMessage({ id: 'Energiesparmaßnahmen' })
  const myForm = useForm({ subscribe: 'fields' })

  const isVisible =
    myForm.flatValues[FormFieldNameEnum.selectBuildingType] !== BuildingTypesKeysEnum.nonResidentialBuilding &&
    myForm.flatValues[FormFieldNameEnum.selectProjectType] === ConfiguratorModesKeysEnum.modernisation

  const dispatch = useAppDispatch()
  useEffect(() => {
    if (isVisible) {
      dispatch(
        addFormGroup({
          name: name,
          label: label,
          fields: [FormFieldNameEnum.energySavingCheckboxGroup],
        })
      )
    }
  }, [dispatch, isVisible, name, label, myForm.flatValues])

  return (
    <FormizStep name={name} label={label} isEnabled={isVisible} data-testid={name}>
      <EnergySavingCheckboxGroup name={FormFieldNameEnum.energySavingCheckboxGroup} intl={intl} />
    </FormizStep>
  )
}

export default injectIntl(StepModernisationEnergySavingMeasures)
