import { FormFieldNameEnum } from '@/features/fields/FormFieldNameEnum'
import { getHeatingLoad, getNormTemperatur } from '@/store/features/userDataSlice'
import { useAppSelector } from '@/store/store'
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  FormControl,
  FormLabel,
  SimpleGrid,
  Switch,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Tfoot,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react'
import { useForm } from '@formiz/core'

const DebugSummary = () => {
  const myFormInStep = useForm()
  const normTempValue = useAppSelector(getNormTemperatur)
  const heatingLoadValue = useAppSelector(getHeatingLoad)

  return (
    <>
      <Accordion allowToggle={true} defaultIndex={0}>
        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box as="span" flex="1" textAlign="left" fontSize={'xl'} color={'red'}>
                ENTWICKLER / TESTER (wird noch entfernt)
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <TableContainer w={'100%'}>
              <Table variant="striped">
                <TableCaption>
                  Zusammenfassung der Auswahl (NUR Darstellung) Zahlenformat US HeatingLoad: 1.1234 = HeatingLoad 1,1234
                  kW
                </TableCaption>
                <Thead>
                  <Tr>
                    <Th>Name</Th>
                    <Th isNumeric>Wert</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  <Tr>
                    <Td>ProjectType</Td>
                    <Td isNumeric>{myFormInStep.flatValues[FormFieldNameEnum.selectProjectType]}</Td>
                  </Tr>
                  <Tr>
                    <Td>BuildingType</Td>
                    <Td isNumeric>{myFormInStep.flatValues[FormFieldNameEnum.selectBuildingType]}</Td>
                  </Tr>
                  <Tr>
                    <Td>HeatingLoad</Td>
                    <Td isNumeric>{heatingLoadValue}</Td>
                  </Tr>
                  <Tr>
                    <Td>NormTemp</Td>
                    <Td isNumeric>{normTempValue}</Td>
                  </Tr>
                </Tbody>
                <Tfoot>
                  <Tr>
                    <Th>Name</Th>
                    <Th isNumeric>Wert</Th>
                  </Tr>
                </Tfoot>
              </Table>
            </TableContainer>

            <FormControl as={SimpleGrid} columns={{ base: 2 }} alignContent={'center'}>
              <FormLabel htmlFor="roomHeatingSolar">Heizungsunterstützung</FormLabel>
              <Switch
                id="roomHeatingSolar"
                isReadOnly
                isChecked={myFormInStep.flatValues.selectUseSolarHeatingSupport === 'yes'}
              />

              <FormLabel htmlFor="roomCooling">Raumkühlung:</FormLabel>
              <Switch id="roomCooling" isReadOnly isChecked={myFormInStep.flatValues.selectUseAreaCooling === 'yes'} />

              <FormLabel htmlFor="warmWater">Trinkwassererwärmung:</FormLabel>
              <Switch
                id="warmWater"
                isReadOnly
                isChecked={myFormInStep.flatValues.selectUseToGenerateWarmWater === 'yes'}
              />
            </FormControl>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
      {/*<HeatPumpGrid userInputHeatingLoad={heatingLoadValue} userInputNormTemp={normTempValue} />*/}
      {/*<HeatPumpCharts userInputHeatingLoad={heatingLoadValue} userInputNormTemp={normTempValue} />*/}
    </>
  )
}

export default DebugSummary
