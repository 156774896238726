import { RootState } from '@/store/store'
import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import uniqid from 'uniqid'

const initialState = {
  heatingLoad: 0,
  heatingLoadOriginal: 0,
  normTemp: 0,
  context: {
    sessionId: '',
    languageCode: '',
    isLoggedIn: false,
    pricesVisible: true,
    currency: 'EUR',
    debug: false,
  },
}
interface HeatingLoadInterface {
  heatingLoad: number
}
interface NormTemperaturInterface {
  normTemp: number
}
interface UserContextInterface {
  languageCode: string
  isLoggedIn: boolean
  customerId?: number
  customerExternalId?: string
  companyName?: string
  pricesVisible: boolean
  currency: string
  debug: boolean
}

const UserDataSlice = createSlice({
  name: 'userDataSlice',
  initialState: initialState,
  reducers: {
    setHeatingLoad: (state, param: PayloadAction<HeatingLoadInterface>) => {
      state.heatingLoad = param.payload.heatingLoad
    },
    setHeatingLoadOriginal: (state, param: PayloadAction<HeatingLoadInterface>) => {
      state.heatingLoadOriginal = param.payload.heatingLoad
    },
    setNormTemperatur: (state, param: PayloadAction<NormTemperaturInterface>) => {
      state.normTemp = param.payload.normTemp
    },
    setUserContext: (state, param: PayloadAction<UserContextInterface>) => {
      state.context = { ...param.payload, sessionId: uniqid('hppc-') }
    },
  },
})

export const getHeatingLoad = (state: RootState) => state.userDataSlice.heatingLoad
export const getHeatingLoadOriginal = (state: RootState) => state.userDataSlice.heatingLoadOriginal
export const getNormTemperatur = (state: RootState) => state.userDataSlice.normTemp
export const getLanguageCode = (state: RootState) => state.userDataSlice.context.languageCode
export const getCustomerId = (state: RootState) => state.userDataSlice.context.customerId ?? null
export const getSessionId = (state: RootState) => state.userDataSlice.context.sessionId
export const getCurrencyCode = (state: RootState) => state.userDataSlice.context.currency ?? 'EUR'
export const isDebugEnabled = (state: RootState) => state.userDataSlice.context.debug ?? false
export const isUserLoggedIn = (state: RootState) => state.userDataSlice.context.isLoggedIn ?? false
export const pricesVisible = (state: RootState) => state.userDataSlice.context.pricesVisible ?? true

const { actions, reducer } = UserDataSlice
export const { setHeatingLoad, setHeatingLoadOriginal, setNormTemperatur, setUserContext } = actions
export default reducer
