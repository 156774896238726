interface PAdditional {
  heatingCapacityInKwAtSub15Degrees: number
  heatingCapacityInKwAtSub10Degrees: number
  valueOfNormOutsideTemp: number
  buildingHeatingLoad: number
}

export interface PAdditionalResult {
  pAdditional: number
}

export function calculatePAdditional({
  heatingCapacityInKwAtSub10Degrees,
  heatingCapacityInKwAtSub15Degrees,
  buildingHeatingLoad,
  valueOfNormOutsideTemp,
}: PAdditional): PAdditionalResult {
  const pAdditional_x1 = -15 // fixed value (egal für welche vorlauf temp)
  const pAdditional_x2 = -10 // fixed value (egal für welche vorlauf temp)
  // let y1 = 3.54; // from matrix value of 35° at -15 außen temp
  // let y1 = heatingCapacityInKwAtSub15Degrees;
  // let y2 = 4.16; // from matrix value of 35° at -10 außen temp
  // let y2 = heatingCapacityInKwAtSub10Degrees;

  // m1 = 0,124 (aus: (y1-y2)/(pAdditional_x1-pAdditional_x2) )
  const m1 = (heatingCapacityInKwAtSub15Degrees - heatingCapacityInKwAtSub10Degrees) / (pAdditional_x1 - pAdditional_x2)
  // b1 = 5,4 ( y1-(m1*pAdditional_x1) )
  const b1 = heatingCapacityInKwAtSub15Degrees - m1 * pAdditional_x1

  // Y1 = m1*pAdditional_x1+b1
  // Y1 = 3,912
  const Y1 = m1 * valueOfNormOutsideTemp + b1

  // PZusatz = 	6,09 (aus: heizlastWert - Y1)
  const PZusatz = buildingHeatingLoad - Y1

  return {
    pAdditional: parseFloat(PZusatz.toFixed(3)),
  }
}
