import { HeatingLoadBuildingResult } from '@/features/math/calculateBuildingHeatLoad'
import { calculateFactorOutsideTempCorrection } from '@/features/math/factor/calculateFactorOutsideTempCorrection'
import { calculateFactorSperr } from '@/features/math/factor/calculateFactorSperr'

interface HeatingLoadBuildingProps {
  // W/m²
  qSpez: number
  // m²
  habitableSpace: number
  // Norm-Außentemperatur
  normTempOutside: number
  // dauert der Sperrzeiten see https://www.westnetz.de/de/faq/waermepumpen.html
  hoursDisabled: number
}

export const calculateHeatLoadNoKnownMultiFamily = ({
  habitableSpace: Ages,
  qSpez,
  normTempOutside,
  hoursDisabled,
}: HeatingLoadBuildingProps): HeatingLoadBuildingResult => {
  console.log('calculateHeatLoadNoKnownMultiFamily', {
    habitableSpace: Ages,
    qSpez: qSpez,
    normTempOutside: normTempOutside,
    hoursDisabled: hoursDisabled,
  })
  const fATKorr = calculateFactorOutsideTempCorrection({ normTempOutside: normTempOutside })
  const fSperr = calculateFactorSperr({ hoursDisabled })
  // QWP[W] = ( qspez[W/m²]) x Ages[m²] x fATKorr x fsperr
  let qWP = qSpez * Ages * fATKorr * fSperr

  // convert to kW -> QWP[W]÷ 1000
  qWP = qWP / 1000

  return {
    kW: parseFloat('' + qWP.toFixed(3)),
  }
}
