import { NoMatchesGridItem } from '@/features/heatPumpSet/HeatPumpSetFilterDataProvider'
import { HeatPumpSets } from '@/features/heatPumpSet/HeatPumpSets'
import { HeatPumpSetsFilter } from '@/features/heatPumpSet/HeatPumpSetsFilter'
import StatisticUsageTracker from '@/features/statistic/StatisticUsageTracker'
import { selectFilteredHeatPumpPackages } from '@/selector/heatPumpPackages'
import { useAppSelector } from '@/store/store'
import { GridItem } from '@chakra-ui/react'
import type { IntlShape } from 'react-intl'

export const HeatPumpSetsContent = ({ intl }: { intl: IntlShape }) => {
  const filteredPacks = useAppSelector(selectFilteredHeatPumpPackages)

  const content = []
  if (filteredPacks.length === 0) {
    content.push(<NoMatchesGridItem key="no_matches_grid" />)
  } else {
    content.push(<StatisticUsageTracker mountTracking={true} key="packages_stats" reason="SUMMARY" />)
    content.push(
      <GridItem key="packages_filter_grid" colSpan={1} area={'nav'}>
        <HeatPumpSetsFilter intl={intl} />
      </GridItem>
    )
    content.push(
      <GridItem key="packages_content_grid" colSpan={2} area={'main'} className="heat-pump-pack-details-wrapper">
        <HeatPumpSets />
      </GridItem>
    )
  }

  return <>{content}</>
}
