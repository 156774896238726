import { scrollParentWindowToTop } from '@/features/helper/iFrameHelper'
import { WarningIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  FormControl,
  Heading,
  HStack,
  Image,
  Popover,
  PopoverBody,
  PopoverContent,
  Text,
  useRadioGroup,
} from '@chakra-ui/react'
import { useField, useForm } from '@formiz/core'
import { UseFieldProps } from '@formiz/core/dist/types/field.types'
import { FormattedMessage } from 'react-intl'
import { RadioCard, RadioOption } from './RadioCard'

interface SelectSingleOptionWithRadioCardsInterface {
  options: RadioOption[]
  label?: string
  helper?: string
  autoSubmitStep?: boolean
  autoSubmitStepValue?: string
  autoSubmitStepCallback?: (value: string) => boolean
  injectOnChange?: (value: string) => void
  boxSize?: string
  allowChange?: boolean
}
export const SelectSingleOptionWithRadioCards = (props: UseFieldProps & SelectSingleOptionWithRadioCardsInterface) => {
  const {
    setValue,
    isPristine,
    isSubmitted,
    isValid,
    isValidating,
    errorMessage,
    value: currentFieldValue,
  } = useField(props)
  const {
    name,
    options,
    label,
    autoSubmitStep,
    autoSubmitStepValue,
    autoSubmitStepCallback,
    boxSize,
    helper,
    injectOnChange,
    allowChange,
  } = props
  const myForm = useForm()
  const showError = !isValid && !isValidating && isSubmitted

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: name,
    // defaultValue: ConfiguratorModesKeysEnum.modernisation,
    // value: ConfiguratorModesKeysEnum.modernisation,
    onChange: (value) => {
      setValue(value)
      if (typeof injectOnChange === 'function') {
        injectOnChange(value)
      }

      if (
        autoSubmitStep ||
        (autoSubmitStepValue && value === autoSubmitStepValue) ||
        (autoSubmitStepCallback && autoSubmitStepCallback(value))
      ) {
        setTimeout(myForm.submitStep, 500)
        setTimeout(() => {
          scrollParentWindowToTop()
        }, 750)
      }
    },
  })

  const isEditPreventionActive = allowChange === false && !isPristine && isSubmitted

  const group = getRootProps({
    id: name,
  })

  return (
    <Box as={FormControl} className={'radio-stack radio-stack-' + name} mb={8}>
      {label ? (
        <Box p={5} shadow="md">
          <Heading fontSize="xl" className={'radio-stack-headline radio-stack-headline-' + name}>
            {label}
          </Heading>
        </Box>
      ) : null}
      {helper ? (
        <Box p={5} textAlign={'center'}>
          <Text fontSize="xs" className={'radio-stack-helper radio-stack-helper-' + name}>
            {helper}
          </Text>
        </Box>
      ) : null}

      {isEditPreventionActive ? (
        <Box
          alignContent={'center'}
          textAlign={'center'}
          mb={5}
          pb={5}
          bg={'lightblue'}
          className={'radio-stack-helper-info'}
        >
          <Text p={10} bg={'lightblue'} fontWeight={'bold'}>
            <FormattedMessage id={'Anpassung dieser Auswahl ist leider nicht möglich'} />
            {'. '}
            <FormattedMessage id={'Starten Sie den Konfigurator neu wenn Sie eine andere Auswahl treffen wollen'} />.
          </Text>
          <Button
            gridColumn="1"
            onClick={() => window.location.replace('/')}
            colorScheme="brand"
            className="is-secondary"
            rounded={0}
            size={'sm'}
          >
            <FormattedMessage id="Reset" />
          </Button>
        </Box>
      ) : null}
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      <HStack mb={5} {...group} data-testid={name} className={'radio-stack-options-' + name}>
        {options.map((valueItem: RadioOption, index) => {
          const value = valueItem.value
          const radio = getRadioProps({ value })
          if (value === currentFieldValue) {
            radio.isChecked = true
          }

          return (
            /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
            /* @ts-ignore */
            <RadioCard
              key={valueItem.value + index}
              {...radio}
              isDisabled={(valueItem.isDisabled || isEditPreventionActive) ?? false}
              name={'radioStackOption-' + name}
            >
              {valueItem.imageSrc ? (
                <Image
                  borderRadius="full"
                  boxSize={boxSize ?? '150px'}
                  src={valueItem.imageSrc}
                  alt={valueItem.label}
                />
              ) : null}
              {valueItem.imageComponent && typeof valueItem.imageComponent === 'object' ? (
                <Image
                  borderRadius="full"
                  boxSize={boxSize ?? '150px'}
                  src={valueItem.imageComponent.render()?.toString()}
                  alt={valueItem.label}
                />
              ) : null}
              {valueItem.isDisabled ? (
                <Popover placement={'auto'} defaultIsOpen={true} closeOnEsc={false} closeOnBlur={false}>
                  <PopoverContent textAlign={'center'}>
                    <PopoverBody>
                      <FormattedMessage id={'Diese Option ist mit der aktuellen Konfiguration nicht auswählbar'} />
                    </PopoverBody>
                  </PopoverContent>
                  {valueItem.label}
                </Popover>
              ) : (
                <> {valueItem.label}</>
              )}
            </RadioCard>
          )
        })}
      </HStack>
      {showError ? (
        <Text color={'red.500'}>
          <WarningIcon mr="2" />
          {errorMessage}
        </Text>
      ) : null}
    </Box>
  )
}
