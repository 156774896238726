import { FormFieldNameEnum } from '@/features/fields/FormFieldNameEnum'
import { SliderWithStepMarks } from '@/features/fields/SliderWithStepMarks'
import { addFormGroup } from '@/store/features/formGroupsSlice'
import { useAppDispatch } from '@/store/store'
import { FormizStep, useForm } from '@formiz/core'
import { UseFieldProps } from '@formiz/core/dist/types/field.types'
import { isInRangeNumber } from '@formiz/validations'
import { useEffect } from 'react'
import { injectIntl } from 'react-intl'
import { IntlShape } from 'react-intl/src/types'
import IconNo from '../../assets/icons/oeg-konfigurator-icon-nein.svg'
import IconYesSolar from '../../assets/icons/oeg-konfigurator-icon-pv-anlage.svg'
import IconYesEnergy from '../../assets/icons/oeg-konfigurator-icon-stromtarif.svg'
import { SelectSingleOptionWithRadioCards } from '../fields/SelectSingleOptionWithRadioCards'
import SelectStandardTemperature from '../fields/SelectStandardTemperature'

interface StepAdditionalInformationPropsInterface extends UseFieldProps {
  intl: IntlShape
  isVisible: boolean
}

const StepAdditionalInformation = (props: StepAdditionalInformationPropsInterface) => {
  const { name, isVisible, intl } = props
  const label = intl.formatMessage({ id: 'Weitere Angaben' })

  const myForm = useForm()
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (isVisible) {
      const fields = [
        FormFieldNameEnum.selectUseToGenerateSolarEnergy,
        FormFieldNameEnum.selectUseEnergyWithTimeLocks,
        FormFieldNameEnum.knownNormTemp,
      ]

      if (myForm.flatValues.selectUseEnergyWithTimeLocks === 'yes') {
        fields.push(FormFieldNameEnum.energyWithTimeLocksHours)
      }

      if (myForm.flatValues[FormFieldNameEnum.knownNormTemp] === 'yes') {
        fields.push(FormFieldNameEnum.normTemp)
      }

      if (myForm.flatValues[FormFieldNameEnum.knownNormTemp] === 'no') {
        fields.push(FormFieldNameEnum.normTempFinder)
      }

      dispatch(
        addFormGroup({
          name: name,
          label: label,
          fields: fields,
        })
      )
    }
  }, [dispatch, isVisible, name, label, myForm.flatValues])

  return (
    <FormizStep name={name} label={label} isEnabled={isVisible} data-testid={name}>
      <SelectSingleOptionWithRadioCards
        label={intl.formatMessage({ id: 'Nutzen oder planen Sie eine PV-Anlage zur Stromproduktion?' })}
        required={intl.formatMessage({ id: 'Nutzen oder planen Sie eine PV-Anlage zur Stromproduktion?' })}
        name={FormFieldNameEnum.selectUseToGenerateSolarEnergy}
        options={[
          {
            imageComponent: IconYesSolar,
            value: 'yes',
            label: intl.formatMessage({ id: 'Ja' }),
          },
          {
            imageComponent: IconNo,
            value: 'no',
            label: intl.formatMessage({ id: 'Nein' }),
          },
        ]}
      />

      <SelectSingleOptionWithRadioCards
        label={intl.formatMessage({
          id: 'Nutzen Sie einen günstigeren Stromtarif, bei dem der Anbieter Sperrzeiten vorgeben kann?',
        })}
        required={intl.formatMessage({
          id: 'Nutzen Sie einen günstigeren Stromtarif, bei dem der Anbieter Sperrzeiten vorgeben kann?',
        })}
        name={FormFieldNameEnum.selectUseEnergyWithTimeLocks}
        options={[
          {
            imageComponent: IconYesEnergy,
            value: 'yes',
            label: intl.formatMessage({ id: 'Ja' }),
          },
          {
            imageComponent: IconNo,
            value: 'no',
            label: intl.formatMessage({ id: 'Nein' }),
          },
        ]}
      />
      {myForm.flatValues.selectUseEnergyWithTimeLocks === 'yes' ? (
        <>
          <SelectSingleOptionWithRadioCards
            label={intl.formatMessage({ id: 'Stunden pro Tag gesperrt' })}
            required={intl.formatMessage({ id: 'Stunden pro Tag gesperrt' })}
            name={FormFieldNameEnum.energyWithTimeLocksHours}
            options={[
              {
                value: '2',
                label: '2h',
              },
              {
                value: '4',
                label: '4h',
              },
              {
                value: '6',
                label: '6h',
              },
              {
                value: '6.0',
                label: intl.formatMessage({ id: 'nicht bekannt' }),
              },
            ]}
          />
        </>
      ) : null}

      <SelectSingleOptionWithRadioCards
        label={intl.formatMessage({ id: 'Ist die Normaußentemperatur bekannt?' })}
        required={intl.formatMessage({ id: 'Die Normaußentemperatur ist für die Berechnung erforderlich' })}
        helper={intl.formatMessage({
          id: 'Normaußentemperatur, nach DIN EN 12831, ist die tiefste Temperatur, welche 10 Mal innerhalb von 20 Jahren an zwei aufeinanderfolgende Tage, gemessen wurde.',
        })}
        name={FormFieldNameEnum.knownNormTemp}
        options={[
          {
            // imageComponent: IconYes,
            value: 'yes',
            label: intl.formatMessage({ id: 'Ja' }),
          },
          {
            // imageComponent: IconNo,
            value: 'no',
            label: intl.formatMessage({ id: 'Nein, über Postleitzahl ermitteln' }),
          },
        ]}
      />

      {myForm.flatValues[FormFieldNameEnum.knownNormTemp] === 'yes' ? (
        <SliderWithStepMarks
          name={FormFieldNameEnum.normTemp}
          minValue={-20}
          maxValue={0}
          defaultValue={myForm.flatValues[FormFieldNameEnum.normTemp] ?? -10}
          unitMarker={'°C'}
          label={intl.formatMessage({ id: 'Normaußentemperatur in °C' })}
          required={intl.formatMessage({ id: 'Normaußentemperatur in °C' })}
          validations={[
            {
              rule: isInRangeNumber(-20, 0),
              message: intl.formatMessage({ id: 'Die Normaußentemperatur sollte zwischen -20°C und 0°C liegen' }),
            },
          ]}
        />
      ) : null}

      {myForm.flatValues[FormFieldNameEnum.knownNormTemp] === 'no' ? (
        <SelectStandardTemperature name={FormFieldNameEnum.normTempFinder} />
      ) : null}
    </FormizStep>
  )
}

export default injectIntl(StepAdditionalInformation)
