/// <reference types="vite-svg-loader" />
import AppConfig from '@/app/AppConfig'
import IconYes from '@/assets/icons/oeg-konfigurator-icon-ja.svg'
import IconNo from '@/assets/icons/oeg-konfigurator-icon-nein.svg'
import { FormFieldNameEnum } from '@/features/fields/FormFieldNameEnum'
import { RadioOption } from '@/features/fields/RadioCard'
import { FieldsEnergySourceAndConsumption } from '@/features/heatingLoad/FieldsEnergySourceAndConsumption'
import { StepHeatingLoadValuesEnum } from '@/features/steps/StepHeatingLoad'
import { addFormGroup } from '@/store/features/formGroupsSlice'
import { useAppDispatch } from '@/store/store'
import { FormizStep, useForm } from '@formiz/core'
import { UseFieldProps } from '@formiz/core/dist/types/field.types'
import { isInRangeNumber, isMinNumber } from '@formiz/validations'
import { useEffect } from 'react'
import { injectIntl } from 'react-intl'
import { IntlShape } from 'react-intl/src/types'
import IconHeatingLoadCalculate from '../../../assets/icons/oeg-konfigurator-icon-berechnen.svg'
import IconHeatingLoadIsKnown from '../../../assets/icons/oeg-konfigurator-icon-heizlast-bekannt.svg'
import IconHeatingLoadByLoad from '../../../assets/icons/oeg-konfigurator-icon-verbrauch.svg'
import { EditableFiledWithWrapper } from '../../fields/EditableFiledWithWrapper'
import { SelectSingleOptionWithRadioCards } from '../../fields/SelectSingleOptionWithRadioCards'
import { SliderWithStepMarks } from '../../fields/SliderWithStepMarks'
import { BuildingTypesKeysEnum } from '../StepChooseBuildingType'
import { ConfiguratorModesKeysEnum } from '../StepChooseProjectType'

interface StepHeatingLoadModernisationPropsInterface extends UseFieldProps {
  intl: IntlShape
}

const StepHeatingLoadModernisation = (props: StepHeatingLoadModernisationPropsInterface) => {
  const { intl, name } = props
  const label = intl.formatMessage({ id: 'Heizlast' })
  const myForm = useForm()
  const isVisible = myForm.flatValues[FormFieldNameEnum.selectProjectType] === ConfiguratorModesKeysEnum.modernisation

  const dispatch = useAppDispatch()

  useEffect(() => {
    if (isVisible) {
      const fields = []
      if (myForm.flatValues[FormFieldNameEnum.selectBuildingType] === BuildingTypesKeysEnum.nonResidentialBuilding) {
        fields.push(FormFieldNameEnum.knownBuildingHeatingLoadValue)
      } else {
        fields.push(FormFieldNameEnum.heatingLoadSource)
        if (myForm.flatValues.heatingLoadSource === StepHeatingLoadValuesEnum.heatingLoadIsKnown) {
          fields.push(FormFieldNameEnum.knownBuildingHeatingLoadValue)
        }

        if (
          myForm.flatValues.heatingLoadSource &&
          myForm.flatValues.heatingLoadSource === StepHeatingLoadValuesEnum.heatingLoadCalculation
        ) {
          fields.push(FormFieldNameEnum.habitableSpace)
          fields.push(FormFieldNameEnum.buildingConstructionYear)
        }

        if (
          myForm.flatValues.heatingLoadSource &&
          myForm.flatValues.heatingLoadSource === StepHeatingLoadValuesEnum.heatingLoadByConsumption
        ) {
          fields.push(FormFieldNameEnum.selectCurrentHeatingSystemType)
          fields.push(FormFieldNameEnum.knownGasOrOilOrPowerConsumeValue)
          fields.push(FormFieldNameEnum.knownGasOrOilOrPowerConsumeValueIncludesWarmWater)
        }
      }

      dispatch(
        addFormGroup({
          name: name,
          label: label,
          fields: fields,
        })
      )
    }
  }, [dispatch, isVisible, name, label, myForm.flatValues])

  const getMaxValueByBuildingType = (type: string): number => {
    if (type === BuildingTypesKeysEnum.multiFamilyHouse) {
      return 900
    }

    if (type === BuildingTypesKeysEnum.singleFamilyHouse) {
      return 300
    }

    return 1000
  }

  const optionsBuildingConstructionYearSingleFamilyHouse: RadioOption[] = [
    // Baujahr vor 1958 (90-180 W/m²) Berechnungswert: qspez =  140 [W/m²]
    {
      value: '1950',
      label: intl.formatMessage({ id: 'vor' }) + ' 1958',
    },
    // Baujahr 1959 - 1968 ( W/m²) Berechnungswert: qspez =  130 [W/m²]
    {
      value: '1965',
      label: '1959-1968',
    },
    // Baujahr 1969 - 1979 ( W/m²) Berechnungswert: qspez = 120  [W/m²]
    {
      value: '1972',
      label: '1969-1973',
    },
    // Baujahr 1974 - 1977 ( W/m²) Berechnungswert: qspez =  115 [W/m²]
    {
      value: '1975',
      label: '1974-1977',
    },
    // Baujahr 1978 - 1983 ( W/m²) Berechnungswert: qspez =  95  [W/m²]
    {
      value: '1981',
      label: '1978-1983',
    },
    // Baujahr 1984 - 1994 ( W/m²) Berechnungswert: qspez = 75 [W/m²]
    {
      value: '1986',
      label: '1984-1994',
    },
    // Baujahr ab 1995 ( W/m²) Berechnungswert: qspez = 60  [W/m²]
    {
      value: '1996',
      label: intl.formatMessage({ id: 'ab' }) + ' 1995',
    },
  ]

  const optionsBuildingConstructionYearMultiFamilyHouse: RadioOption[] = [
    // Baujahr vor 1980 (90-180 W/m²) Berechnungswert: qspez = 120 [W/m²] < 8 WE qspez = 110 [W/m²] > 8 WE
    {
      value: '1975',
      label: intl.formatMessage({ id: 'vor' }) + ' 1980',
    },
    // Baujahr 1980 - 1995 (70 - 90 W/m²) Berechnungswert: qspez =  75 [W/m²]  < 8 WE qspez =  70 [W/m²] > 8 WE
    {
      value: '1990',
      label: '1981-1995',
    },
    // Baujahr 1995 - 2000 (50 - 60 W/m²) Berechnungswert: qspez =  55[W/m²]  < 8 WE qspez = 50 [W/m²] > 8 WE
    {
      value: '1997',
      label: '1996-2000',
    },
    // Baujahr 2000 - 2010 (40 - 60 W/m²) Berechnungswert: qspez =  45 [W/m²]  < 8 WE qspez = 40 [W/m²] > 8 WE
    {
      value: '2005',
      label: '2001-2010',
    },
    // Baujahr 2010 - 2022 (15 - 35 W/m²) Berechnungswert: qspez =  25[W/m²]  < 8 WE qspez =  20 [W/m²] > 8 WE
    {
      value: '2015',
      label: '2011-' + new Date().getFullYear(),
    },
  ]

  return (
    <FormizStep name={name} label={label} isEnabled={isVisible} data-testid={name}>
      <>
        {myForm.flatValues[FormFieldNameEnum.selectBuildingType] === BuildingTypesKeysEnum.nonResidentialBuilding ? (
          <>
            <EditableFiledWithWrapper
              name={FormFieldNameEnum.knownBuildingHeatingLoadValue}
              headline={intl.formatMessage({ id: 'Eingabe des Ergebnis der Heizlastberechnung des Gebäudes' })}
              label={intl.formatMessage({ id: 'Eingabe in kW' })}
              required={intl.formatMessage({ id: 'Pflichtfeld' })}
              type="number"
              minValue={AppConfig.form.heatingLoadMin}
              maxValue={AppConfig.form.heatingLoadMax}
              placeholder={'0'}
              validations={[
                {
                  rule: isInRangeNumber(AppConfig.form.heatingLoadMin, AppConfig.form.heatingLoadMax),
                  message: intl.formatMessage(
                    { id: 'Die Heizlast muss zwischen {s} und {s1} kW liegen' },
                    { s: AppConfig.form.heatingLoadMin, s1: AppConfig.form.heatingLoadMax }
                  ),
                },
              ]}
            />
          </>
        ) : (
          <>
            <SelectSingleOptionWithRadioCards
              required={intl.formatMessage({ id: 'Bitte wählen' })}
              name={FormFieldNameEnum.heatingLoadSource}
              options={[
                {
                  value: StepHeatingLoadValuesEnum.heatingLoadIsKnown,
                  label: intl.formatMessage({ id: 'Ich kenne die Heizlast' }),
                  imageComponent: IconHeatingLoadIsKnown,
                },
                {
                  value: StepHeatingLoadValuesEnum.heatingLoadByConsumption,
                  label: intl.formatMessage({ id: 'Ich kenne den Verbrauch' }),
                  imageComponent: IconHeatingLoadByLoad,
                },
                {
                  value: StepHeatingLoadValuesEnum.heatingLoadCalculation,
                  label: intl.formatMessage({ id: 'Heizlast berechnen' }),
                  imageComponent: IconHeatingLoadCalculate,
                },
              ]}
            />

            {myForm.flatValues.heatingLoadSource === StepHeatingLoadValuesEnum.heatingLoadIsKnown ? (
              <EditableFiledWithWrapper
                name={FormFieldNameEnum.knownBuildingHeatingLoadValue}
                headline={intl.formatMessage({ id: 'Ich kenne die Heizlast' })}
                label={intl.formatMessage({ id: 'Eingabe in kW' })}
                required={intl.formatMessage({ id: 'Eingabe in kW' })}
                type="number"
                placeholder={'0'}
                validations={[
                  {
                    rule: isMinNumber(AppConfig.form.heatingLoadMin),
                    message: intl.formatMessage(
                      { id: 'Die Heizlast muss zwischen {s} und {s1} kW liegen' },
                      { s: AppConfig.form.heatingLoadMin, s1: AppConfig.form.heatingLoadMax }
                    ),
                  },
                  {
                    rule: isInRangeNumber(AppConfig.form.heatingLoadMin, AppConfig.form.heatingLoadMax),
                    message: intl.formatMessage(
                      { id: 'Die Heizlast muss zwischen {s} und {s1} kW liegen' },
                      { s: AppConfig.form.heatingLoadMin, s1: AppConfig.form.heatingLoadMax }
                    ),
                  },
                ]}
              />
            ) : null}

            {myForm.flatValues.heatingLoadSource &&
            myForm.flatValues.heatingLoadSource === StepHeatingLoadValuesEnum.heatingLoadCalculation ? (
              <>
                <SliderWithStepMarks
                  name={FormFieldNameEnum.habitableSpace}
                  unitMarker={'m²'}
                  minValue={50}
                  defaultValue={myForm.flatValues[FormFieldNameEnum.habitableSpace] ?? 150}
                  maxValue={getMaxValueByBuildingType(myForm.flatValues[FormFieldNameEnum.selectBuildingType])}
                  label={intl.formatMessage({ id: 'Welche Wohnfläche hat das Gebäude?' })}
                  required={intl.formatMessage({ id: 'Wohnfläche ist eine Pflichtangabe' })}
                  validations={[
                    {
                      rule: isInRangeNumber(
                        50,
                        getMaxValueByBuildingType(myForm.flatValues[FormFieldNameEnum.selectBuildingType])
                      ),
                      message: intl.formatMessage(
                        { id: 'Die Wohnfläche muss zwischen {s} und {s1} liegen' },
                        {
                          s: 50,
                          s1: getMaxValueByBuildingType(myForm.flatValues[FormFieldNameEnum.selectBuildingType]),
                        }
                      ),
                    },
                  ]}
                />

                <SelectSingleOptionWithRadioCards
                  label={intl.formatMessage({ id: 'In welchem Jahr wurde das Gebäude gebaut?' })}
                  required={intl.formatMessage({ id: 'Bitte wählen' })}
                  name={FormFieldNameEnum.buildingConstructionYear}
                  options={
                    myForm.flatValues[FormFieldNameEnum.selectBuildingType] === BuildingTypesKeysEnum.singleFamilyHouse
                      ? optionsBuildingConstructionYearSingleFamilyHouse
                      : optionsBuildingConstructionYearMultiFamilyHouse
                  }
                />
              </>
            ) : null}

            {myForm.flatValues.heatingLoadSource &&
            myForm.flatValues.heatingLoadSource === StepHeatingLoadValuesEnum.heatingLoadByConsumption ? (
              <>
                <FieldsEnergySourceAndConsumption intl={intl} />
                <SelectSingleOptionWithRadioCards
                  label={intl.formatMessage({ id: 'Verbrauch inkl. Trinkwassererwärmung?' })}
                  required={intl.formatMessage({ id: 'Bitte wählen' })}
                  name={FormFieldNameEnum.knownGasOrOilOrPowerConsumeValueIncludesWarmWater}
                  options={[
                    {
                      imageComponent: IconYes,
                      value: 'yes',
                      label: intl.formatMessage({ id: 'Ja' }),
                    },
                    {
                      imageComponent: IconNo,
                      value: 'no',
                      label: intl.formatMessage({ id: 'Nein' }),
                    },
                  ]}
                />
              </>
            ) : null}
          </>
        )}
      </>
    </FormizStep>
  )
}

export default injectIntl(StepHeatingLoadModernisation)
