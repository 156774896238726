import { formValuesHelper } from '@/features/helper/formValuesHelper'
import { Alert, AlertDescription, AlertIcon, AlertTitle } from '@chakra-ui/react'
import { useForm } from '@formiz/core'
import * as Sentry from '@sentry/browser'
import { MouseEventHandler } from 'react'
import { FormattedMessage } from 'react-intl'

export default function ErrorFallback({
  error,
  resetErrorBoundary,
}: {
  error: Error
  resetErrorBoundary: MouseEventHandler
}) {
  const myForm = useForm()

  // todo: fancy error renderer
  console.error('FALLBACK_ERROR HANDLER: ', error, formValuesHelper({ myForm }), myForm.flatValues)
  Sentry.captureException(error)

  return (
    <Alert status="error">
      <AlertIcon />
      <AlertTitle>
        <FormattedMessage id="Es ist ein Fehler aufgetreten" />:
      </AlertTitle>
      <AlertDescription>
        <pre>{error.message}</pre>
        <button onClick={resetErrorBoundary}>
          <FormattedMessage id="Hier klicken um die Anwendung neu zu starten" />
        </button>
      </AlertDescription>
    </Alert>
  )
}
