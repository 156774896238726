import { selectFilteredHeatPumpPackages, selectRelevantHeatPumps } from '@/selector/heatPumpPackages'
import {
  HeatPumpPackageDetailsInterface,
  useLazyGetHeatPumpPackagesDetailsByHeatPumpPackagesQuery,
} from '@/store/features/heatPumpPackagesDetailsApiSlice'
import { getHeatingLoad, getHeatingLoadOriginal, getNormTemperatur } from '@/store/features/userDataSlice'
import { SimpleGrid, Spinner } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { HeatPumpPackageInterface } from '../../store/features/heatPumpPackagesApiSlice'
import { useAppSelector } from '../../store/store'
import { HeatPumpPackCard } from '../heatPump/HeatPumpPackCard'

export interface FilterFlagsInterface {
  filterOptionAdditionalDrinkingWaterHeating: boolean
  filterOptionCooling: boolean
  filterOptionDrinkingWaterHeating: boolean
  filterOptionHeating: boolean
  filterOptionHeatingSupport: boolean
  filterOptionMoreThanOneTank: boolean
  filterOptionPurePlusFreshWaterHeatExchanger: boolean
  filterOptionSgOptimized: boolean
  filterOptionTankLyingDown: boolean
  filterOptionTankStanding: boolean
  filterOptionWithOneTank: boolean
  relevantHeatPumpOegNumbers: string[]
  userInputHeatingLoad: number
  userInputNormTemp: number
  filterOptionTemperature35: boolean
  filterOptionTemperature45: boolean
  filterOptionTemperature55: boolean
  filterOptionTemperature70: boolean
  maxPersonsDrinkingWater: number
}

export const HeatPumpSets = () => {
  const filteredPacks = useAppSelector(selectFilteredHeatPumpPackages)
  const relevantPumps = useAppSelector(selectRelevantHeatPumps)
  const userInputNormTemp = useAppSelector(getNormTemperatur)
  const userInputHeatingLoad = useAppSelector(getHeatingLoad)
  const userInputHeatingLoadOriginal = useAppSelector(getHeatingLoadOriginal)

  // load details ONCE
  const [trigger, result] = useLazyGetHeatPumpPackagesDetailsByHeatPumpPackagesQuery(/*{ refetchOnFocus: true }*/)
  const [detailsLoaded, setDetailsLoaded] = useState(false)
  const [lastKnownFilterPackCount, setLastKnownFilterPackCount] = useState(0)

  useEffect(() => {
    if (filteredPacks.length === 0) {
      // skip load if no packs
      setDetailsLoaded(true)
      // console.log('was prevented trigger(filteredPacks)')
    } else {
      if (
        (!detailsLoaded || filteredPacks.length !== lastKnownFilterPackCount) &&
        result.isUninitialized &&
        !result.isLoading &&
        !result.isFetching
      ) {
        // console.log('trigger(filteredPacks)')
        trigger(filteredPacks)
        setLastKnownFilterPackCount(filteredPacks.length)
        setDetailsLoaded(true)
      }
    }
  }, [detailsLoaded, setDetailsLoaded, trigger, filteredPacks, result, lastKnownFilterPackCount])

  let filteredPacksDetails: HeatPumpPackageDetailsInterface[] = []
  if (result.isSuccess) {
    filteredPacksDetails = result.data
  }

  let output: JSX.Element = <></>
  if (result.isLoading) {
    output = <Spinner boxSize={50} thickness="3px" speed="0.85s" color="brand.500" />
  } else if (result.isSuccess && detailsLoaded) {
    console.log('found ' + filteredPacksDetails.length + ' details of heat pump packs sets')
    const getDetailsByHeatPumpPackId = (id: number): HeatPumpPackageDetailsInterface | undefined => {
      const item = filteredPacksDetails.find((item: HeatPumpPackageDetailsInterface) => item.id === id)
      if (typeof item === 'undefined') {
        trigger(filteredPacks).refetch()
        console.info('missing details of: ' + id)
      }
      return item
    }
    output = (
      <>
        {filteredPacks.map((heatPumpPack: HeatPumpPackageInterface, index) => {
          const details = getDetailsByHeatPumpPackId(heatPumpPack.productId)
          if (!details) {
            console.error(
              'missing details of: ' + heatPumpPack.productId + ' external: ' + heatPumpPack.productExternalId
            )
            return null
          }
          return (
            <HeatPumpPackCard
              key={heatPumpPack.productId + heatPumpPack.productExternalId + index}
              heatPumpPack={heatPumpPack}
              heatPumpPackDetails={details}
              relevantPumps={relevantPumps}
              normTemp={userInputNormTemp}
              heatingLoad={userInputHeatingLoad}
              heatingLoadOriginal={userInputHeatingLoadOriginal}
              formattedPrice={''}
            />
          )
        })}
      </>
    )
    // console.log('SHOW SETS')
  }

  return (
    <SimpleGrid key={'packs'} columns={1} spacing={5} mb={5} className="heat-pump-pack-details-wrapper-inner">
      {output}
    </SimpleGrid>
  )
}
