import TranslationsHelper from '@/features/helper/translationsHelper'
import { ArrowRightIcon } from '@chakra-ui/icons'
import { Box, Card, CardBody, CardHeader, Link, List, ListIcon, ListItem } from '@chakra-ui/react'
import { useForm } from '@formiz/core'

export interface ValueItemInterface {
  name: string
  fieldName: string
  stepName: string
  value: string | number
  unit?: string
  isDisabled?: boolean
}

export interface GroupValuesCardPropertiesInterface {
  headline: string
  values: ValueItemInterface[]
}

export const GroupValuesCard = (props: GroupValuesCardPropertiesInterface) => {
  const { headline, values } = props
  const form = useForm({ subscribe: false })

  return (
    <Card className={'grid-value-card'}>
      <CardHeader
        fontWeight={'bold'}
        color={'brand.headline'}
        className={'grid-value-card-header'}
        mb={1}
        height={'55px'}
      >
        {/*<Heading letterSpacing="wide" fontSize="2xs" color={'black'} textTransform="uppercase" textAlign={'left'}>*/}
        {headline}
        {/*</Heading>*/}
      </CardHeader>
      <CardBody>
        <List spacing={0} className={'grid-value-card-list'}>
          {values.map((valueItem, index) => {
            return (
              <ListItem
                key={index}
                fontSize={'xs'}
                mb={2}
                className={'grid-value-card-list-item ' + 'summary-matrix-' + valueItem.stepName}
              >
                <ListIcon as={ArrowRightIcon} color="brand.default" />
                <Link
                  onClick={() => {
                    if (valueItem.isDisabled) {
                      return
                    }
                    return form.goToStep(valueItem.stepName)
                  }}
                >
                  <Box as={'span'} className={'grid-item-headline'}>
                    <TranslationsHelper value={valueItem.fieldName} />
                  </Box>
                  {valueItem.value === 'none' ? ' ' : ': '}
                  <Box as={'span'} className={'grid-item-value'}>
                    <TranslationsHelper value={valueItem.value} />
                    {valueItem.unit ? valueItem.unit : ''}
                  </Box>
                </Link>
              </ListItem>
            )
          })}
        </List>
      </CardBody>
    </Card>
  )
}
