import AppConfig from '@/app/AppConfig'
import { FormFieldNameEnum } from '@/features/fields/FormFieldNameEnum'
import { translate } from '@/features/helper/translationsHelper'
import { addFormGroup } from '@/store/features/formGroupsSlice'
import { useAppDispatch } from '@/store/store'
import { FormizStep, useForm } from '@formiz/core'
import { UseFieldProps } from '@formiz/core/dist/types/field.types'
import { isInRangeNumber } from '@formiz/validations'
import { useEffect } from 'react'
import type { IntlShape } from 'react-intl'
import IconHeatingLoadCalculate from '../../assets/icons/oeg-konfigurator-icon-berechnen.svg'
import IconHeatingLoadIsKnown from '../../assets/icons/oeg-konfigurator-icon-heizlast-bekannt.svg'
import IconHeatingLoadKfwEnergyHouse from '../../assets/icons/oeg-konfigurator-icon-kfw-haus.svg'
import IconHeatingLoadLowEnergyHouse from '../../assets/icons/oeg-konfigurator-icon-niedrigenergiehaus.svg'
import IconHeatingLoadPassiveEnergyHouse from '../../assets/icons/oeg-konfigurator-icon-passivhaus.svg'
import { EditableFiledWithWrapper } from '../fields/EditableFiledWithWrapper'
import { SelectSingleOptionWithRadioCards } from '../fields/SelectSingleOptionWithRadioCards'
import { SliderWithStepMarks } from '../fields/SliderWithStepMarks'
import { BuildingTypesKeysEnum } from './StepChooseBuildingType'
import { ConfiguratorModesKeysEnum } from './StepChooseProjectType'

export enum EnergyEfficiencyEnum {
  niedrigenergiehaus = 'niedrigenergiehaus',
  kfWEffizienzhaus = 'kfWEffizienzhaus',
  passivhaus = 'passivhaus',
}

export enum StepHeatingLoadValuesEnum {
  heatingLoadIsKnown = 'heatingLoadIsKnown',
  heatingLoadCalculation = 'heatingLoadCalculation',
  heatingLoadByConsumption = 'heatingLoadByConsumption',
}

interface StepHeatingLoadPropsInterface extends UseFieldProps {
  intl: IntlShape
}

const StepHeatingLoad = (props: StepHeatingLoadPropsInterface) => {
  const { name, intl } = props
  const label = intl.formatMessage({ id: 'Heizlast' })
  const myForm = useForm({ subscribe: 'fields' })
  const isVisible = myForm.flatValues[FormFieldNameEnum.selectProjectType] === ConfiguratorModesKeysEnum.newBuilding

  const dispatch = useAppDispatch()
  useEffect(() => {
    if (isVisible) {
      const fields = []
      if (myForm.flatValues[FormFieldNameEnum.selectBuildingType] === BuildingTypesKeysEnum.nonResidentialBuilding) {
        fields.push(FormFieldNameEnum.knownBuildingHeatingLoadValue)
      } else {
        fields.push(FormFieldNameEnum.heatingLoadSource)

        if (myForm.flatValues.heatingLoadSource === StepHeatingLoadValuesEnum.heatingLoadIsKnown) {
          fields.push(FormFieldNameEnum.knownBuildingHeatingLoadValue)
        }

        if (myForm.flatValues.heatingLoadSource === StepHeatingLoadValuesEnum.heatingLoadCalculation) {
          fields.push(FormFieldNameEnum.habitableSpace)
          fields.push(FormFieldNameEnum.energyEfficiency)
        }
      }
      dispatch(
        addFormGroup({
          name: name,
          label: label,
          fields: fields,
        })
      )
    }
  }, [dispatch, isVisible, name, label, myForm.flatValues])

  const getMaxValueByBuildingType = (type: string) => {
    if (type === BuildingTypesKeysEnum.multiFamilyHouse) {
      return 900
    }

    if (type === BuildingTypesKeysEnum.singleFamilyHouse) {
      return 300
    }

    return 1000
  }

  return (
    <FormizStep name={name} label={label} isEnabled={isVisible} data-testid={name}>
      <>
        {myForm.flatValues[FormFieldNameEnum.selectBuildingType] === BuildingTypesKeysEnum.nonResidentialBuilding ? (
          <>
            <EditableFiledWithWrapper
              name={FormFieldNameEnum.knownBuildingHeatingLoadValue}
              headline={intl.formatMessage({ id: 'Ergebnis der Heizlastberechnung des Gebäudes' })}
              label={intl.formatMessage({ id: 'Eingabe in kW' })}
              required={intl.formatMessage({ id: 'Pflichtfeld' })}
              type="number"
              minValue={AppConfig.form.heatingLoadMin}
              maxValue={AppConfig.form.heatingLoadMax}
              placeholder={'0'}
              validations={[
                {
                  rule: isInRangeNumber(1, AppConfig.form.heatingLoadMax),
                  message: intl.formatMessage(
                    { id: 'Die Heizlast muss zwischen {s} und {s1} kW liegen' },
                    { s: AppConfig.form.heatingLoadMin, s1: AppConfig.form.heatingLoadMax }
                  ),
                },
              ]}
            />
          </>
        ) : (
          <>
            <SelectSingleOptionWithRadioCards
              required={intl.formatMessage({ id: 'Bitte wählen' })}
              name={FormFieldNameEnum.heatingLoadSource}
              options={[
                {
                  value: StepHeatingLoadValuesEnum.heatingLoadIsKnown,
                  label: intl.formatMessage({ id: 'Ich kenne die Heizlast' }),
                  imageComponent: IconHeatingLoadIsKnown,
                },
                {
                  value: StepHeatingLoadValuesEnum.heatingLoadCalculation,
                  label: intl.formatMessage({ id: 'Heizlast berechnen' }),
                  imageComponent: IconHeatingLoadCalculate,
                },
              ]}
            />

            {myForm.flatValues.heatingLoadSource === StepHeatingLoadValuesEnum.heatingLoadIsKnown ? (
              <EditableFiledWithWrapper
                name={FormFieldNameEnum.knownBuildingHeatingLoadValue}
                headline={intl.formatMessage({ id: 'Gebäude Heizlast' })}
                label={intl.formatMessage({ id: 'Eingabe in kW' })}
                required={intl.formatMessage({ id: 'Heizlast in kW ist erforderlich' })}
                type="number"
                placeholder={'0'}
                validations={[
                  {
                    rule: isInRangeNumber(AppConfig.form.heatingLoadMin, AppConfig.form.heatingLoadMax),
                    message: intl.formatMessage(
                      { id: 'Die Heizlast muss zwischen {s} und {s1} kW liegen' },
                      { s: AppConfig.form.heatingLoadMin, s1: AppConfig.form.heatingLoadMax }
                    ),
                  },
                ]}
              />
            ) : null}

            {myForm.flatValues.heatingLoadSource === StepHeatingLoadValuesEnum.heatingLoadCalculation ? (
              <>
                <SliderWithStepMarks
                  name={FormFieldNameEnum.habitableSpace}
                  minValue={50}
                  defaultValue={myForm.flatValues[FormFieldNameEnum.habitableSpace] ?? 150}
                  unitMarker={'m²'}
                  maxValue={getMaxValueByBuildingType(myForm.flatValues[FormFieldNameEnum.selectBuildingType])}
                  label={intl.formatMessage({ id: 'Welche Wohnfläche hat das Gebäude?' })}
                  required={intl.formatMessage({ id: 'Wohnfläche ist eine Pflichtangabe' })}
                />

                <SelectSingleOptionWithRadioCards
                  label={intl.formatMessage({ id: 'Welcher Energieeffizienz entspricht das Gebäude?' })}
                  required={intl.formatMessage({ id: 'Bitte wählen' })}
                  name={FormFieldNameEnum.energyEfficiency}
                  options={[
                    {
                      value: EnergyEfficiencyEnum.niedrigenergiehaus,
                      label: translate(intl, EnergyEfficiencyEnum.niedrigenergiehaus),
                      imageComponent: IconHeatingLoadLowEnergyHouse,
                    },
                    {
                      value: EnergyEfficiencyEnum.kfWEffizienzhaus,
                      label: translate(intl, EnergyEfficiencyEnum.kfWEffizienzhaus),
                      imageComponent: IconHeatingLoadKfwEnergyHouse,
                    },
                    {
                      value: EnergyEfficiencyEnum.passivhaus,
                      label: translate(intl, EnergyEfficiencyEnum.passivhaus),
                      imageComponent: IconHeatingLoadPassiveEnergyHouse,
                    },
                  ]}
                />
              </>
            ) : null}
          </>
        )}
      </>
    </FormizStep>
  )
}

export default StepHeatingLoad
