import { FormFieldNameEnum } from '@/features/fields/FormFieldNameEnum'
import { UseFormValues } from '@formiz/core/dist/types/form.types'
import { BuildingTypesKeysEnum } from '../steps/StepChooseBuildingType'
import { ConfiguratorModesKeysEnum } from '../steps/StepChooseProjectType'
import { EnergyEfficiencyEnum } from '../steps/StepHeatingLoad'

export const extractHeatingLoadQSpezFromFormValues = (props: { form: UseFormValues }): number | undefined => {
  const { form } = props
  let qspez: number | undefined = undefined

  if (form.flatValues.knownBuildingHeatingLoadValue) {
    // in case of "knownHeatingLoadValue" we don't need qspez for calc
    return qspez
  }
  // Neubau:
  if (form.flatValues[FormFieldNameEnum.selectProjectType] === ConfiguratorModesKeysEnum.newBuilding) {
    // - Einfamilienhaus
    if (form.flatValues[FormFieldNameEnum.selectBuildingType] === BuildingTypesKeysEnum.singleFamilyHouse) {
      // Niedrigenergiehaus Berechnungswert: qspez =  40 [W/m²]
      if (form.flatValues.energyEfficiency === EnergyEfficiencyEnum.niedrigenergiehaus) {
        qspez = 40
      }
      // KfW-Effizienzhaus Berechnungswert: qspez =  25 [W/m²]
      if (form.flatValues.energyEfficiency === EnergyEfficiencyEnum.kfWEffizienzhaus) {
        qspez = 25
      }
      // Passivhaus Berechnungswert: qspez =  15 [W/m²]
      if (form.flatValues.energyEfficiency === EnergyEfficiencyEnum.passivhaus) {
        qspez = 15
      }
    }

    // - Mehrfamilienhaus
    if (form.flatValues[FormFieldNameEnum.selectBuildingType] === BuildingTypesKeysEnum.multiFamilyHouse) {
      // Niedrigenergiehaus Berechnungswert: qspez =  35 [W/m²]
      if (form.flatValues.energyEfficiency === EnergyEfficiencyEnum.niedrigenergiehaus) {
        qspez = 35
      }
      // KfW-Effizienzhaus Berechnungswert: qspez =  20 [W/m²]
      if (form.flatValues.energyEfficiency === EnergyEfficiencyEnum.kfWEffizienzhaus) {
        qspez = 20
      }
      // Passivhaus Berechnungswert: qspez =  10 [W/m²]
      if (form.flatValues.energyEfficiency === EnergyEfficiencyEnum.passivhaus) {
        qspez = 10
      }
    }
    // - Nichtwohngebäude
    if (form.flatValues[FormFieldNameEnum.selectBuildingType] === BuildingTypesKeysEnum.nonResidentialBuilding) {
      // @todo: Eingabe des Ergebnis der Heizlastberechnung des Gebäudes Eingabe: qH = 1-50kW
      // -> kein qSpez
      qspez = 0
    }

    return qspez
  }

  // Modernisierung:
  if (
    !form.flatValues[FormFieldNameEnum.buildingConstructionYear] &&
    !form.flatValues[FormFieldNameEnum.knownGasOrOilOrPowerConsumeValue]
  ) {
    // qSpez is  not always needed at this point
    return -1
  }

  const buildingConstructionYear = parseInt(form.flatValues[FormFieldNameEnum.buildingConstructionYear])

  // - Einfamilienhaus
  if (form.flatValues[FormFieldNameEnum.selectBuildingType] === BuildingTypesKeysEnum.singleFamilyHouse) {
    switch (true) {
      // Baujahr vor 1958 (90-180 W/m²) Berechnungswert: qspez =  140 [W/m²]
      case buildingConstructionYear < 1959:
        qspez = 140
        break
      // Baujahr 1959 - 1968 ( W/m²) Berechnungswert: qspez =  130 [W/m²]
      case buildingConstructionYear >= 1959 && buildingConstructionYear < 1969:
        qspez = 130
        break
      // Baujahr 1969 - 1979 ( W/m²) Berechnungswert: qspez = 120  [W/m²]
      case buildingConstructionYear >= 1969 && buildingConstructionYear < 1979:
        qspez = 120
        break
      // Baujahr 1974 - 1977 ( W/m²) Berechnungswert: qspez =  115 [W/m²]
      case buildingConstructionYear >= 1974 && buildingConstructionYear < 1977:
        qspez = 115
        break
      // Baujahr 1978 - 1983 ( W/m²) Berechnungswert: qspez =  95  [W/m²]
      case buildingConstructionYear >= 1978 && buildingConstructionYear < 1983:
        qspez = 95
        break
      // Baujahr 1984 - 1994 ( W/m²) Berechnungswert: qspez = 75 [W/m²]
      case buildingConstructionYear >= 1984 && buildingConstructionYear < 1994:
        qspez = 75
        break
      // Baujahr ab 1995 ( W/m²) Berechnungswert: qspez = 60  [W/m²]
      case buildingConstructionYear >= 1995:
        qspez = 60
        break
    }
  }

  // Mehrfamilienhaus
  if (form.flatValues[FormFieldNameEnum.selectBuildingType] === BuildingTypesKeysEnum.multiFamilyHouse) {
    const numberOfResidentialUnits = parseInt(form.flatValues[FormFieldNameEnum.numberOfResidentialUnits])

    switch (true) {
      // Baujahr vor 1980 (90-180 W/m²) Berechnungswert: qspez = 120 [W/m²] < 8 WE qspez = 110 [W/m²] > 8 WE
      case buildingConstructionYear < 1980 && numberOfResidentialUnits < 8:
        qspez = 120
        break
      case buildingConstructionYear < 1980 && numberOfResidentialUnits > 8:
        qspez = 110
        break
      // Baujahr 1980 - 1995 (70 - 90 W/m²) Berechnungswert: qspez =  75 [W/m²]  < 8 WE qspez =  70 [W/m²] > 8 WE
      case buildingConstructionYear >= 1980 && buildingConstructionYear < 1995 && numberOfResidentialUnits <= 8:
        qspez = 75
        break
      case buildingConstructionYear >= 1980 && buildingConstructionYear < 1995 && numberOfResidentialUnits > 8:
        qspez = 70
        break
      // Baujahr 1995 - 2000 (50 - 60 W/m²) Berechnungswert: qspez =  55[W/m²]  < 8 WE qspez = 50 [W/m²] > 8 WE
      case buildingConstructionYear >= 1995 && buildingConstructionYear < 2000 && numberOfResidentialUnits <= 8:
        qspez = 55
        break
      case buildingConstructionYear >= 1995 && buildingConstructionYear < 2000 && numberOfResidentialUnits > 8:
        qspez = 50
        break
      // Baujahr 2000 - 2010 (40 - 60 W/m²) Berechnungswert: qspez =  45 [W/m²]  < 8 WE qspez = 40 [W/m²] > 8 WE
      case buildingConstructionYear >= 2000 && buildingConstructionYear < 2010 && numberOfResidentialUnits <= 8:
        qspez = 45
        break
      case buildingConstructionYear >= 2000 && buildingConstructionYear < 2010 && numberOfResidentialUnits > 8:
        qspez = 40
        break
      // Baujahr 2010 - 2022 (15 - 35 W/m²) Berechnungswert: qspez =  25[W/m²]  < 8 WE qspez =  20 [W/m²] > 8 WE
      case buildingConstructionYear >= 2010 && buildingConstructionYear <= 2022 && numberOfResidentialUnits <= 8:
        qspez = 25
        break
      case buildingConstructionYear >= 2010 && buildingConstructionYear <= 2022 && numberOfResidentialUnits > 8:
        qspez = 20
        break
    }
  }

  // - Nichtwohngebäude
  if (form.flatValues[FormFieldNameEnum.selectBuildingType] === BuildingTypesKeysEnum.nonResidentialBuilding) {
    // Eingabe des Ergebnis der Heizlastberechnung des Gebäudes Eingabe: qH = 1-50kW
    // -> kein qSpez
    qspez = 0
  }

  return qspez
}
