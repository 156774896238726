interface HeatingLoad {
  valueOfNormOutsideTemp: number
  buildingHeatingLoad: number
}

export interface HeatingLoadResult {
  m2: number
  b2: number
  Y2: number
}

interface CalcM2Params {
  x1: number
  y1: number
  x2: number
  y2: number
}

interface CalcB2Params {
  m2: number
  x1: number
  y1: number
}

export function calculateHeatingLoad({ valueOfNormOutsideTemp, buildingHeatingLoad }: HeatingLoad): HeatingLoadResult {
  const calcM2 = ({ x1, y1, x2, y2 }: CalcM2Params) => {
    return (y1 - y2) / (x1 - x2)
  }

  const calcB2 = ({ m2, x1, y1 }: CalcB2Params) => {
    return y1 - m2 * x1
  }

  const m2 = calcM2({
    x1: valueOfNormOutsideTemp,
    y1: buildingHeatingLoad,
    x2: 15 /* fixed value */,
    y2: 0 /* fixed value */,
  })

  const b2 = calcB2({
    m2: m2,
    x1: valueOfNormOutsideTemp,
    y1: buildingHeatingLoad,
  })

  const Y2 = m2 * 15 /* fixed value */ * b2

  return {
    m2: parseFloat(m2.toFixed(4)),
    b2: parseFloat(b2.toFixed(4)),
    Y2: parseFloat(Y2.toFixed(4)),
  }
}
