import { isDebugEnabled } from '@/store/features/userDataSlice'
import { useAppSelector } from '@/store/store'
import { Box, Card, CardBody, CardHeader, Flex } from '@chakra-ui/react'
import React from 'react'
import { Debug } from './footer/Debug'

type Props = {
  children?: React.ReactNode
}

export const PageLayout = ({ children }: Props) => {
  const debugEnabled = useAppSelector(isDebugEnabled)

  return (
    <>
      <Flex
        flex="3"
        minH="100vh"
        minW="100%"
        alignItems={'center'}
        flexWrap="nowrap"
        flexDirection={{ base: 'column', lg: 'column' }}
      >
        <Box flex="2" position="relative">
          <Card>
            <CardHeader className={'stepsHeader'} />
            <CardBody className={'stepsContent'}>{children}</CardBody>
          </Card>
        </Box>

        {debugEnabled ? (
          <Box flex="1" position="relative">
            <Debug />
          </Box>
        ) : null}
      </Flex>
    </>
  )
}
