import { calculateFactorSperr } from '../../factor/calculateFactorSperr'

interface HeatingLoadBuildingNonResidentialProps {
  // gebäudeheizlast
  qH_BuildingHeatingLoad: number
  // Eingabe des tägl. Energiebedarf für Warmwasser: qWWTag = 0 - 200 [kWh/Tag]
  qWWTag?: number
  qWWTagHours?: number
  // dauert der Sperrzeiten see https://www.westnetz.de/de/faq/waermepumpen.html
  hoursDisabled: number
}

export interface HeatingLoadNonResidentialBuildingResult {
  kW: number
  // fATKorr: number
}

export const calculateHeatingLoadNonResidential = ({
  qH_BuildingHeatingLoad: qH,
  qWWTag,
  qWWTagHours,
  hoursDisabled,
}: HeatingLoadBuildingNonResidentialProps): HeatingLoadNonResidentialBuildingResult => {
  console.log('calculateHeatingLoadNonResidential', {
    qH: qH,
    qWWTag: qWWTag,
    qWWTagHours: qWWTagHours,
    hoursDisabled: hoursDisabled,
  })
  let qWP = 0

  const fSperr = calculateFactorSperr({ hoursDisabled })

  // Ich kenne die Gebäudeheizlast
  // Eingabe: qH = 1-50[kW]
  if (!qH) {
    throw Error('needs buildingHeatingLoad (qH)')
  }

  qWP = (qH * 1000 * fSperr) / 1000

  return {
    kW: parseFloat('' + qWP.toFixed(3)),
  }
}
