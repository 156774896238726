import { extendTheme } from '@chakra-ui/react'

const activeLabelStyles = {
  transform: 'scale(0.85) translateX(-75%) translateY(-50px)',
}

export const configuratorTheme = extendTheme({
  colors: {
    brand: {
      50: '#edfdf4',
      100: '#A7D5FF',
      200: '#1C62A8',
      300: '#1C62A8',
      400: '#000',
      500: '#1C62A8',
      600: '#1C62A8',
      700: '#1C62A8',
      800: '#1C62A8',
      900: '#1C62A8',
      1000: '#001506',
      default: '#1c62a8',
      backgroundColor: '#1c62a8',
      overlay: '#1c62a8',
      subHeadline: '#1c62a8',
      headline: '#1c62a8',
      borderColor: '#4a4a4a',
      checkIconColor: '#1c62a8',
      blue: '#1c62a8',
      gray: '#4a4a4a',
      lgGray: '#bbb',
    },
  },
  components: {
    Form: {
      variants: {
        floating: {
          container: {
            _focusWithin: {
              label: {
                ...activeLabelStyles,
              },
            },
            'input:not(:placeholder-shown) + label, .chakra-select__wrapper + label, textarea:not(:placeholder-shown) ~ label':
              {
                ...activeLabelStyles,
              },
            label: {
              top: '38%',
              left: '50%',
              msTransform: 'translate(-50%, -50%)',
              transform: 'translate(-50%, -50%)',
              fontSize: '1.2em',
              fontWeight: 'bold',
              color: '#1c62a8',
              zIndex: 2,
              position: 'absolute',
              backgroundColor: 'white',
              pointerEvents: 'none',
              mx: 3,
              px: 1,
              my: 2,
              transformOrigin: 'top',
            },
          },
        },
      },
    },
  },
})
