import { FilterFlagsInterface } from '@/features/heatPumpSet/HeatPumpSets'
import PageVisibility from '@/features/statistic/PageVisibility'
import PageWillLeave from '@/features/statistic/PageWillLeave'
import { selectFilteredHeatPumpPackages } from '@/selector/heatPumpPackages'
import { HeatPumpPackageInterface } from '@/store/features/heatPumpPackagesApiSlice'
import { getFilterFlags } from '@/store/features/heatPumpSetFilterSlice'
import {
  getCurrencyCode,
  getCustomerId,
  getHeatingLoad,
  getLanguageCode,
  getNormTemperatur,
  getSessionId,
} from '@/store/features/userDataSlice'
import { useAppSelector } from '@/store/store'
import { useForm } from '@formiz/core'
import { FormValues, UseFormValues } from '@formiz/core/src/types/form.types'
import * as Sentry from '@sentry/browser'
import debounce from 'lodash.debounce'
import { useEffect, useMemo } from 'react'

export interface StatisticDataInterface extends ReasonInterface {
  sessionId: string
  customerId: null | number
  languageCode: string //'NL'
  currencyCode: 'EUR' | 'CHF'
  formValues: Array<FormValues> | { [key: string]: string[] }[]
}

const sendStatisticData = (statisticData: StatisticDataInterface) => {
  try {
    if (typeof navigator.sendBeacon !== 'function') {
      return
    }
    if (import.meta.env.VITE_API_STATISTIC_USAGE_DISABLED === 'true') {
      return
    }
    navigator.sendBeacon(
      import.meta.env.VITE_API_ENDPOINT_STATISTIC_USAGE,
      JSON.stringify(statisticData, (key, value) => (typeof value === 'undefined' ? null : value))
    )
  } catch (e) {
    Sentry.captureException(e)
  }
}

export const formatTrackingData = (
  sessionId: string,
  languageCode: string,
  currencyCode: 'EUR' | 'CHF',
  customerId: number | null,
  reason: 'SUMMARY' | 'HIDDEN' | 'NO-PUMPS' | 'REQUEST' | 'LEAVE' | 'NO-PACKAGES' | 'events',
  myForm: UseFormValues,
  filterFlags: FilterFlagsInterface,
  packages: HeatPumpPackageInterface[] = [],
  normTemperatur: number,
  heatingLoad: number,
  contactEmail?: string
): StatisticDataInterface => {
  const productIds: number[] = packages.map((item: HeatPumpPackageInterface) => {
    return item.productId
  })

  return {
    sessionId: sessionId,
    customerId: customerId ?? null,
    languageCode: languageCode,
    currencyCode: currencyCode,
    reason,
    formValues: [
      myForm.flatValues,
      { env: import.meta.env.VITE_SENTRY_ENV },
      {
        packageProductIds: productIds,
        normTemperatur,
        heatingLoad,
        contactEmail: contactEmail,
      },
      filterFlags,
    ],
  }
}

interface ReasonInterface {
  reason: 'SUMMARY' | 'HIDDEN' | 'NO-PUMPS' | 'REQUEST' | 'LEAVE' | 'NO-PACKAGES' | 'events'
}

interface PropertiesInterface extends ReasonInterface {
  mountTracking: boolean
}

const StatisticUsageTracker = (props: PropertiesInterface) => {
  const { reason, mountTracking } = props
  const myForm = useForm()
  const packages = useAppSelector(selectFilteredHeatPumpPackages)

  const statisticData: StatisticDataInterface = formatTrackingData(
    useAppSelector(getSessionId),
    useAppSelector(getLanguageCode),
    useAppSelector(getCurrencyCode),
    useAppSelector(getCustomerId),
    reason,
    myForm,
    useAppSelector(getFilterFlags),
    packages,
    useAppSelector(getNormTemperatur),
    useAppSelector(getHeatingLoad)
  )

  const sendHandler = () => {
    sendStatisticData(statisticData)
  }

  const debouncedSummaryHandler = useMemo(
    () =>
      debounce(() => {
        sendHandler()
        //console.log('sent')
      }, 1000),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  useEffect(() => {
    if (mountTracking) {
      //console.log('bounce')
      debouncedSummaryHandler()
    }
  }, [mountTracking, debouncedSummaryHandler])

  if (!mountTracking) {
    const pageVisible = PageVisibility()
    if (!pageVisible) {
      statisticData.reason = 'HIDDEN'
      sendStatisticData(statisticData)
    }

    const pageBeforeLeave = PageWillLeave()
    if (pageBeforeLeave) {
      statisticData.reason = 'LEAVE'
      sendStatisticData(statisticData)
    }
  }

  return <></>
}

export default StatisticUsageTracker
