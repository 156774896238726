import { HeatingLoadResult } from './calculateHeatingLoad'

interface PreHeatingLoad {
  heatingCapacityInKwAtSub7Degrees: number
  heatingCapacityInKwAtSub2Degrees: number
  heatingLoad: HeatingLoadResult
}

export interface PreHeatingLoadResult {
  x: number
  m1: number
  b1: number
}

interface CalcB1Params {
  y1: number
  m1: number
  x1: number
}

interface CalcM1Params {
  x1: number
  y1: number
  x2: number
  y2: number
}

export function calculatePreHeatingLoad({
  heatingCapacityInKwAtSub7Degrees,
  heatingCapacityInKwAtSub2Degrees,
  heatingLoad,
}: PreHeatingLoad): PreHeatingLoadResult {
  const preHeatingTemp_x1 = -7 // matrix name der spalte/zeile -7°C
  const preHeatingTemp_x2 = -2 // matrix name der spalte/zeile -2°C

  const calcM1 = ({ x1, y1, x2, y2 }: CalcM1Params): number => {
    return (y1 - y2) / (x1 - x2)
  }

  const calcB1 = ({ y1, m1, x1 }: CalcB1Params): number => {
    return y1 - m1 * x1
  }

  const m1 = calcM1({
    x1: preHeatingTemp_x1,
    y1: heatingCapacityInKwAtSub7Degrees,
    x2: preHeatingTemp_x2,
    y2: heatingCapacityInKwAtSub2Degrees,
  })

  const b1 = calcB1({ y1: heatingCapacityInKwAtSub7Degrees, m1: preHeatingTemp_x1, x1: m1 })

  const x = (b1 - heatingLoad.b2) / (heatingLoad.m2 - m1)

  return {
    m1: parseFloat(m1.toFixed(3)),
    b1: parseFloat(b1.toFixed(4)),
    x: parseFloat(x.toFixed(4)),
  }
}
