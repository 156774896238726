import { FormFieldNameEnum } from '@/features/fields/FormFieldNameEnum'
import TranslationsHelper, { translate } from '@/features/helper/translationsHelper'
import { UserStatsInterface } from '@/store/features/userStatsApiSlice'
import {
  Box,
  Button,
  Heading,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Table,
  Tbody,
  Td,
  Tr,
  useDisclosure,
} from '@chakra-ui/react'
import type { IntlShape } from 'react-intl'
import { FormattedMessage, injectIntl } from 'react-intl'

const StatsDetailsModal = ({ userStats, intl }: { userStats: UserStatsInterface; intl: IntlShape }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  let formValuesRaw = {}
  let env = ''
  let result: { packageProductIds: number[]; normTemperatur: number; heatingLoad: number } = {
    packageProductIds: [],
    normTemperatur: 0,
    heatingLoad: 0,
  }
  const formValues: { name: string; value: number | string }[] = []

  if (isOpen) {
    for (const dataPoint of userStats.formValues ?? []) {
      if (typeof dataPoint['env'] === 'string') {
        env = dataPoint['env']
      } else if (typeof dataPoint['selectProjectType'] === 'string') {
        formValuesRaw = dataPoint
      } else if (
        typeof dataPoint['heatingLoad'] === 'number' &&
        typeof dataPoint['packageProductIds'] !== 'undefined'
      ) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        result = dataPoint
      }
    }

    for (const [fieldName, value] of Object.entries(formValuesRaw)) {
      let currentValue = value

      if (fieldName === FormFieldNameEnum.normTempFinder) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        currentValue = value.value
      }
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      formValues.push({ name: fieldName, value: currentValue })
    }
  }

  return (
    <>
      <Button colorScheme={'brand'} onClick={onOpen}>
        <FormattedMessage id={'Details'} />
      </Button>

      <Modal isOpen={isOpen} onClose={onClose} size={'lg'}>
        <ModalOverlay />

        <ModalContent minWidth={'90%'}>
          <ModalHeader>
            Übersicht der Konfiguration {userStats.sessionId} (#{userStats.id} | {env}) <br />
          </ModalHeader>
          <ModalCloseButton />

          <ModalBody>
            <SimpleGrid columns={2} spacingX="30px" spacingY="20px">
              <Box>
                <Heading mb={2}>Eingaben:</Heading>
                <Table mt={2} variant="striped" size="sm">
                  <Tbody>
                    {formValues.map((value, index) => {
                      let val: string | number
                      if (Array.isArray(value.value)) {
                        val = ''
                        value.value.forEach((value1, index) => {
                          if (index > 0) {
                            val += ', '
                          }
                          val += translate(intl, value1)
                        })
                      } else {
                        val = value.value
                      }

                      return (
                        <Tr key={index}>
                          <Td>
                            <TranslationsHelper value={value.name} />
                          </Td>
                          <Td isNumeric>
                            <TranslationsHelper value={val} />
                          </Td>
                        </Tr>
                      )
                    })}
                  </Tbody>
                </Table>
              </Box>
              <Box>
                <Heading mb={2}>Ergebnis:</Heading>
                <Table mt={2} variant="striped" size="sm">
                  <Tbody>
                    <Tr>
                      <Td>Heizlast</Td>
                      <Td isNumeric>{result.heatingLoad}</Td>
                    </Tr>
                    <Tr>
                      <Td>Normaußentemeratur</Td>
                      <Td isNumeric>{result.normTemperatur}</Td>
                    </Tr>
                    <Tr>
                      <Td>Gefundene Pakete:</Td>
                      <Td isNumeric>{result.packageProductIds.length}</Td>
                    </Tr>
                    {/*{result.packageProductIds.map((productId, index) => {*/}
                    {/*  return (*/}
                    {/*    <Tr key={index}>*/}
                    {/*      <Td>Paket:</Td>*/}
                    {/*      <Td isNumeric>{productId}</Td>*/}
                    {/*    </Tr>*/}
                    {/*  )*/}
                    {/*})}*/}
                  </Tbody>
                </Table>
              </Box>
            </SimpleGrid>
          </ModalBody>

          <ModalFooter>
            <Button
              as={Link}
              colorScheme="brand"
              mr={3}
              target={'_blank'}
              href={'/prefill?prefillId=' + userStats.id + '&summaryInfo=1'}
            >
              <FormattedMessage id={'Konfiguration anwenden'} />
            </Button>
            <Button colorScheme="red" onClick={onClose}>
              <FormattedMessage id={'Schließen'} />
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default injectIntl(StatsDetailsModal)
