import { RootState } from '@/store/store'
import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

interface FormGroupInterface {
  name: string
  label: string
  fields: string[]
}

export interface GroupsInterface {
  [key: string]: FormGroupInterface[]
}

interface FormGroupsInterface {
  groups: GroupsInterface
}

const initialState: FormGroupsInterface = {
  groups: {},
}

const formGroupsSlice = createSlice({
  name: 'formGroupsSlice',
  initialState: initialState,
  reducers: {
    addFormGroup: (state, param: PayloadAction<FormGroupInterface>) => {
      // @todo: fix eslint crap
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      state.groups[param.payload.name] = param.payload
    },
    removeFormGroups: (state, param: PayloadAction<{ name: string }>) => {
      // @todo: fix eslint crap
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const newGroups: GroupsInterface = {}
      Object.keys(state.groups).forEach((value) => {
        const group = state.groups[value]
        if (value !== param.payload.name) {
          newGroups[value] = group
        }
      })

      state.groups = newGroups
    },
  },
})

export const getFormGroups = (state: RootState): GroupsInterface => state.formGroupsSlice.groups

const { actions, reducer } = formGroupsSlice
export const { addFormGroup, removeFormGroups } = actions
export default reducer
