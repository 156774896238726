/**
 * Sperrzeiten des Stromversorgers. Bewährt hat sich die nachfolgenden Praxiswerte:
 * 2h= +5%, 4h= +10%, 6h= +15% Aufschlag auf die Heizlast
 * Berechnungswert: +15%
 *  ( fSperr. = 1,15)
 * X = C * B / A
 *
 * @return number
 */
export const calculateFactorSperr = ({ hoursDisabled }: { hoursDisabled: number }): number => {
  // Sperrzeiten des Stromversorgers. Bewährt hat sich die nachfolgenden Praxiswerte:
  // 2h= +5%, 4h= +10%, 6h= +15% Aufschlag auf die Heizlast
  // Berechnungswert: +15%
  //  ( fSperr. = 1,15)
  // X = C * B / A
  const fSperrPercent = (hoursDisabled * 10) / 4
  const fSperr = 1 + fSperrPercent / 100

  return fSperr
}
