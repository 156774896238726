import { HeatingLoadBuildingResult } from '../../../calculateBuildingHeatLoad'
import { calculateFactorSperr } from '../../../factor/calculateFactorSperr'
import { getEnergySavingFactors } from './../../../factor/getEnergySavingFactors'

interface PropertiesInterface {
  normTempOutside: number
  energySavingOptions: string[]
  hoursDisabled: number
  qH: number
}

export const calculateHeatingLoadKnownLoadModernisationMultiFamily = ({
  normTempOutside,
  energySavingOptions,
  hoursDisabled,
  qH,
}: PropertiesInterface): HeatingLoadBuildingResult => {
  console.log('calculateHeatingLoadPumpModernisationSingleFamily', {
    normTempOutside: normTempOutside,
    energySavingOptions: energySavingOptions,
    hoursDisabled: hoursDisabled,
    qH: qH,
  })
  // dämmung
  const energySavingFactors = getEnergySavingFactors({ energySavingOptions, isMultiFamily: true })
  const fWindows = energySavingFactors.fWindows
  const fRoof = energySavingFactors.fRoof
  const fWalls = energySavingFactors.fWalls

  const fSperr = calculateFactorSperr({ hoursDisabled })

  let resultQwp = 0

  resultQwp = qH * fWindows * fRoof * fWalls * fSperr

  return {
    kW: parseFloat(resultQwp.toFixed(3)),
  }
}
