export const calculateWarmWaterHeatingLoadMultiFamilyHouse = ({
  warmWaterNumberOfPersonsPerResidence,
  numberOfResidentialUnits,
  isWithSolar,
}: {
  warmWaterNumberOfPersonsPerResidence: number
  numberOfResidentialUnits: number
  isWithSolar: boolean
}): { kW: number } => {
  console.log('calculateWarmWaterHeatingLoadMultiFamilyHouse', {
    warmWaterNumberOfPersonsPerResidence: warmWaterNumberOfPersonsPerResidence,
    numberOfResidentialUnits: numberOfResidentialUnits,
    isWithSolar: isWithSolar,
  })
  // qWTag = 200W pP
  const qWTag = 200
  // Pers.ges[Zahl] = Pers.WE[Zahl] x WE[Zahl]
  const persons = warmWaterNumberOfPersonsPerResidence * numberOfResidentialUnits
  // -> QWW[W] = Pers.ges[Zahl] x qWTag[W]
  let qWW = persons * qWTag

  if (isWithSolar) {
    qWW *= 0.5
  }

  return {
    kW: qWW / 1000,
  }
}
