import { heatPumpPackageBomApiSlice } from '@/store/features/heatPumpPackageBomApiSlice'
import { heatPumpPackagesDetailsApiSlice } from '@/store/features/heatPumpPackagesDetailsApiSlice'
import { userStatsApiSlice } from '@/store/features/userStatsApiSlice'
import { configureStore } from '@reduxjs/toolkit'
import { CurriedGetDefaultMiddleware } from '@reduxjs/toolkit/src/getDefaultMiddleware'
import type { TypedUseSelectorHook } from 'react-redux'
import { useDispatch, useSelector } from 'react-redux'
import formGroupsSliceReducer from './features/formGroupsSlice'
import { heatPumpsApiSlice } from './features/heatPumpApiSlice'
import { heatPumpPackagesApiSlice } from './features/heatPumpPackagesApiSlice'
import heatPumpPackagesSliceReducer from './features/heatPumpPackagesSlice'
import heatPumpSetFilterSliceReducer from './features/heatPumpSetFilterSlice'
import { standardTemperatureApiSlice } from './features/standardTemperatureApiSlice'
import { translationApiSlice } from './features/translationApiSlice'
import userDataSliceReducer from './features/userDataSlice'
import { userSessionApiSlice } from './features/userSessionApiSlice'

export const store = configureStore({
  reducer: {
    [standardTemperatureApiSlice.reducerPath]: standardTemperatureApiSlice.reducer,
    [heatPumpsApiSlice.reducerPath]: heatPumpsApiSlice.reducer,
    [heatPumpPackagesApiSlice.reducerPath]: heatPumpPackagesApiSlice.reducer,
    [translationApiSlice.reducerPath]: translationApiSlice.reducer,
    [userSessionApiSlice.reducerPath]: userSessionApiSlice.reducer,
    [heatPumpPackagesDetailsApiSlice.reducerPath]: heatPumpPackagesDetailsApiSlice.reducer,
    [heatPumpPackageBomApiSlice.reducerPath]: heatPumpPackageBomApiSlice.reducer,
    [userStatsApiSlice.reducerPath]: userStatsApiSlice.reducer,
    heatPumpSetFilterSlice: heatPumpSetFilterSliceReducer,
    heatPumpPackagesSlice: heatPumpPackagesSliceReducer,
    userDataSlice: userDataSliceReducer,
    formGroupsSlice: formGroupsSliceReducer,
  },
  middleware: (getDefaultMiddleware: CurriedGetDefaultMiddleware) => {
    // used for things like cache lifetime
    return getDefaultMiddleware()
      .concat(standardTemperatureApiSlice.middleware)
      .concat(heatPumpsApiSlice.middleware)
      .concat(heatPumpPackagesApiSlice.middleware)
      .concat(translationApiSlice.middleware)
      .concat(userSessionApiSlice.middleware)
      .concat(heatPumpPackagesDetailsApiSlice.middleware)
      .concat(heatPumpPackageBomApiSlice.middleware)
      .concat(userStatsApiSlice.middleware)
  },
  devTools: true,
})

export type RootState = ReturnType<typeof store.getState>
export const useAppDispatch: () => typeof store.dispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
