import { Box, Center } from '@chakra-ui/react'
import type { ChartData, ChartOptions } from 'chart.js'
import {
  CategoryScale,
  Chart as ChartJS,
  Filler,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js'
import annotationPlugin from 'chartjs-plugin-annotation'
import { Line } from 'react-chartjs-2'
import { HeatPumpMatrixValueInterface } from '../../store/features/heatPumpApiSlice'
import { dualPointRanges } from './dualPointRanges'

export interface HeatPumpHeatingCapacityChartDataMatrixInterface {
  label: string
  heatPumps: HeatPumpMatrixValueInterface[]
  borderColor: string
  backgroundColor: string
  useMinValueLineStyle: boolean
}
export const HeatPumpHeatingCapacityChart = (props: {
  heatingLoad: number
  chartTitle: string
  dynamicMaxY: boolean
  normOutsideTemp: number
  heatPumpsTempMatrixSets: HeatPumpHeatingCapacityChartDataMatrixInterface[]
}) => {
  const dualPointRange = dualPointRanges({ normOutsideTempValue: props.normOutsideTemp })
  const bivalenzpunkt1x: number = dualPointRange.x1
  const bivalenzpunkt2x: number = dualPointRange.x2

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler,
    annotationPlugin
  )

  const convertX = (xvalue: number): number => {
    return Math.abs(-25 - xvalue)
  }

  const buildOptions = (yMaxScaleValue: number): ChartOptions => {
    return {
      elements: {
        line: {
          spanGaps: true,
          stepped: false,
        },
      },
      responsive: true,
      maintainAspectRatio: true,
      interaction: {
        mode: 'index' as const,
        intersect: false,
      },
      plugins: {
        title: {
          display: props.chartTitle.length > 0,
          text: props.chartTitle,
        },
        annotation: {
          annotations: {
            bivalenzpunkt1: {
              type: 'line',
              xMin: convertX(bivalenzpunkt1x),
              xMax: convertX(bivalenzpunkt1x),
              borderColor: 'rgb(0,0,0)',
              borderWidth: 2,
            },
            bivalenzpunkt2: {
              type: 'line',
              xMin: convertX(bivalenzpunkt2x),
              xMax: convertX(bivalenzpunkt2x),
              borderColor: 'rgb(0,0,0)',
              borderWidth: 2,
            },
            line3: {
              type: 'line',
              xMin: convertX(15), // = 15 fixed temp point
              xMax: convertX(props.normOutsideTemp),
              yMin: 0,
              yMax: props.heatingLoad,
              borderColor: 'rgb(97,229,56)',
              borderWidth: 2,
            },
          },
        },
      },
      scales: {
        x: {
          display: true,
          title: {
            display: true,
            text: 'Ambient Temperature in °C',
          },
          ticks: {
            display: true,
            autoSkip: true,
            stepSize: 1,
          },
        },
        y: {
          axis: 'y',
          type: 'linear' as const,
          display: true,
          position: 'left' as const,
          title: {
            display: true,
            text: 'Heating Capacity in kW',
          },
          beginAtZero: true,
          min: 0,
          max: yMaxScaleValue,
          suggestedMin: 0,
          suggestedMax: yMaxScaleValue,
          ticks: {
            count: yMaxScaleValue,
            display: true,
            autoSkip: true,
            stepSize: 1,
          },
        },
      },
    }
  }
  const getLabels = () => {
    let start = -25
    const end = 27
    const scale: number[] = [start]
    while (start < end) {
      start += 1
      scale.push(start)
    }
    return scale
  }

  const labels = getLabels()

  const findInArray = (value: number, heatPumps: HeatPumpMatrixValueInterface[]) => {
    const index = heatPumps.findIndex(({ ambientTemperature }) => ambientTemperature == value)

    return heatPumps[index]
  }

  const buildDataSets = (heatPumpsData: HeatPumpHeatingCapacityChartDataMatrixInterface[]): ChartData => {
    const data: ChartData = {
      labels: labels,
      datasets: [],
    }

    heatPumpsData.forEach((heatPumps) => {
      data.datasets.push({
        label: heatPumps.label,
        data: labels.map((label) => {
          return findInArray(label, heatPumps.heatPumps)?.heatingCapacityValue ?? null
        }),
        borderColor: heatPumps.borderColor,
        backgroundColor: heatPumps.backgroundColor,
        yAxisID: 'y',
        pointStyle: 'cross',
        normalized: true,
        borderDash: heatPumps.useMinValueLineStyle ? [10, 5] : [],
      })
    })

    return data
  }

  const lineData = buildDataSets(props.heatPumpsTempMatrixSets)
  const extractMaxYScaleValue = (dynamicMaxY: boolean, lineData: ChartData) => {
    let yMaxScaleValue = 27
    if (dynamicMaxY) {
      const values: number[] = []

      lineData.datasets.map((dataSet) => {
        values.push(parseInt(dataSet.data[dataSet.data.length - 1] + '') ?? 0)
        return
      })

      const yMaxScaleValueOfData = Math.max(...values)
      if (yMaxScaleValueOfData + 2 < yMaxScaleValue) {
        yMaxScaleValue = yMaxScaleValueOfData + 2
      }
    }

    return yMaxScaleValue
  }

  const options = buildOptions(extractMaxYScaleValue(props.dynamicMaxY, lineData))

  return (
    <>
      <Box height={'auto'} width="full">
        <Center>
          {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
          {/* @ts-ignore*/}
          <Line options={options} data={lineData} />
        </Center>
      </Box>
    </>
  )
}
