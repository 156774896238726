import { dualPointRanges } from '@/features/heatPump/dualPointRanges'
import { FilterFlagsInterface } from '@/features/heatPumpSet/HeatPumpSets'
import { calculateHeatPumpPowerConsume } from '@/features/math/calculateHeatPumpPowerConsume'
import { HeatPumpInterface } from '@/store/features/heatPumpApiSlice'
import { getHeatingLoad, getNormTemperatur } from '@/store/features/userDataSlice'
import { createSelector } from 'reselect'
import { HeatPumpPackageInterface } from '../store/features/heatPumpPackagesApiSlice.js'
import { getFilterFlags } from '../store/features/heatPumpSetFilterSlice.js'
import { RootState } from '../store/store.js'

interface preHeatingTemperaturMatrix {
  35: boolean
  45: boolean
  55: boolean
  70: boolean
}

// use data from store
const getHeatPumpPackagesFromState = (state: RootState): HeatPumpPackageInterface[] =>
  state.heatPumpPackagesSlice.heatPumpPackages
const getRelevantHeatPumpsFromState = (state: RootState): HeatPumpInterface[] =>
  state.heatPumpPackagesSlice.relevantPumps

export const selectRelevantHeatPumps = createSelector(getRelevantHeatPumpsFromState, (pumps: HeatPumpInterface[]) => {
  return pumps
})

export const checkPumpPackCanDoTemp = (
  heatPumpPackage: HeatPumpPackageInterface,
  targetTemp: number,
  relevantHeatPumps: HeatPumpInterface[],
  userInputHeatingLoad: number,
  userInputNormTemp: number
) => {
  let filterFoundTemp = false
  const dualPointRange = dualPointRanges({ normOutsideTempValue: userInputNormTemp })

  for (const relevantPump of relevantHeatPumps) {
    if (heatPumpPackage.heatPumpExternalId !== relevantPump.oegNumber) {
      continue
    }

    for (const value of relevantPump.valuesRelevantForPower) {
      if (value.maxPreheatValue !== targetTemp) {
        // skip wrong target temp
        continue
      }

      // check if temp is possible for pack
      const calculatedHeatPumpPowerConsume = calculateHeatPumpPowerConsume({
        heatingCapacityInKwAtSub2Degrees: value.heatingCapacityAtSub2Degrees,
        heatingCapacityInKwAtSub7Degrees: value.heatingCapacityAtSub7Degrees,
        heatingCapacityInKwAtSub10Degrees: value.heatingCapacityAtSub10Degrees,
        heatingCapacityInKwAtSub15Degrees: value.heatingCapacityAtSub15Degrees,
        buildingHeatingLoad: userInputHeatingLoad,
        valueOfNormOutsideTemp: userInputNormTemp,
      })

      // console.log(heatPump.oegNumber, value.maxPreheatValue, calculatedHeatPumpPowerConsume)
      // Geeignete Kombination aus Wärmepumpe und Vorlauftemperatur (-7 < x < -2)
      // =WENN(UND(-7<$'Eingabe und Diagramme'.$Y$25;$'Eingabe und Diagramme'.$Y$25<-2);1;"")
      if (calculatedHeatPumpPowerConsume.preHeatingLoad.x < dualPointRange.x1) {
        continue
      }
      if (calculatedHeatPumpPowerConsume.preHeatingLoad.x > dualPointRange.x2) {
        continue
      }

      // Paket 650011180 benötigt ein PZusatz von mind. 7,6 kW bei 35 °C Vorlauftemp. und -10 °C Normaußentemp.
      // Der größte einschraubbare Heizstab hat jedoch nur 4,5 kW Leistung. Deshalb darf das Paket nicht aufgelistet werden.
      if (calculatedHeatPumpPowerConsume.pAdditional > heatPumpPackage.heatingRodPower) {
        // rod can not do it
        logFilterRemoved(
          'pAdditional',
          heatPumpPackage,
          'heatingRodPower',
          'calculatedHeatPumpPowerConsume.pAdditional > heatPumpPackage.heatingRodPower'
        )
        continue
      }

      filterFoundTemp = true
      break
    }
  }

  return filterFoundTemp
}

const logFilterRemoved = (
  selector: string,
  heatPumpPack: HeatPumpPackageInterface,
  filterName: string,
  condition: boolean | string | number
) => console.info('[-]', selector + ' removed: ', heatPumpPack.productExternalId, filterName, condition)

const logFilterAdded = (
  selector: string,
  heatPumpPack: HeatPumpPackageInterface,
  filterName: string,
  condition: boolean | string | number | FilterFlagsInterface
) => console.info('[+]', selector + ' added: ', heatPumpPack.productExternalId, filterName, condition)

export const selectHeatPumpPackagesFilteredByForm = createSelector(
  getHeatPumpPackagesFromState,
  getFilterFlags,
  (heatPumpPackages: HeatPumpPackageInterface[], filterFlags: FilterFlagsInterface) => {
    const filtered: HeatPumpPackageInterface[] = []

    heatPumpPackages.map((heatPumpPack: HeatPumpPackageInterface) => {
      if (filterFlags.filterOptionCooling !== heatPumpPack.filterOptionCooling) {
        logFilterRemoved(
          'selectHeatPumpPackagesFilteredByForm',
          heatPumpPack,
          'filterOptionCooling',
          filterFlags.filterOptionCooling
        )
        return null
      }

      if (filterFlags.filterOptionDrinkingWaterHeating !== heatPumpPack.filterOptionDrinkingWaterHeating) {
        logFilterRemoved(
          'selectHeatPumpPackagesFilteredByForm',
          heatPumpPack,
          'filterOptionDrinkingWaterHeating',
          filterFlags.filterOptionDrinkingWaterHeating
        )
        return null
      }

      // special case: kein solar für heizung aber für warm wasser:
      // filterOptionHeatingSupport = false
      // filterOptionAdditionalDrinkingWaterHeating = true
      // muss filterOptionHeatingSupport -> als true betrachtet werden
      if (
        // no solar suppor
        !filterFlags.filterOptionHeatingSupport &&
        // but solar for drinking water
        filterFlags.filterOptionAdditionalDrinkingWaterHeating &&
        // and pump can it
        ((heatPumpPack.filterOptionHeatingSupport && heatPumpPack.filterOptionAdditionalDrinkingWaterHeating) ||
          (!heatPumpPack.filterOptionHeatingSupport && heatPumpPack.filterOptionAdditionalDrinkingWaterHeating))
      ) {
        // OK
        // ADD PUMP TO RESULT
        filtered.push(heatPumpPack)
        logFilterAdded(
          'selectHeatPumpPackagesFilteredByForm',
          heatPumpPack,
          'filterOptionAdditionalDrinkingWaterHeating',
          'special case: add filterOptionHeatingSupport when only filterOptionAdditionalDrinkingWaterHeating'
        )
        // ignore later conditions
        return
      }

      if (
        // WITH solar suppor
        filterFlags.filterOptionHeatingSupport &&
        // but NO solar for drinking water
        !filterFlags.filterOptionAdditionalDrinkingWaterHeating &&
        // and pump can it
        ((heatPumpPack.filterOptionHeatingSupport && heatPumpPack.filterOptionAdditionalDrinkingWaterHeating) ||
          (heatPumpPack.filterOptionHeatingSupport && !heatPumpPack.filterOptionAdditionalDrinkingWaterHeating))
      ) {
        // OK
        // ADD PUMP TO RESULT
        filtered.push(heatPumpPack)
        logFilterAdded(
          'selectHeatPumpPackagesFilteredByForm',
          heatPumpPack,
          'filterOptionAdditionalDrinkingWaterHeating',
          'special case: add filterOptionAdditionalDrinkingWaterHeating when only filterOptionHeatingSupport'
        )
        // ignore later conditions
        return
      }

      // WARNTING: CONDITION ABOVE MAY SKIP THIS
      if (
        filterFlags.filterOptionAdditionalDrinkingWaterHeating !==
        heatPumpPack.filterOptionAdditionalDrinkingWaterHeating
      ) {
        logFilterRemoved(
          'selectHeatPumpPackagesFilteredByForm',
          heatPumpPack,
          'filterOptionAdditionalDrinkingWaterHeating',
          filterFlags.filterOptionAdditionalDrinkingWaterHeating
        )
        return null
      }

      // WARNTING: CONDITION ABOVE MAY SKIP THIS
      if (filterFlags.filterOptionHeatingSupport !== heatPumpPack.filterOptionHeatingSupport) {
        logFilterRemoved(
          'selectHeatPumpPackagesFilteredByForm',
          heatPumpPack,
          'filterOptionHeatingSupport',
          filterFlags.filterOptionHeatingSupport
        )
        return null
      }

      // filter packages where "empfohlene max. Personenanzahl für Trinkwassererwärmung" überschritt wird
      if (filterFlags.maxPersonsDrinkingWater > 0) {
        if (heatPumpPack.maxPersonsDrinkingWater < filterFlags.maxPersonsDrinkingWater) {
          logFilterRemoved(
            'selectFilteredHeatPumpPackages',
            heatPumpPack,
            'maxPersonsDrinkingWater',
            'filter value: ' +
              filterFlags.maxPersonsDrinkingWater +
              ' not valid for packValue ' +
              heatPumpPack.maxPersonsDrinkingWater
          )
          return null
        }
      }

      logFilterAdded('selectHeatPumpPackagesFilteredByForm', heatPumpPack, 'filtered', filterFlags)

      filtered.push(heatPumpPack)
    })

    return filtered
  }
)

const pumpAndRodValidation = (
  heatPumpPack: HeatPumpPackageInterface,
  relevantPumps: HeatPumpInterface[],
  userInputHeatingLoad: number,
  userInputNormTemp: number
): preHeatingTemperaturMatrix => {
  return {
    35: checkPumpPackCanDoTemp(heatPumpPack, 35, relevantPumps, userInputHeatingLoad, userInputNormTemp),
    45: checkPumpPackCanDoTemp(heatPumpPack, 45, relevantPumps, userInputHeatingLoad, userInputNormTemp),
    55: checkPumpPackCanDoTemp(heatPumpPack, 55, relevantPumps, userInputHeatingLoad, userInputNormTemp),
    70: checkPumpPackCanDoTemp(heatPumpPack, 70, relevantPumps, userInputHeatingLoad, userInputNormTemp),
  }
}

export const selectFilteredHeatPumpPackages = createSelector(
  selectHeatPumpPackagesFilteredByForm,
  getFilterFlags,
  getRelevantHeatPumpsFromState,
  getNormTemperatur,
  getHeatingLoad,
  (
    heatPumpPackages,
    filterFlags,
    relevantPumps: HeatPumpInterface[],
    userInputNormTemp: number,
    userInputHeatingLoad: number
  ) => {
    const filtered: HeatPumpPackageInterface[] = []
    const preHeatingInformation: { [key: number]: preHeatingTemperaturMatrix } = []

    heatPumpPackages.map((heatPumpPack: HeatPumpPackageInterface) => {
      if (filterFlags.filterOptionTankLyingDown || filterFlags.filterOptionTankStanding) {
        if (filterFlags.filterOptionTankLyingDown && !heatPumpPack.filterOptionTankLyingDown) {
          logFilterRemoved(
            'selectFilteredHeatPumpPackages',
            heatPumpPack,
            'filterOptionTankLyingDown',
            filterFlags.filterOptionTankLyingDown
          )
          return null
        }

        if (filterFlags.filterOptionTankStanding && heatPumpPack.filterOptionTankLyingDown) {
          logFilterRemoved(
            'selectFilteredHeatPumpPackages',
            heatPumpPack,
            'filterOptionTankStanding',
            filterFlags.filterOptionTankStanding
          )
          return null
        }
      }

      if (filterFlags.filterOptionWithOneTank || filterFlags.filterOptionMoreThanOneTank) {
        if (filterFlags.filterOptionMoreThanOneTank && !heatPumpPack.filterOptionMoreThanOneTank) {
          logFilterRemoved(
            'selectFilteredHeatPumpPackages',
            heatPumpPack,
            'filterOptionMoreThanOneTank',
            filterFlags.filterOptionMoreThanOneTank
          )
          return null
        }

        if (filterFlags.filterOptionWithOneTank && heatPumpPack.filterOptionMoreThanOneTank) {
          logFilterRemoved(
            'selectFilteredHeatPumpPackages',
            heatPumpPack,
            'filterOptionWithOneTank',
            filterFlags.filterOptionWithOneTank
          )
          return null
        }
      }

      if (filterFlags.filterOptionSgOptimized && !heatPumpPack.filterOptionSgOptimized) {
        logFilterRemoved(
          'selectFilteredHeatPumpPackages',
          heatPumpPack,
          'filterOptionSgOptimized',
          filterFlags.filterOptionSgOptimized
        )
        return null
      }

      if (
        filterFlags.filterOptionPurePlusFreshWaterHeatExchanger &&
        !heatPumpPack.filterOptionPurePlusFreshWaterHeatExchanger
      ) {
        logFilterRemoved(
          'selectFilteredHeatPumpPackages',
          heatPumpPack,
          'filterOptionPurePlusFreshWaterHeatExchanger',
          filterFlags.filterOptionPurePlusFreshWaterHeatExchanger
        )
        return null
      }

      const pumpCheck = pumpAndRodValidation(heatPumpPack, relevantPumps, userInputHeatingLoad, userInputNormTemp)

      if (filterFlags.filterOptionTemperature35 && !pumpCheck[35]) {
        logFilterRemoved(
          'selectFilteredHeatPumpPackages',
          heatPumpPack,
          'filterOptionTemperature35',
          filterFlags.filterOptionTemperature35
        )
        return null
      }

      if (filterFlags.filterOptionTemperature45 && !pumpCheck[45]) {
        logFilterRemoved(
          'selectFilteredHeatPumpPackages',
          heatPumpPack,
          'filterOptionTemperature45',
          filterFlags.filterOptionTemperature45
        )
        return null
      }

      if (filterFlags.filterOptionTemperature55 && !pumpCheck[55]) {
        logFilterRemoved(
          'selectFilteredHeatPumpPackages',
          heatPumpPack,
          'filterOptionTemperature55',
          filterFlags.filterOptionTemperature55
        )
        return null
      }

      if (filterFlags.filterOptionTemperature70 && !pumpCheck[70]) {
        logFilterRemoved(
          'selectFilteredHeatPumpPackages',
          heatPumpPack,
          'filterOptionTemperature55',
          filterFlags.filterOptionTemperature55
        )
        return null
      }

      // remove pack anyway if no temp is possible for pump/rod
      if (!pumpCheck[35] && !pumpCheck[45] && !pumpCheck[55] && !pumpCheck[70]) {
        logFilterRemoved(
          'selectFilteredHeatPumpPackages',
          heatPumpPack,
          'rodPowerLevel',
          '!pumpCheck[35] && !pumpCheck[45] && !pumpCheck[55] && !pumpCheck[70]'
        )
        return null
      }

      // store for sorting
      preHeatingInformation[heatPumpPack.productId] = pumpCheck

      filtered.push(heatPumpPack)
    })

    // sort data
    const sortHeatPumpSets = (filteredPacks: HeatPumpPackageInterface[]) => {
      try {
        const data1 = filteredPacks

        data1.sort((a, b) => {
          return a.filterOptionSgOptimized && !b.filterOptionSgOptimized ? -1 : 0
        })

        data1.sort((a, b) => {
          return a.filterOptionPurePlusFreshWaterHeatExchanger && !b.filterOptionPurePlusFreshWaterHeatExchanger
            ? -1
            : 0
        })

        // @todo: speicherinhalt?

        data1.sort((a, b) => {
          const aHeating55 = preHeatingInformation[a.productId][55]
          const bHeating55 = preHeatingInformation[b.productId][55]

          return aHeating55 && !bHeating55 ? -1 : 0
        })

        data1.sort((a, b) => {
          const aHeating45 = preHeatingInformation[a.productId][45]
          const bHeating45 = preHeatingInformation[b.productId][45]

          return aHeating45 && !bHeating45 ? -1 : 0
        })

        data1.sort((a, b) => {
          const aHeating35 = preHeatingInformation[a.productId][35]
          const bHeating35 = preHeatingInformation[b.productId][35]

          return aHeating35 && !bHeating35 ? -1 : 0
        })

        //return filteredPacks
        return data1
      } catch (e) {
        console.error(e)
      }
      return filteredPacks
    }

    return sortHeatPumpSets(filtered)
  }
)

export const selectHeatPumpSetsWithOneTank = createSelector(
  selectFilteredHeatPumpPackages,
  (items: HeatPumpPackageInterface[]) => {
    return items.filter((heatPumpSet) => !heatPumpSet.filterOptionMoreThanOneTank)
  }
)
export const selectHeatPumpSetsWithMoreThanOneTank = createSelector(
  selectFilteredHeatPumpPackages,
  (items: HeatPumpPackageInterface[]) => items.filter((heatPumpSet) => heatPumpSet.filterOptionMoreThanOneTank)
)

export const selectHeatPumpSetsWithTankLyingDown = createSelector(
  selectFilteredHeatPumpPackages,
  (items: HeatPumpPackageInterface[]) => items.filter((heatPumpSet) => heatPumpSet.filterOptionTankLyingDown)
)
export const selectHeatPumpSetsWithTankStandingUp = createSelector(
  selectFilteredHeatPumpPackages,
  (items: HeatPumpPackageInterface[]) => items.filter((heatPumpSet) => !heatPumpSet.filterOptionTankLyingDown)
)

export const selectHeatPumpSetsWithTemperature35 = createSelector(
  selectFilteredHeatPumpPackages,
  getRelevantHeatPumpsFromState,
  getHeatingLoad,
  getNormTemperatur,

  (items: HeatPumpPackageInterface[], relevantPumps, userInputHeatingLoad, userInputNormTemp) =>
    items.filter((heatPumpSet) =>
      checkPumpPackCanDoTemp(heatPumpSet, 35, relevantPumps, userInputHeatingLoad, userInputNormTemp)
    )
)

export const selectHeatPumpSetsWithTemperature45 = createSelector(
  selectFilteredHeatPumpPackages,
  getRelevantHeatPumpsFromState,
  getHeatingLoad,
  getNormTemperatur,

  (items: HeatPumpPackageInterface[], relevantPumps, userInputHeatingLoad, userInputNormTemp) =>
    items.filter((heatPumpSet) =>
      checkPumpPackCanDoTemp(heatPumpSet, 45, relevantPumps, userInputHeatingLoad, userInputNormTemp)
    )
)

export const selectHeatPumpSetsWithTemperature55 = createSelector(
  selectFilteredHeatPumpPackages,
  getRelevantHeatPumpsFromState,
  getHeatingLoad,
  getNormTemperatur,

  (items: HeatPumpPackageInterface[], relevantPumps, userInputHeatingLoad, userInputNormTemp) =>
    items.filter((heatPumpSet) =>
      checkPumpPackCanDoTemp(heatPumpSet, 55, relevantPumps, userInputHeatingLoad, userInputNormTemp)
    )
)

export const selectHeatPumpSetsWithTemperature70 = createSelector(
  selectFilteredHeatPumpPackages,
  getRelevantHeatPumpsFromState,
  getHeatingLoad,
  getNormTemperatur,
  (items: HeatPumpPackageInterface[], relevantPumps, userInputHeatingLoad, userInputNormTemp) =>
    items.filter((heatPumpSet) =>
      checkPumpPackCanDoTemp(heatPumpSet, 70, relevantPumps, userInputHeatingLoad, userInputNormTemp)
    )
)

export const selectHeatPumpSetsWithSgOptimized = createSelector(
  selectFilteredHeatPumpPackages,
  (items: HeatPumpPackageInterface[]) => items.filter((heatPumpSet) => heatPumpSet.filterOptionSgOptimized)
)

export const selectHeatPumpSetsWithPurePlusHeatExchanger = createSelector(
  selectFilteredHeatPumpPackages,
  (items: HeatPumpPackageInterface[]) =>
    items.filter((heatPumpSet) => heatPumpSet.filterOptionPurePlusFreshWaterHeatExchanger)
)

export const selectHeatPumpSetsWithRoomCooling = createSelector(
  selectFilteredHeatPumpPackages,
  (items: HeatPumpPackageInterface[]) => items.filter((heatPumpSet) => heatPumpSet.filterOptionCooling)
)
export const selectHeatPumpSetsWithoutRoomCooling = createSelector(
  selectFilteredHeatPumpPackages,
  (items: HeatPumpPackageInterface[]) => items.filter((heatPumpSet) => !heatPumpSet.filterOptionCooling)
)

export const selectHeatPumpSetsWithDrinkingWaterHeating = createSelector(
  selectFilteredHeatPumpPackages,
  (items: HeatPumpPackageInterface[]) => items.filter((heatPumpSet) => heatPumpSet.filterOptionDrinkingWaterHeating)
)
export const selectHeatPumpSetsWithoutDrinkingWaterHeating = createSelector(
  selectFilteredHeatPumpPackages,
  (items: HeatPumpPackageInterface[]) => items.filter((heatPumpSet) => !heatPumpSet.filterOptionDrinkingWaterHeating)
)

export const selectHeatPumpSetsWithHeatingSupport = createSelector(
  selectFilteredHeatPumpPackages,
  (items: HeatPumpPackageInterface[]) => items.filter((heatPumpSet) => heatPumpSet.filterOptionHeatingSupport)
)
export const selectHeatPumpSetsWithoutHeatingSupport = createSelector(
  selectFilteredHeatPumpPackages,
  (items: HeatPumpPackageInterface[]) => items.filter((heatPumpSet) => !heatPumpSet.filterOptionHeatingSupport)
)

export const selectHeatPumpSetsWithCooling = createSelector(
  selectFilteredHeatPumpPackages,
  (items: HeatPumpPackageInterface[]) => items.filter((heatPumpSet) => heatPumpSet.filterOptionCooling)
)
export const selectHeatPumpSetsWithoutCooling = createSelector(
  selectFilteredHeatPumpPackages,
  (items: HeatPumpPackageInterface[]) => items.filter((heatPumpSet) => !heatPumpSet.filterOptionCooling)
)

export const selectHeatPumpSetsWithAdditionalDrinkingWaterHeating = createSelector(
  selectFilteredHeatPumpPackages,
  (items: HeatPumpPackageInterface[]) =>
    items.filter((heatPumpSet) => heatPumpSet.filterOptionAdditionalDrinkingWaterHeating)
)
export const selectHeatPumpSetsWithoutAdditionalDrinkingWaterHeating = createSelector(
  selectFilteredHeatPumpPackages,
  (items: HeatPumpPackageInterface[]) =>
    items.filter((heatPumpSet) => !heatPumpSet.filterOptionAdditionalDrinkingWaterHeating)
)
