import { FormFieldNameEnum } from '@/features/fields/FormFieldNameEnum'
import { BuildingTypesKeysEnum } from '@/features/steps/StepChooseBuildingType'
import { ConfiguratorModesKeysEnum } from '@/features/steps/StepChooseProjectType'
import { UseFormValues } from '@formiz/core/dist/types/form.types'

interface ResultInterface {
  currentHeaterTypeValue?: 'gas' | 'oil' | 'wp' | 'other'
  currentWarmWaterHeaterType?: 'gas' | 'gas-solar' | 'oil' | 'oil-solar' | 'wp' | 'wp-solar' | 'water-heater' | 'other'
  energySavingCheckboxGroup: string[]
  habitableSpaceValue: number
  hoursDisabled: number
  isBuildingMultiFamily: boolean
  isBuildingSingleFamily: boolean
  isBuildingTypeNonResidentialBuilding: boolean
  isHeatPumpGenerateWarmWater: boolean
  isModeModernisation: boolean
  isModeRebuild: boolean
  isSourceHeatingLoadByConsumption: boolean
  isSourceHeatingLoadCalculation: boolean
  isSourceHeatingLoadIsKnown: boolean
  isWithSolar: boolean
  knownGasOrOilOrPowerConsume: number | undefined
  numberOfPersonsPerBuilding: number
  qH: undefined | number
  totalNumberOfPersons: number
  numberOfResidentialUnits?: number
  numberOfPersonsPerResidentialUnit?: number
  warmWaterNumberOfPersonsPerResidence?: number
  warmWaterNumberHeatingLoadQwwDay?: number
  warmWaterNumberHeatingLoadQwwDayHours?: number
}

export const formValuesHelper = ({ myForm }: { myForm: UseFormValues }): ResultInterface => {
  const isBuildingSingleFamily = () =>
    myForm.flatValues[FormFieldNameEnum.selectBuildingType] === BuildingTypesKeysEnum.singleFamilyHouse

  const isBuildingMultiFamily = () =>
    myForm.flatValues[FormFieldNameEnum.selectBuildingType] === BuildingTypesKeysEnum.multiFamilyHouse
  const isBuildingTypeNonResidentialBuilding = () =>
    myForm.flatValues[FormFieldNameEnum.selectBuildingType] === BuildingTypesKeysEnum.nonResidentialBuilding

  const isModeModernisation = () =>
    myForm.flatValues[FormFieldNameEnum.selectProjectType] === ConfiguratorModesKeysEnum.modernisation

  const isSourceHeatingLoadCalculation = () =>
    myForm.flatValues[FormFieldNameEnum.heatingLoadSource] === 'heatingLoadCalculation'

  const isSourceHeatingLoadIsKnown = () =>
    myForm.flatValues[FormFieldNameEnum.heatingLoadSource] === 'heatingLoadIsKnown'

  const isSourceHeatingLoadByConsumption = () =>
    myForm.flatValues[FormFieldNameEnum.heatingLoadSource] === 'heatingLoadByConsumption'

  const isModeRebuild = () =>
    myForm.flatValues[FormFieldNameEnum.selectProjectType] === ConfiguratorModesKeysEnum.newBuilding

  const getNumberOfPersonsPerBuilding = (): number =>
    parseInt(myForm.flatValues.warmWaterNumberOfPersonsPerBuilding ?? '0')

  const hoursDisabled =
    myForm.flatValues.selectUseEnergyWithTimeLocks === 'no' ? 0 : parseInt(myForm.flatValues.energyWithTimeLocksHours)
  const currentHeaterTypeValue = myForm.flatValues.selectCurrentHeatingSystemType ?? undefined
  const habitableSpaceValue = myForm.flatValues.habitableSpace ? parseInt(myForm.flatValues.habitableSpace) : 0
  const energySavingCheckboxGroup = myForm.flatValues.energySavingCheckboxGroup ?? []

  // warm water
  const isHeatPumpGenerateWarmWater = () => myForm.flatValues[FormFieldNameEnum.selectUseToGenerateWarmWater] === 'yes'
  const totalNumberOfPersons = getNumberOfPersonsPerBuilding()
  const currentWarmWaterHeaterType = myForm.flatValues[FormFieldNameEnum.selectCurrentWarmWaterHeatingSystemType]

  const knownGasOrOilOrPowerConsume = myForm.flatValues.knownGasOrOilOrPowerConsumeValue
    ? parseFloat(myForm.flatValues.knownGasOrOilOrPowerConsumeValue)
    : undefined

  const qH_knownBuildingHeatingLoadValue = myForm.flatValues.knownBuildingHeatingLoadValue
    ? parseFloat(myForm.flatValues.knownBuildingHeatingLoadValue)
    : myForm.flatValues.heatingLoadValueQh
      ? parseFloat(myForm.flatValues.heatingLoadValueQh)
      : undefined

  const isWithSolar = () =>
    (isModeModernisation() &&
      (currentWarmWaterHeaterType?.toLowerCase().includes('solar') ||
        myForm.flatValues[FormFieldNameEnum.selectCurrentUseOfSolarToGenerateWarmWater] === 'yes')) ||
    (isModeRebuild() && myForm.flatValues[FormFieldNameEnum.selectUseSolarHeatingSupport] === 'yes')

  const numberOfResidentialUnits = myForm.flatValues.numberOfResidentialUnits
    ? parseInt(myForm.flatValues.numberOfResidentialUnits)
    : undefined

  const numberOfPersonsPerResidentialUnit = myForm.flatValues.numberOfPersonsPerResidentialUnit
    ? parseInt(myForm.flatValues.numberOfPersonsPerResidentialUnit)
    : undefined

  const warmWaterNumberOfPersonsPerResidence = myForm.flatValues.warmWaterNumberOfPersonsPerResidence
    ? parseInt(myForm.flatValues.warmWaterNumberOfPersonsPerResidence)
    : undefined

  return {
    currentHeaterTypeValue: currentHeaterTypeValue,
    currentWarmWaterHeaterType: currentWarmWaterHeaterType,
    energySavingCheckboxGroup: energySavingCheckboxGroup,
    habitableSpaceValue: habitableSpaceValue,
    hoursDisabled: hoursDisabled,
    isBuildingMultiFamily: isBuildingMultiFamily(),
    isBuildingSingleFamily: isBuildingSingleFamily(),
    isBuildingTypeNonResidentialBuilding: isBuildingTypeNonResidentialBuilding(),
    isHeatPumpGenerateWarmWater: isHeatPumpGenerateWarmWater(),
    isModeModernisation: isModeModernisation(),
    isModeRebuild: isModeRebuild(),
    isSourceHeatingLoadByConsumption: isSourceHeatingLoadByConsumption(),
    isSourceHeatingLoadCalculation: isSourceHeatingLoadCalculation(),
    isSourceHeatingLoadIsKnown: isSourceHeatingLoadIsKnown(),
    isWithSolar: isWithSolar(),
    knownGasOrOilOrPowerConsume: knownGasOrOilOrPowerConsume,
    numberOfPersonsPerBuilding: getNumberOfPersonsPerBuilding(),
    qH: qH_knownBuildingHeatingLoadValue,
    totalNumberOfPersons: totalNumberOfPersons,
    numberOfResidentialUnits: numberOfResidentialUnits,
    numberOfPersonsPerResidentialUnit: numberOfPersonsPerResidentialUnit,
    warmWaterNumberOfPersonsPerResidence: warmWaterNumberOfPersonsPerResidence,
    warmWaterNumberHeatingLoadQwwDay: myForm.flatValues.warmWaterNumberHeatingLoadQwwDay ?? undefined,
    warmWaterNumberHeatingLoadQwwDayHours: myForm.flatValues.warmWaterNumberHeatingLoadQwwDayHours ?? undefined,
  }
}
