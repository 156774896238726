import { Box, Slider, SliderFilledTrack, SliderMark, SliderThumb, SliderTrack } from '@chakra-ui/react'
import { useField } from '@formiz/core'
import { UseFieldProps } from '@formiz/core/dist/types/field.types'
import { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { FormGroup, FormGroupPropsInterface } from './FormGroup'

interface SliderHabitableSpacePropsInterface extends UseFieldProps {
  minValue: number
  maxValue: number
  label: string
  unitMarker: string
  name: string
}

export const SliderWithStepMarks = (props: SliderHabitableSpacePropsInterface) => {
  const { errorMessage, id, isValid, isSubmitted, isPristine, resetKey, setValue, otherProps } = useField(props)
  const { required, children, label, helper, minValue, maxValue, unitMarker } = otherProps
  const { name, defaultValue } = props

  const [sliderValue, setSliderValue] = useState(minValue)
  const [isTouched, setIsTouched] = useState(false)
  const showError = !isValid && (isTouched || isSubmitted)

  useEffect(() => {
    if (typeof defaultValue !== 'undefined') {
      setValue(defaultValue)
      setSliderValue(defaultValue)
    }
  }, [setSliderValue, setValue, defaultValue])

  useEffect(() => {
    setIsTouched(false)
  }, [setIsTouched, resetKey])

  const labelStyles = {
    mt: '2',
    ml: '-2.5',
    fontSize: 'sm',
  }

  const formGroupProps: FormGroupPropsInterface = {
    errorMessage,
    helper,
    id,
    isRequired: !!required,
    label,
    showError,
    children,
  }
  const calculatePercent = (percent: number, value: number): number => {
    return (percent / 100) * value
  }
  const showPleaseChoose = (): boolean => {
    return isPristine && !isTouched
  }
  return (
    <FormGroup {...formGroupProps}>
      <Box pt={6} pb={2} className={'slider__' + name + ' slider__container'}>
        <Slider
          name={name}
          aria-label="slider-ex-6"
          onChange={(val) => setSliderValue(val)}
          onChangeEnd={(value) => setValue(value)}
          onFocus={() => setIsTouched(true)}
          onTouchStart={() => setIsTouched(true)}
          onTouchEnd={() => setIsTouched(false)}
          min={minValue}
          max={maxValue}
          defaultValue={defaultValue ?? minValue}
          value={sliderValue}
          data-testid={name}
          className={' slider__content'}
        >
          <SliderMark value={minValue} {...labelStyles} className={'slider__marker'}>
            {minValue}
            {unitMarker}
          </SliderMark>
          <SliderMark value={calculatePercent(25, maxValue)} {...labelStyles} className={'slider__marker'}>
            {calculatePercent(25, maxValue)}
            {unitMarker}
          </SliderMark>
          <SliderMark value={calculatePercent(50, maxValue)} {...labelStyles} className={'slider__marker'}>
            {calculatePercent(50, maxValue)}
            {unitMarker}
          </SliderMark>
          <SliderMark value={calculatePercent(75, maxValue)} {...labelStyles} className={'slider__marker'}>
            {calculatePercent(75, maxValue)}
            {unitMarker}
          </SliderMark>
          <SliderMark value={maxValue} {...labelStyles} className={'slider__marker'}>
            {maxValue}
            {unitMarker}
          </SliderMark>
          <SliderMark
            value={sliderValue}
            textAlign="center"
            bg="blue.500"
            color="white"
            mt="-10"
            ml="-5"
            w="12"
            className={'slider__marker_current'}
            data-testid={name + '_current'}
          >
            {showPleaseChoose() ? (
              <FormattedMessage id={'Bitte wählen'} />
            ) : (
              <>
                {sliderValue}
                {unitMarker}
              </>
            )}
          </SliderMark>
          <SliderTrack className={'slider__track'}>
            <SliderFilledTrack data-testid={name + '_track'} className={'slider__track_filled'} />
          </SliderTrack>
          <SliderThumb data-testid={name + '_handle'} className={'slider__handle'} />
        </Slider>
      </Box>
    </FormGroup>
  )
}
