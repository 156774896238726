export interface DualPointRangeInterface {
  x1: number
  x2: number
}
export const dualPointRanges = (props: { normOutsideTempValue: number }): DualPointRangeInterface => {
  const inRange = (low: number, num: number, high: number, inclusive: boolean) => {
    inclusive = typeof inclusive === 'undefined' ? false : inclusive
    if (inclusive && num >= low && num <= high) return true
    return num > low && num < high
  }

  // Normaußentemperatur [°C]			Bivalenzpunkte [°C]
  // -16			-4 … -7
  const valuesSets = {
    '-16': [-4, -7],
    // -14			-3 … -6
    '-14': [-3, -6],
    // -12			-3 … -6
    '-12': [-3, -6],
    // -10			-2 … -5
    '-10': [-2, -5],
  }

  const temp = props.normOutsideTempValue
  let values = valuesSets['-16']

  if (inRange(-20, temp, -15, true)) {
    values = valuesSets['-16']
  }

  if (inRange(-15, temp, -11, true)) {
    values = valuesSets['-12']
  }

  if (inRange(-11, temp, -0, true)) {
    values = valuesSets['-10']
  }

  return {
    x1: values[1],
    x2: values[0],
  }
}
