import { RootState } from '@/store/store'
import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import { FilterFlagsInterface } from '../../features/heatPumpSet/HeatPumpSets'

const initialState: FilterFlagsInterface = {
  filterOptionHeating: true,
  filterOptionHeatingSupport: false,
  filterOptionCooling: false,
  filterOptionDrinkingWaterHeating: false,
  filterOptionAdditionalDrinkingWaterHeating: false,
  filterOptionMoreThanOneTank: false,
  filterOptionWithOneTank: false,
  filterOptionTankStanding: false,
  filterOptionTankLyingDown: false,
  filterOptionPurePlusFreshWaterHeatExchanger: false,
  filterOptionSgOptimized: false,
  maxPersonsDrinkingWater: 0,
  relevantHeatPumpOegNumbers: [],
  userInputHeatingLoad: 0,
  userInputNormTemp: 0,
  filterOptionTemperature35: false,
  filterOptionTemperature45: false,
  filterOptionTemperature55: false,
  filterOptionTemperature70: false,
}

const initialStateUserFilter = {
  filterOptionMoreThanOneTank: false,
  filterOptionWithOneTank: false,
  filterOptionTankStanding: false,
  filterOptionTankLyingDown: false,
  filterOptionPurePlusFreshWaterHeatExchanger: false,
  filterOptionSgOptimized: false,
  filterOptionTemperature35: false,
  filterOptionTemperature45: false,
  filterOptionTemperature55: false,
  filterOptionTemperature70: false,
}

interface PayloadInterface {
  name: string
  value: boolean | string[] | string | number
}
const heatPumpSetFilterSlice = createSlice({
  name: 'heatPumpSetFilterSlice',
  initialState: { filter: initialState },
  reducers: {
    resetFilter: (state) => {
      state.filter = initialState
    },
    resetUserFilter: (state) => {
      const currentFlags: FilterFlagsInterface = state.filter
      // reset user filters
      currentFlags.filterOptionMoreThanOneTank = false
      currentFlags.filterOptionWithOneTank = false
      currentFlags.filterOptionTankStanding = false
      currentFlags.filterOptionTankLyingDown = false
      currentFlags.filterOptionPurePlusFreshWaterHeatExchanger = false
      currentFlags.filterOptionSgOptimized = false
      currentFlags.filterOptionTemperature35 = false
      currentFlags.filterOptionTemperature45 = false
      currentFlags.filterOptionTemperature55 = false
      currentFlags.filterOptionTemperature70 = false

      state.filter = currentFlags
    },
    setFilterOptions: (state, param: PayloadAction<FilterFlagsInterface>) => {
      const { payload } = param
      state.filter = payload
    },
    setFilterOption: (state, param: PayloadAction<PayloadInterface>) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (typeof state.filter[param.payload.name] === 'undefined') {
        throw new Error(param.payload.name + ' is no valid filter option')
      }
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      state.filter[param.payload.name] = param.payload.value
    },
  },
})

const { actions, reducer } = heatPumpSetFilterSlice
export const { setFilterOptions, setFilterOption, resetFilter, resetUserFilter } = actions

export const getFilterFlags = (state: RootState): FilterFlagsInterface => state.heatPumpSetFilterSlice.filter

export const filtersAreUsed = (state: RootState): boolean => {
  return JSON.stringify(state.heatPumpSetFilterSlice.filter) !== JSON.stringify(initialState)
}
export const userFiltersAreUsed = (state: RootState): boolean => {
  const currentUserFilters = {
    filterOptionMoreThanOneTank: state.heatPumpSetFilterSlice.filter.filterOptionMoreThanOneTank,
    filterOptionWithOneTank: state.heatPumpSetFilterSlice.filter.filterOptionWithOneTank,
    filterOptionTankStanding: state.heatPumpSetFilterSlice.filter.filterOptionTankStanding,
    filterOptionTankLyingDown: state.heatPumpSetFilterSlice.filter.filterOptionTankLyingDown,
    filterOptionPurePlusFreshWaterHeatExchanger:
      state.heatPumpSetFilterSlice.filter.filterOptionPurePlusFreshWaterHeatExchanger,
    filterOptionSgOptimized: state.heatPumpSetFilterSlice.filter.filterOptionSgOptimized,
    filterOptionTemperature35: state.heatPumpSetFilterSlice.filter.filterOptionTemperature35,
    filterOptionTemperature45: state.heatPumpSetFilterSlice.filter.filterOptionTemperature45,
    filterOptionTemperature55: state.heatPumpSetFilterSlice.filter.filterOptionTemperature55,
    filterOptionTemperature70: state.heatPumpSetFilterSlice.filter.filterOptionTemperature70,
  }
  return JSON.stringify(currentUserFilters) !== JSON.stringify(initialStateUserFilter)
}

export default reducer
