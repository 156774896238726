import AppDashboard from '@/features/dashboard/AppDashboard'
import { configuratorTheme } from '@/features/layout/configuratorTheme'
import NoIframeRedirect from '@/features/tools/NoIframeRedirect'
import { TranslationsKeys } from '@/features/translations/TranslationsKeys'
import { useGetTranslationsQuery } from '@/store/features/translationApiSlice'
import { setUserContext } from '@/store/features/userDataSlice'
import { useGetUserSessionQuery } from '@/store/features/userSessionApiSlice'
import { Center, ChakraProvider, Container, Spinner } from '@chakra-ui/react'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import 'iframe-resizer/js/iframeResizer.contentWindow'
import { FormatError } from 'intl-messageformat'
import React, { useEffect } from 'react'
import ReactDOM from 'react-dom/client'
import {
  IntlProvider,
  InvalidConfigError,
  MessageFormatError,
  MissingDataError,
  MissingTranslationError,
  UnsupportedFormatterError,
} from 'react-intl'
import { Provider } from 'react-redux'
import App from './App'
import AppDebug from './AppDebug'
import { store, useAppDispatch } from './store/store'

const params = new URLSearchParams(window.location.search)
const language = params.get('lang') ?? 'de'
const debug = params.get('debug') ?? '0'
const prefillId = params.get('prefillId') ?? undefined

// if (typeof visitor === 'object' && typeof visitor.locale === 'string') {
//   language = visitor.locale;
// }

// error handler
function handleError(
  err:
    | MissingTranslationError
    | MessageFormatError
    | MissingDataError
    | InvalidConfigError
    | UnsupportedFormatterError
    | FormatError
) {
  // if (process.env.NODE_ENV !== 'production' && language !== 'de') {
  const errorMsg = err ? `\n${err.message}` : ''
  // const formattedErrorMsg = `${err}${errorMsg}`;
  console.debug(errorMsg)
  // }
}

const PreLoader = () => {
  const { data: messages, isLoading: isLoadingTranslation } = useGetTranslationsQuery(language, {
    skip: import.meta.env.VITE_API_TRANSLATION_DISABLE === 'true',
  })
  const { data: visitorData, isLoading: isLoadingSession } = useGetUserSessionQuery({})

  const isLoading =
    isLoadingSession || (import.meta.env.VITE_API_TRANSLATION_DISABLE !== 'true' && isLoadingTranslation)

  const doRedirectToIframe = (): boolean => {
    if (import.meta.env.VITE_APP_REDIRECT_NO_IFRAME !== 'true') {
      // no auto redirect
      return false
    }

    // dashboard and prefill
    if (window.location.pathname === '/backend/dashboard') {
      return false
    }
    if (window.location.pathname === '/prefill' && typeof prefillId !== 'undefined') {
      return false
    }

    return window.self === window.top
  }

  const dispatch = useAppDispatch()

  if (window.location.pathname === '/translation') {
    return (
      <>
        <TranslationsKeys />
      </>
    )
  }

  if (isLoading) {
    return (
      <>
        <Container minHeight="560px">
          <Center h="calc(70vh)">
            <Spinner boxSize={100} thickness="10px" speed="0.85s" color="brand.500" />
          </Center>
        </Container>
      </>
    )
  } else {
    const AppWrapper = () => {
      useEffect(() => {
        // set initial user context data
        const data = { ...visitorData, languageCode: language, debug: debug === '1' } // use param from iframe
        dispatch(setUserContext(data))
      })

      if (doRedirectToIframe()) {
        return (
          <Center>
            <NoIframeRedirect language={language} />
          </Center>
        )
      }

      if (window.location.pathname === '/debug') {
        return <AppDebug />
      }

      if (window.location.pathname === '/backend/dashboard') {
        return <AppDashboard />
      }
      if (window.location.pathname === '/prefill' && typeof prefillId !== 'undefined') {
        return <App doPreFillWithId={parseInt(prefillId)} />
      }
      return <App />
    }

    return (
      <IntlProvider locale={language} messages={messages ?? []} onError={handleError}>
        <AppWrapper />
      </IntlProvider>
    )
  }
}

if (import.meta.env.VITE_SENTRY_ENABLED === 'true') {
  Sentry.init({
    dsn: 'https://2cfb6f23527749fba5895f6ae8782679@o4504638040113152.ingest.sentry.io/4504638049288192',
    integrations: [
      new BrowserTracing(),
      new Sentry.Replay({
        // Additional SDK configuration goes in here, for example:
        maskAllText: false,
        blockAllMedia: false,
        maskAllInputs: false,
      }),
    ],
    environment: import.meta.env.VITE_SENTRY_ENV,
    enabled: true,
    attachStacktrace: true,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 1.0,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,
  })
}

ReactDOM.createRoot(document.getElementById('heat-pump-configurator-app') as HTMLElement).render(
  <React.StrictMode>
    <Provider store={store}>
      <ChakraProvider theme={configuratorTheme} cssVarsRoot="#heat-pump-configurator-app" resetCSS={false}>
        <PreLoader />
      </ChakraProvider>
    </Provider>
  </React.StrictMode>
)
