interface parentDataInterface {
  iframeHeight: number
  iframeWidth: number
  clientHeight: number
  clientWidth: number
  offsetTop: number
  scrollTop: number
}

export const scrollParentWindowToTop = () => {
  if ('parentIFrame' in window) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    parentIFrame.getPageInfo((infos: parentDataInterface) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      // receive data only once per click!
      parentIFrame.getPageInfo(false)

      if (infos.clientWidth <= 992) {
        // small / medium devices
        if (infos.scrollTop > 150) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          parentIFrame.scrollTo(0, 75) // mobile toolbar
          return
        }
      }

      if (infos.scrollTop > 300) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        parentIFrame.scrollTo(0, 250) // desktop toolbar
      }
    })
  }

  return true
}
