import { Box } from '@chakra-ui/react'
import { useForm } from '@formiz/core'

export const Debug = () => {
  const form = useForm()
  const queryParams = new URLSearchParams(window.location.search)
  const isDebug = queryParams.get('debug') || false

  return (
    <>
      {isDebug && (
        <Box data-test="debug" as="pre" fontSize="xs" flex="1">
          <Box fontSize="sm" color="gray.400">
            # Debug useForm()
          </Box>
          {JSON.stringify(form, null, 2)}
        </Box>
      )}
    </>
  )
}
