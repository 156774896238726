interface PropertiesInterface {
  knownWpConsume: number
  tVoll:
    | number
    | 2100 /* single with heating */
    | 1800 /* single without heating */
    | 2000 /* multi family with heating */
    | 1700 /* multi family without heating */
}

/**
 *  21) Ich kenne den Wärmepumpen - Stromverbrauch 500 - 25000 kWh/Jahr
 */
export const calculateLoadFromConsumedWp = ({
  knownWpConsume: consume,
  tVoll,
}: PropertiesInterface): { kW: number } => {
  const qWP = consume / tVoll

  return {
    kW: parseFloat(qWP.toFixed(3)),
  }
}
