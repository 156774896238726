import ErrorFallback from '@/app/ErrorFallback'
import { scrollParentWindowToTop } from '@/features/helper/iFrameHelper'
import { StepperWithValueLinks } from '@/features/layout/footer/StepperWithValueLinks'
import PreFiller from '@/features/preFill/PreFiller'
import { Box, Button, Heading, useDisclosure } from '@chakra-ui/react'
import { Formiz, useForm } from '@formiz/core'
import { UseFormValues } from '@formiz/core/dist/types/form.types'
import React, { useState } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { FormattedMessage } from 'react-intl'
import { PageLayout } from '../features/layout/PageLayout'
import { StepMatrixInterface, StepperWrapper } from '../features/layout/footer/StepperWrapper'
import StatisticUsageTracker from '../features/statistic/StatisticUsageTracker'

interface ConfiguratorFormParams {
  children: React.ReactNode
  stepMatrix: StepMatrixInterface
  doPreFillWithId?: number
}

const ConfiguratorForm = ({ children, stepMatrix, doPreFillWithId }: ConfiguratorFormParams) => {
  const myForm: UseFormValues = useForm()
  const [isLoading, setIsLoading] = useState(false)
  const { isOpen, onOpen, onClose } = useDisclosure()

  const handleSubmit = async (values: object[]) => {
    setIsLoading(true)
    console.log('[+] Form handleSubmit: ', values) // eslint-disable-line no-console
    // await fakeDelay()
    setIsLoading(false)
  }

  // todo: onInvalidSubmit -> zur stelle des fehlers springen
  return (
    <Formiz /* onSubmit={handleSubmit} */ onValidSubmit={handleSubmit} connect={myForm}>
      <ErrorBoundary
        // onError={myErrorHandler}
        FallbackComponent={ErrorFallback}
        onReset={() => {
          // reset the state of your app so the error doesn't happen again
          myForm.reset()
          scrollParentWindowToTop()
        }}
      >
        <StatisticUsageTracker mountTracking={false} reason="events" />
        <PageLayout>
          <form noValidate onSubmit={myForm.submitStep} className="form" style={{ minHeight: '16rem' }}>
            <Box gridColumn="2" textAlign="left" fontSize="md">
              <Heading mb={5} fontSize={'2xl'} className={'stepLabel'}>
                {myForm.currentStep && myForm.currentStep.label}
              </Heading>
            </Box>

            <Box className={'stepContent'}>{children}</Box>

            <footer className={'stepFooter'}>
              <StepperWrapper>
                <StepperWithValueLinks stepMatrix={stepMatrix} />
              </StepperWrapper>

              <Box>
                <div className="ml-auto" style={{ minWidth: '6rem' }}>
                  <Button
                    size={'sm'}
                    gridColumn="1"
                    onClick={() => window.location.replace('/')}
                    colorScheme="brand"
                    className="is-secondary"
                    isDisabled={isLoading || myForm.isSubmitted}
                  >
                    <FormattedMessage id="Reset" />
                  </Button>
                </div>
                <div data-iframe-height="1" style={{ position: 'relative', marginTop: '20px' }}></div>
              </Box>
            </footer>
            {doPreFillWithId ? (
              <PreFiller doPreFillWithId={doPreFillWithId} isOpen={isOpen} onOpen={onOpen} onClose={onClose} />
            ) : null}
          </form>
        </PageLayout>
      </ErrorBoundary>
    </Formiz>
  )
}

export default ConfiguratorForm
