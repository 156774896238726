interface PropertiesInterface {
  qWWTag: number
  hoursActive?: number
}

/**
 * non resident: 1Tag entspricht 8h -> Qww[W] = qWWTag [kWh/Tag] x 1000/ 8h
 *
 * qWTag = user input kWh!
 *
 * @param hoursActive
 * @param qWWTag
 */
export const calculateWarmWaterHeatingLoadNonResidential = ({
  hoursActive,
  qWWTag,
}: PropertiesInterface): { kWh: number } => {
  const qwwDuration = hoursActive ?? 8
  const qWW = (qWWTag * 1000) / qwwDuration / 1000

  return {
    kWh: parseFloat(qWW.toFixed(3)),
  }
}
