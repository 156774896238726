interface FormValuesMaxMinValues {
  heatingLoadMin: number
  heatingLoadMax: number
  heatingLoadWarnMax: number
  heatingGasKwhYearMin: number
  heatingGasKwhYearMax: number
  heatingGasKwhYearWarnMax: number
  heatingOilLiterYearMin: number
  heatingOilLiterYearMax: number
  heatingOilLiterYearWarnMax: number
  heatingElectricityKwhYearMin: number
  heatingElectricityKwhYearMax: number
  heatingElectricityKwhYearWarnMax: number
  heatingOtherKwhYearMin: number
  heatingOtherKwhYearMax: number
  heatingOtherKwhYearWarnMax: number
  warmWaterNumberHeatingLoadQwwDayMin: number
  warmWaterNumberHeatingLoadQwwDayMax: number
  warmWaterNumberOfPersonsPerBuildingMin: number
  warmWaterNumberOfPersonsPerBuildingMax: number
  warmWaterNumberOfPersonsPerBuildingWarnMax: number
  warmWaterNumberOfPersonsPerResidenceMin: number
  warmWaterNumberOfPersonsPerResidenceMax: number
}

interface AppConfigInterface {
  form: FormValuesMaxMinValues
}

/**
 * See excel sheet "230310_1008_Konfiguratorpfade.xlsx"
 */
const FormValuesInitial: FormValuesMaxMinValues = {
  heatingLoadMin: 1,
  heatingLoadMax: 25,
  heatingLoadWarnMax: 19.4, // current border for results
  heatingGasKwhYearMin: 2000,
  heatingGasKwhYearMax: 50000,
  heatingGasKwhYearWarnMax: 41000, // current border for results
  heatingOilLiterYearMin: 200,
  heatingOilLiterYearMax: 5000,
  heatingOilLiterYearWarnMax: 4100,
  heatingElectricityKwhYearMin: 2000,
  heatingElectricityKwhYearMax: 50000,
  heatingElectricityKwhYearWarnMax: 41000, // current border for results
  heatingOtherKwhYearMin: 2000,
  heatingOtherKwhYearMax: 50000,
  heatingOtherKwhYearWarnMax: 41000, // current border for results
  warmWaterNumberHeatingLoadQwwDayMin: 1,
  warmWaterNumberHeatingLoadQwwDayMax: 200,
  warmWaterNumberOfPersonsPerBuildingMin: 1,
  warmWaterNumberOfPersonsPerBuildingMax: 20,
  warmWaterNumberOfPersonsPerBuildingWarnMax: 13, // current border for results
  warmWaterNumberOfPersonsPerResidenceMin: 0.1,
  warmWaterNumberOfPersonsPerResidenceMax: 20.0,
}

const AppConfig: AppConfigInterface = {
  form: FormValuesInitial,
}

export default AppConfig
