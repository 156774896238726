import { FormFieldNameEnum } from '@/features/fields/FormFieldNameEnum'
import type { IntlShape } from 'react-intl/src/types'
import { SelectSingleOptionWithRadioCards } from './SelectSingleOptionWithRadioCards'

export const SelectNumberOfResidentialUnits = ({ intl }: { intl: IntlShape }) => {
  return (
    <SelectSingleOptionWithRadioCards
      label={intl.formatMessage({ id: 'Wie viele Wohneinheiten befinden sich im Gebäude?' })}
      required={intl.formatMessage({ id: 'Anzahl der Wohneinheiten ist eine Pflichtangabe' })}
      name={FormFieldNameEnum.numberOfResidentialUnits}
      boxSize={'55px'}
      options={[
        {
          value: '2',
          label: '2',
        },
        {
          value: '3',
          label: '3',
        },
        {
          value: '4',
          label: '4',
        },
        {
          value: '5',
          label: '5',
        },
        {
          value: '6',
          label: '6',
        },
        {
          value: '7',
          label: '7',
        },
        {
          value: '8',
          label: '8',
        },
        /* grave wants to disable it
        {
          value: '9',
          label: '9',
        },
        {
          value: '10',
          label: '10',
        },
        {
          value: '11',
          label: '11',
        },
        {
          value: '12',
          label: '12+',
        }, */
      ]}
    />
  )
}
