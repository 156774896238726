import { StepNamesEnum } from '@/App'
import { FormFieldNameEnum } from '@/features/fields/FormFieldNameEnum'
import { fakeDelay } from '@/features/helper/fakeDelay'
import { calculateWarmWaterHeatingPersons } from '@/features/math/warmWater/calculateWarmWaterHeatingPersons'
import { BuildingTypesKeysEnum } from '@/features/steps/StepChooseBuildingType'
import { setFilterOption } from '@/store/features/heatPumpSetFilterSlice'
import { useLazyGetPreFillDataByIdQuery } from '@/store/features/userStatsApiSlice'
import { useAppDispatch } from '@/store/store'
import { Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Spinner } from '@chakra-ui/react'
import type { Step } from '@formiz/core'
import { useForm } from '@formiz/core'
import { FormValues } from '@formiz/core/src/types/form.types'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { FormattedMessage } from 'react-intl'

const PreFiller = ({
  doPreFillWithId,
  isOpen,
  onOpen,
  onClose,
}: {
  doPreFillWithId: number | undefined
  isOpen: boolean
  onOpen: () => void
  onClose: () => void
}) => {
  const myForm = useForm()
  const dispatch = useAppDispatch()

  const [running, setRunning] = useState(false)
  const [trigger] = useLazyGetPreFillDataByIdQuery()
  const loadedSteps: React.MutableRefObject<Step[]> = useRef<Step[]>([])

  const getStepByName = useCallback(
    (targetStepName: string): Step | undefined => {
      for (const step of loadedSteps.current) {
        if (step.name === targetStepName) {
          return step
        }
      }
      return undefined
    },
    [loadedSteps]
  )

  const gotoStepUntilVisited = useCallback(
    async (stepName: string) => {
      let targetStep: Step | undefined = getStepByName(stepName)
      if (typeof targetStep === 'undefined' || !targetStep) {
        return
      }

      const timeoutValue = 30
      let timeout = 0

      while (!targetStep.isVisited) {
        await fakeDelay(timeout).then(() => myForm.goToStep(stepName))
        timeout += timeoutValue

        targetStep = getStepByName(stepName)
        if (typeof targetStep === 'undefined' || !targetStep) {
          break
        }

        if (timeout > 30 * timeoutValue) {
          console.error('max iterations while try to visit step reached')
          break
        }
      }
      return
    },
    [getStepByName, myForm]
  )

  const doVisiteAll = useCallback(async () => {
    for (const stepMatrixKey in StepNamesEnum) {
      await gotoStepUntilVisited(stepMatrixKey).then(() => {
        console.log('[+] PreFill: visited: ' + stepMatrixKey)
      })
    }
  }, [gotoStepUntilVisited])

  const setFilterOptions = useCallback(
    async (formValues: FormValues) => {
      // check if filterOptions exists in statistics data and use them
      // fallback to auto mapping from form values:
      for (const [key, value] of Object.entries(formValues ?? {})) {
        switch (key) {
          case FormFieldNameEnum.selectUseToGenerateWarmWater:
            dispatch(
              setFilterOption({
                name: 'filterOptionDrinkingWaterHeating',
                value: value === 'yes',
              })
            )

            //// maxPersonsDrinkingWater
            // single family
            if (
              value === 'yes' &&
              formValues[FormFieldNameEnum.selectBuildingType] === BuildingTypesKeysEnum.singleFamilyHouse
            ) {
              const numberOfPersons = parseInt(formValues[FormFieldNameEnum.warmWaterNumberOfPersonsPerBuilding])
              if (numberOfPersons > 0) {
                dispatch(
                  setFilterOption({
                    name: 'maxPersonsDrinkingWater',
                    value: numberOfPersons,
                  })
                )
              }
            }

            // multifamliy
            if (
              value === 'yes' &&
              formValues[FormFieldNameEnum.selectBuildingType] === BuildingTypesKeysEnum.multiFamilyHouse
            ) {
              const numberOfPersons = parseInt(formValues[FormFieldNameEnum.warmWaterNumberOfPersonsPerBuilding])
              const units = parseInt(formValues[FormFieldNameEnum.numberOfResidentialUnits] ?? 1)

              dispatch(
                setFilterOption({
                  name: 'maxPersonsDrinkingWater',
                  value: calculateWarmWaterHeatingPersons({
                    warmWaterNumberOfPersonsPerResidence: numberOfPersons,
                    numberOfResidentialUnits: units,
                  }),
                })
              )
            }
            break

          case FormFieldNameEnum.selectUseAreaCooling:
            dispatch(
              setFilterOption({
                name: 'filterOptionCooling',
                value: value === 'yes',
              })
            )
            break

          case FormFieldNameEnum.selectUseWarmWaterSolarHeatingSupport:
            if (value === 'yes' && formValues[FormFieldNameEnum.selectUseToGenerateWarmWater]) {
              dispatch(
                setFilterOption({
                  name: 'filterOptionAdditionalDrinkingWaterHeating',
                  value: value === 'yes',
                })
              )
            }
            break

          case FormFieldNameEnum.selectUseSolarHeatingSupport:
            dispatch(
              setFilterOption({
                name: 'filterOptionHeatingSupport',
                value: value === 'yes',
              })
            )
            break
        }
      }
    },
    [dispatch]
  )

  const doPrefill = useCallback(async () => {
    const run = async () => {
      if (!doPreFillWithId) {
        console.log('[+] PreFill: skipped')
        return false
      }
      const { data: preFillDataResponse, isError, error } = await trigger(doPreFillWithId)

      if (isError || !preFillDataResponse) {
        console.error('[-] PreFill: failed to get api result', error)
        return false
      }

      myForm.setFieldsValues(preFillDataResponse.preFillValues ?? {}, { keepPristine: false, keepUnmounted: true })

      await setFilterOptions(preFillDataResponse.preFillValues)

      return true
    }

    return new Promise((resolve, reject) => {
      run().then((result) => {
        if (result) {
          resolve('prefill finish')
        } else {
          reject('prefill failed')
        }
      })
    })
  }, [doPreFillWithId, myForm, trigger, setFilterOptions])

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (typeof myForm.steps === 'undefined' || myForm.steps.length < 1) {
      return
    }

    loadedSteps.current = myForm.steps

    // run only once!
    if (running) {
      return
    }

    setRunning(true)

    // open modal
    onOpen()

    // run logic
    doPrefill().then(() => {
      // first prefill is relevant to activate correct steps
      console.log('[+] PreFill: values injected completed - step 1')

      doVisiteAll().then(() => {
        console.log('[+] PreFill: visited all')

        doPrefill().then(() => {
          // second prefill is required, because values in conditional fields sometimes nicht set correct
          console.log('[+] PreFill: values injected completed - step 2')

          // this 250ms , avoid flickering values for 2nd doPrefill
          fakeDelay(250).then(() => onClose())
        })
      })
    })
  }, [myForm, dispatch, doPrefill, doVisiteAll, onClose, onOpen, running])

  return (
    <>
      <Modal isCentered isOpen={isOpen} closeOnOverlayClick={false} onClose={onClose} size={'xs'}>
        <ModalOverlay bg="brand.overlay" backdropFilter="blur(10px) hue-rotate(90deg)" />
        <ModalContent>
          <ModalHeader>
            <FormattedMessage id={'Formular wird ausgefüllt'} />
          </ModalHeader>
          <ModalBody>
            <Spinner thickness="4px" speed="0.75s" emptyColor="gray.200" color="blue.500" size="xl" />
          </ModalBody>
          <ModalFooter>
            <FormattedMessage id={'Bitte warten'} />
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default PreFiller
