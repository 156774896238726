import HeatPumpCharts from '@/features/heatPump/HeatPumpCharts'
import { checkPumpPackCanDoTemp } from '@/selector/heatPumpPackages'
import { HeatPumpInterface } from '@/store/features/heatPumpApiSlice'
import { BomListItemInterface, useLazyGetBomListByPumpPackageQuery } from '@/store/features/heatPumpPackageBomApiSlice'
import { HeatPumpPackageDetailsInterface } from '@/store/features/heatPumpPackagesDetailsApiSlice'
import { isDebugEnabled, isUserLoggedIn, pricesVisible } from '@/store/features/userDataSlice'
import { useAppSelector } from '@/store/store'
import { AddIcon, ArrowForwardIcon, MinusIcon } from '@chakra-ui/icons'
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Badge,
  Box,
  Button,
  ButtonGroup,
  Grid,
  GridItem,
  Heading,
  Image,
  Link,
  List,
  ListIcon,
  ListItem,
  Popover,
  PopoverContent,
  PopoverTrigger,
  SimpleGrid,
  Spinner,
  Table,
  Tbody,
  Td,
  Tr,
} from '@chakra-ui/react'
import parse from 'html-react-parser'
import { FormattedMessage, FormattedNumber } from 'react-intl'
import { HeatPumpPackageInterface } from '../../store/features/heatPumpPackagesApiSlice'

interface HeatPumpCardPropsInterface {
  heatPumpPack: HeatPumpPackageInterface
  heatPumpPackDetails: HeatPumpPackageDetailsInterface
  relevantPumps: HeatPumpInterface[]
  formattedPrice: string
  normTemp: number
  heatingLoad: number
  heatingLoadOriginal: number
}

export const HeatPumpPackCard = (property: HeatPumpCardPropsInterface) => {
  const { heatPumpPack, relevantPumps, heatPumpPackDetails, normTemp, heatingLoad, heatingLoadOriginal } = property
  const debugEnabled = useAppSelector(isDebugEnabled)
  const pricesVisibleFlag = useAppSelector(pricesVisible)
  const isUserLoggedInFlag = useAppSelector(isUserLoggedIn)

  const preHeatingTemperatures = (heatPumpPack: HeatPumpPackageInterface) => {
    const temps = [35, 45, 55, 70]
    const possible: string[] = []
    temps.forEach((temp) => {
      if (checkPumpPackCanDoTemp(heatPumpPack, temp, relevantPumps, heatingLoad, normTemp)) {
        possible.push(temp.toString())
      }
    })

    return possible.join('/')
  }

  const [trigger, result] = useLazyGetBomListByPumpPackageQuery()
  const link = 'https://www.oeg.net' + heatPumpPackDetails.links.normal + '?icp=wppk&em_src=wppk&em_cmp=wppk_package'

  //
  // useEffect(() => {
  //   // trigger load bom list
  //   if (result.isUninitialized) {
  //     trigger(heatPumpPack)
  //   }
  // }, [trigger, heatPumpPack, result])

  return (
    <Grid
      minHeight="200px"
      templateRows={'repeat(1, 1fr)'}
      templateColumns={'repeat(5, 1fr)'}
      gap={1}
      marginBottom={1}
      border={'1px'}
      borderColor={'brand.lgGray'}
      className={'heat-pump-pack-details'}
      data-testid={'heat-pump-pack-details-' + heatPumpPackDetails.external_id}
    >
      <GridItem rowSpan={2} colSpan={1} padding={1} className={'heat-pump-pack-details-image'}>
        {debugEnabled ? <Badge textAlign={'right'}>{heatPumpPackDetails.external_id}</Badge> : null}
        <Link href={link} target={'_blank'}>
          <Image src={heatPumpPackDetails.images.normal} alt={heatPumpPackDetails.title} />
        </Link>
      </GridItem>

      <GridItem colSpan={4} padding={1} className={'heat-pump-pack-details-title'}>
        <Heading textAlign={'left'} className={'text-left'} size={'md'}>
          <Link href={link} target={'_blank'}>
            {heatPumpPackDetails.title_html}
          </Link>
        </Heading>
      </GridItem>

      <GridItem colSpan={4} padding={1} className={'heat-pump-pack-details-properties'}>
        <Box as={'span'} textTransform={'uppercase'} fontWeight={'bold'}>
          <FormattedMessage id={'Eigenschaften'} />:
        </Box>
        <List spacing={0}>
          <ListItem>
            <ListIcon as={ArrowForwardIcon} color="brand.default" />
            <FormattedMessage id={'geeignete Vorlauftemperaturen'} />: {preHeatingTemperatures(heatPumpPack)} °C
          </ListItem>

          <ListItem>
            <ListIcon as={ArrowForwardIcon} color="brand.default" />
            <FormattedMessage id={'Aufstellung'} />:{' '}
            {heatPumpPack.filterOptionTankLyingDown ? (
              <FormattedMessage id={'liegend'} />
            ) : (
              <FormattedMessage id={'stehend'} />
            )}
          </ListItem>

          <ListItem>
            <ListIcon as={ArrowForwardIcon} color="brand.default" />
            <FormattedMessage id={'Platzbedarf'} />:{' '}
            {heatPumpPack.filterOptionMoreThanOneTank ? (
              <FormattedMessage id={'2 Speicher'} />
            ) : (
              <FormattedMessage id={'1 Speicher'} />
            )}
          </ListItem>
        </List>
      </GridItem>
      <GridItem
        as={Accordion}
        allowToggle
        colSpan={5}
        minH={'auto'}
        bg={'lightgray'}
        padding={1}
        className={'heat-pump-pack-details-accordion'}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        onChange={(index: number) => {
          // https://chakra-ui.com/docs/components/accordion/props
          if (index === 0) {
            //open === 0, close = -1
            if (result.isUninitialized) {
              trigger(heatPumpPack)
            }
          }
        }}
      >
        <AccordionItem>
          {({ isExpanded }) => {
            return (
              <>
                <h2>
                  <AccordionButton>
                    {pricesVisibleFlag ? (
                      <Box as="span" flex="1" textAlign="left" fontWeight={'bold'}>
                        <FormattedNumber
                          value={heatPumpPackDetails.prices.customer ?? heatPumpPackDetails.prices.catalog}
                          currency={heatPumpPackDetails.prices.currency}
                          maximumFractionDigits={2}
                          minimumFractionDigits={2}
                          currencySign={'standard'}
                          style={'currency'}
                        />
                        {!isUserLoggedInFlag ? '*' : ''}
                      </Box>
                    ) : null}
                    <Box as="span" flex="1" textAlign="right">
                      <ButtonGroup gap="2">
                        <Button as={'span'} colorScheme="brand" rounded={'none'} textTransform="uppercase">
                          <Link href={link} target={'_blank'}>
                            <ArrowForwardIcon /> <FormattedMessage id={'Zum Paket im OEG-Shop'} />
                          </Link>
                        </Button>
                        <Button as={'span'} colorScheme="brand" rounded={'none'} textTransform="uppercase">
                          {isExpanded ? <MinusIcon fontSize="12px" /> : <AddIcon fontSize="12px" />}
                          {'  '}
                          <FormattedMessage id={'Weitere Information'} />
                        </Button>
                      </ButtonGroup>
                    </Box>
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4} className={'heat-pump-pack-details-accordion-content'}>
                  <SimpleGrid columns={{ sm: 1, md: 2 }} spacing={2}>
                    <Box className={'heat-pump-pack-details-accordion-description'}>
                      {parse(heatPumpPackDetails.description ?? '')}
                      <Box className="heat-pump-pack-details-diagramm" mt={10}>
                        <Heading fontSize="xs">
                          <FormattedMessage id="Bivalenzpunkt Berechnung" />
                        </Heading>
                        <HeatPumpCharts
                          userInputNormTemp={normTemp}
                          userInputHeatingLoad={heatingLoadOriginal}
                          heatPumps={relevantPumps}
                          options={{
                            showOnlyPump: heatPumpPack.heatPumpExternalId,
                          }}
                        />
                      </Box>
                    </Box>
                    <Box className={'heat-pump-pack-details-bom-list'}>
                      {result.isSuccess ? (
                        <Table variant="unstyled" className={'bom-list'}>
                          <Tbody>
                            <Tr className={'bom-list-headline'}>
                              <Td colSpan={3}>
                                <Heading size={'xs'} className={'table-headline text-left'}>
                                  <FormattedMessage id={'Paketinhalt'} />
                                </Heading>
                              </Td>
                            </Tr>
                            {result.data?.map((bomItem: BomListItemInterface, index: number) => (
                              <Tr key={index} className={'bom-list-row'}>
                                <Td isNumeric>{bomItem.amount}x</Td>
                                <Td>
                                  <Popover trigger={'hover'} placement={'left'} isLazy={true}>
                                    <PopoverTrigger>
                                      <Image src={bomItem.images.thumb} alt={bomItem.title} />
                                    </PopoverTrigger>
                                    <PopoverContent>
                                      <Image
                                        boxSize="183px"
                                        objectFit="cover"
                                        src={bomItem.images.normal}
                                        alt={bomItem.title}
                                      />
                                    </PopoverContent>
                                  </Popover>
                                </Td>
                                <Td>
                                  <Link
                                    href={
                                      'https://www.oeg.net' +
                                      bomItem.links.normal +
                                      '?icp=wppk_bom&em_src=wppk&em_cmp=wppk_bom'
                                    }
                                    target={'_blank'}
                                  >
                                    {bomItem.title_html}
                                  </Link>
                                </Td>
                              </Tr>
                            ))}
                          </Tbody>
                        </Table>
                      ) : (
                        <Spinner />
                      )}
                    </Box>
                  </SimpleGrid>
                  {debugEnabled ? (
                    <Box bg={'lightgoldenrodyellow'}>
                      <Box as={'pre'}>{JSON.stringify(heatPumpPack, null, 2)}</Box>
                      <Box as={'pre'}>{JSON.stringify(heatPumpPackDetails, null, 2)}</Box>
                    </Box>
                  ) : null}
                </AccordionPanel>
              </>
            )
          }}
        </AccordionItem>
      </GridItem>
    </Grid>
  )
}
