import { CSSReset } from '@chakra-ui/react'
import { injectIntl } from 'react-intl'
import { IntlShape } from 'react-intl/src/types'
import ConfiguratorForm from './app/ConfiguratorForm'
import './assets/scss/style.scss'
import StepAdditionalInformation from './features/steps/StepAdditionalInformation'
import StepChooseAdditionalFunctionality from './features/steps/StepChooseAddionalFunctionality'
import StepChooseBuildingType from './features/steps/StepChooseBuildingType'
import StepChooseProjectType from './features/steps/StepChooseProjectType'
import StepHeatingLoad from './features/steps/StepHeatingLoad'
import StepSummary from './features/steps/StepSummary'
import StepWarmWaterProduction from './features/steps/StepWarmWaterProduction'
import StepHeatingLoadModernisation from './features/steps/modernisation/StepHeatingLoadModernisation'
import StepModernisationEnergySavingMeasures from './features/steps/modernisation/StepModernisationEnergySavingMeasures'
import StepWarmWaterModernisation from './features/steps/modernisation/StepWarmWaterModernisation'
import { FormFieldNameEnum } from '@/features/fields/FormFieldNameEnum'
import { StepMatrixInterface } from '@/features/layout/footer/StepperWrapper'

export enum StepNamesEnum {
  chooseConfiguratorMode = 'chooseConfiguratorMode',
  chooseBuildingType = 'chooseBuildingType',
  heatingLoadValues = 'heatingLoadValues',
  heatingLoadModernisation = 'heatingLoadModernisation',
  energySavingMeasures = 'energySavingMeasures',
  chooseAdditionalFunctionality = 'chooseAdditionalFunctionality',
  warmWaterProduction = 'warmWaterProduction',
  warmWaterProductionModernisation = 'warmWaterProductionModernisation',
  additionalInformation = 'additionalInformation',
  summary = 'summary',
}

const App = (props: { intl: IntlShape; doPreFillWithId?: number }) => {
  const { intl, doPreFillWithId } = props

  const stepMatrix: StepMatrixInterface = {
    0: {
      groupName: intl.formatMessage({ id: 'Projektart' }),
      stepNames: [StepNamesEnum.chooseConfiguratorMode, StepNamesEnum.chooseBuildingType],
      fieldNames: [FormFieldNameEnum.selectProjectType, FormFieldNameEnum.selectBuildingType],
    },
    1: {
      groupName: intl.formatMessage({ id: 'Heizlast' }),
      stepNames: [
        StepNamesEnum.heatingLoadValues,
        StepNamesEnum.heatingLoadModernisation,
        StepNamesEnum.energySavingMeasures,
      ],
      fieldNames: [
        FormFieldNameEnum.knownBuildingHeatingLoadValue,
        FormFieldNameEnum.heatingLoadSource,
        FormFieldNameEnum.habitableSpace,
        FormFieldNameEnum.energyEfficiency,
        FormFieldNameEnum.knownGasOrOilOrPowerConsumeValueIncludesWarmWater,
        FormFieldNameEnum.selectCurrentHeatingSystemType,
        FormFieldNameEnum.knownGasOrOilOrPowerConsumeValue,
        FormFieldNameEnum.energySavingCheckboxGroup,

        FormFieldNameEnum.buildingConstructionYear,

        FormFieldNameEnum.energySavingCheckboxGroup,
      ],
    },
    2: {
      groupName: intl.formatMessage({ id: 'Optionale Funktionen' }),
      stepNames: [StepNamesEnum.chooseAdditionalFunctionality],
      fieldNames: [FormFieldNameEnum.selectUseAreaCooling, FormFieldNameEnum.selectUseSolarHeatingSupport],
    },
    3: {
      groupName: intl.formatMessage({ id: 'Trinkwassererwärmung' }),
      stepNames: [StepNamesEnum.warmWaterProduction, StepNamesEnum.warmWaterProductionModernisation],
      fieldNames: [
        FormFieldNameEnum.selectUseToGenerateWarmWater,
        FormFieldNameEnum.selectUseWarmWaterSolarHeatingSupport,

        FormFieldNameEnum.warmWaterNumberOfPersonsPerBuilding,
        FormFieldNameEnum.warmWaterNumberOfPersonsPerResidence,

        FormFieldNameEnum.warmWaterNumberHeatingLoadQwwDay,
        FormFieldNameEnum.warmWaterNumberHeatingLoadQwwDayHours,

        FormFieldNameEnum.selectCurrentUseOfSolarToGenerateWarmWater,
      ],
    },
    4: {
      groupName: intl.formatMessage({ id: 'Weitere Angaben' }),
      stepNames: [StepNamesEnum.additionalInformation],
      fieldNames: [
        FormFieldNameEnum.selectUseToGenerateSolarEnergy,
        FormFieldNameEnum.selectUseEnergyWithTimeLocks,
        FormFieldNameEnum.knownNormTemp,

        FormFieldNameEnum.energyWithTimeLocksHours,
        FormFieldNameEnum.normTemp,
        FormFieldNameEnum.normTempFinder,
      ],
    },
    5: { groupName: intl.formatMessage({ id: 'Ergebnis' }), stepNames: [StepNamesEnum.summary], fieldNames: [] },
  }

  return (
    <>
      <CSSReset />
      <ConfiguratorForm stepMatrix={stepMatrix} doPreFillWithId={doPreFillWithId}>
        {/* G1 - projektart  */}
        <StepChooseProjectType
          name={StepNamesEnum.chooseConfiguratorMode}
          label={intl.formatMessage({ id: 'Projektart' })}
          isVisible={true}
        />
        <StepChooseBuildingType
          name={StepNamesEnum.chooseBuildingType}
          label={intl.formatMessage({ id: 'Gebäudeart' })}
          isVisible={true}
        />

        {/* G2 - heizlast */}
        <StepHeatingLoad name={StepNamesEnum.heatingLoadValues} intl={intl} />
        {/* StepHeatingLoadModernisation: only modernisation */}
        <StepHeatingLoadModernisation name={StepNamesEnum.heatingLoadModernisation} />
        {/* StepModernisationEnergySavingMeasures: only modernisation */}
        <StepModernisationEnergySavingMeasures name={StepNamesEnum.energySavingMeasures} />

        {/* G3 - optionale funktionen (solar/ac) */}
        <StepChooseAdditionalFunctionality name={StepNamesEnum.chooseAdditionalFunctionality} isVisible={true} />

        {/* G4 - warmwasser */}
        <StepWarmWaterProduction
          name={StepNamesEnum.warmWaterProduction}
          isVisible={true}
          stepMatrix={stepMatrix}
          stepGroup={3}
        />
        <StepWarmWaterModernisation name={StepNamesEnum.warmWaterProductionModernisation} />

        {/* G5 - weitere angaben */}
        <StepAdditionalInformation name={StepNamesEnum.additionalInformation} isVisible={true} />

        {/* G6 - summary */}
        <StepSummary name={StepNamesEnum.summary} intl={intl} stepMatrix={stepMatrix} />
      </ConfiguratorForm>
    </>
  )
}

export default injectIntl(App)
