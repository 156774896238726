import { UseFieldProps } from '@formiz/core/dist/types/field.types'
import { injectIntl } from 'react-intl'
import { IntlShape } from 'react-intl/src/types'
import { useLazyGetTemperaturesByZipQuery } from '../../store/features/standardTemperatureApiSlice'
import { SelectFieldAsync } from './SelectFieldAsync'

interface FilterOptions {
  readonly zip: string
  readonly city?: string
  readonly label?: string
  readonly id?: string
  readonly value?: string
  readonly className?: string | undefined
}

interface SelectStandardTemperaturePropsInterface extends UseFieldProps {
  intl: IntlShape
}

const SelectStandardTemperature = (props: SelectStandardTemperaturePropsInterface) => {
  const { intl, name } = props

  const [trigger] = useLazyGetTemperaturesByZipQuery()

  const loadOptions = (inputValue: string, callback: (options: object[]) => object[]) => {
    const getOptions = async (
      resolve: (value: object[]) => void,
      reject: (reason?: Error | string | { status: string; data?: undefined; error: string }) => void
    ) => {
      if (inputValue.length < 3) {
        return callback([])
      }

      const { data: optionsFromApi, isError, error } = await trigger(inputValue)

      if (isError && error) {
        return reject('query failed')
      }
      if (typeof optionsFromApi === 'undefined' || typeof optionsFromApi.entities !== 'object') {
        return reject('no data')
      }

      const foundOptions: FilterOptions[] = []
      optionsFromApi.ids.map((zip: number | string) => {
        foundOptions.push({
          className: 'select__option',
          label: (optionsFromApi.entities[zip]?.city ?? '') + ' (' + zip + ')',
          value: optionsFromApi.entities[zip]?.temperature.toString(),
          zip: zip.toString(),
        })
      })

      return resolve(callback(foundOptions))
    }

    return new Promise((resolve, reject) => getOptions(resolve, reject))
  }

  return (
    <SelectFieldAsync
      name={name}
      label={intl.formatMessage({ id: 'Auswahl der Normaußentemperatur über PLZ' })}
      helper={intl.formatMessage({
        id: 'Geben Sie Ihre Postleitzahl (nur Deutschland) ein, diese wird zur Berechnung der Heizlast genutzt',
      })}
      loadOptions={loadOptions}
      defaultOptions={true}
      placeholder={intl.formatMessage({ id: 'Geben Sie Ihre Postleitzahl ein' })}
      // value={value}
      required={intl.formatMessage({ id: 'Postleitzahl wird für die Berechnung der Heizlast benötigt' })}
    />
  )
}

export default injectIntl(SelectStandardTemperature)
