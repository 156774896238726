import { Checkbox, FormControl, FormLabel } from '@chakra-ui/react'
import React from 'react'

export const FilterCheckbox = ({
  name,
  onChangeCallback,
  label,
  disabled,
  checked,
}: {
  name: string
  label: string
  disabled?: boolean
  checked?: boolean
  onChangeCallback: (event: React.ChangeEvent<HTMLInputElement>) => void
}) => {
  return (
    <FormControl display="flex" alignItems="center" data-testid={name} className="filter__detail-box-checkbox">
      <Checkbox
        isChecked={checked ?? false}
        disabled={disabled ?? false}
        colorScheme="brand"
        title={label}
        size="md"
        id={name}
        onChange={onChangeCallback}
        style={{ marginRight: '5px' }}
      >
        <FormLabel htmlFor={name} mt="1" mb="0" color={'black'}>
          {label}
        </FormLabel>
      </Checkbox>
    </FormControl>
  )
}
