import { HeatingLoadBuildingResult } from '@/features/math/calculateBuildingHeatLoad'
import { calculateFactorOutsideTempCorrection } from '@/features/math/factor/calculateFactorOutsideTempCorrection'
import { calculateFactorSperr } from '@/features/math/factor/calculateFactorSperr'
import { getEnergySavingFactors } from '@/features/math/factor/getEnergySavingFactors'

interface PropertiesInterface {
  normTempOutside: number
  habitableSpace: number
  qSpez: number
  hoursDisabled: number
  qWW: number
  energySavingOptions: string[]
}

/**
 * 1) automatische Berechnung
 * 4) automatische Berechnung
 * 7) automatische Berechnung
 * 10) automatische Berechnung
 */
export const calculateHeatingLoadPumpByHabitableSpaceAndEnergyNeedAndWarmWater = ({
  habitableSpace,
  qSpez,
  energySavingOptions,
  normTempOutside,
  hoursDisabled,
  qWW, // kWh!
}: PropertiesInterface): HeatingLoadBuildingResult => {
  console.log('calculateHeatingLoadPumpByHabitableSpaceAndEnergyNeedAndWarmWater', {
    habitableSpace: habitableSpace,
    qSpez: qSpez,
    energySavingOptions: energySavingOptions,
    normTempOutside: normTempOutside,
    hoursDisabled: hoursDisabled,
    qWW: qWW,
  })

  const energySavingFactors = getEnergySavingFactors({ energySavingOptions, isMultiFamily: false })
  const fWindows = energySavingFactors.fWindows
  const fRoof = energySavingFactors.fRoof
  const fWalls = energySavingFactors.fWalls

  const fSperr = calculateFactorSperr({ hoursDisabled })

  const fATKorr = calculateFactorOutsideTempCorrection({ normTempOutside: normTempOutside })

  const resultQwp = (qWW * 1000 + qSpez * habitableSpace * fATKorr * fWindows * fRoof * fWalls * fSperr) / 1000

  return {
    kW: parseFloat(resultQwp.toFixed(3)),
  }
}
