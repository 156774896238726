import { calculateFactorSperr } from '../../factor/calculateFactorSperr'
import { calculateWarmWaterHeatingLoadNonResidential } from './../../warmWater/calculateWarmWaterHeatingLoadNonResidential'

interface HeatingLoadBuildingNonResidentialProps {
  // Warmwasserbereitung mit Wärmepumpe?
  // gebäudeheizlast
  qH_BuildingHeatingLoad: number
  // Eingabe des tägl. Energiebedarf für Warmwasser: qWWTag = 0 - 200 [kWh/Tag]
  qWWTag: number
  qWWTagHours: number
  // dauert der Sperrzeiten see https://www.westnetz.de/de/faq/waermepumpen.html
  hoursDisabled: number
}

export interface HeatingLoadNonResidentialBuildingResult {
  kW: number
  // fATKorr: number
}

export const calculateHeatingLoadNonResidentialWithWarmWater = ({
  qH_BuildingHeatingLoad: qH,
  qWWTag,
  qWWTagHours,
  hoursDisabled,
}: HeatingLoadBuildingNonResidentialProps): HeatingLoadNonResidentialBuildingResult => {
  let qWP = 0
  console.log('calculateHeatingLoadNonResidentialWithWarmWater', {
    qH: qH,
    qWWTag: qWWTag,
    qWWTagHours: qWWTagHours,
    hoursDisabled: hoursDisabled,
  })

  const fSperr = calculateFactorSperr({ hoursDisabled })

  // Ich kenne die Gebäudeheizlast
  // Eingabe: qH = 1-50[kW]
  if (!qH) {
    throw Error('needs buildingHeatingLoad (qH)')
  }

  if (!qWWTag) {
    throw Error('needs qWWTag (user input) to calculateHeatingLoadNonResidentialWithWarmWater')
  }

  const qWW = calculateWarmWaterHeatingLoadNonResidential({
    hoursActive: qWWTagHours,
    qWWTag: qWWTag,
  }).kWh

  qWP = (qH * 1000 * fSperr) / 1000 + qWW

  return {
    kW: parseFloat('' + qWP.toFixed(3)),
  }
}
