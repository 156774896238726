interface PropertiesInterface {
  knownGasConsume: number
  fWindows: number
  fRoof: number
  fWalls: number
  fSperr: number
  tVoll:
    | number
    | 2100 /* single with heating */
    | 1800 /* single without heating */
    | 2000 /* multi family with heating */
    | 1700 /* multi family without heating */
}

/**
 *  15) Ich kenne den Gasverbrauch m³ / 10,3kWh/m³ (500-30000 kWh/jahr)
 *      bei Erdgas Mittelwert = 10,3 kWh/m³ -> -15% Verlust  ηKessel= 0,85  und tvoll = 2100 h/a
 */
export const calculateLoadFromConsumedGas = ({
  knownGasConsume: consume,
  fWindows,
  fRoof,
  fWalls,
  fSperr,
  tVoll,
}: PropertiesInterface): { kW: number } => {
  const nKessel = 0.85
  const qWP = ((consume * nKessel) / tVoll) * fWindows * fRoof * fWalls * fSperr

  return {
    kW: parseFloat(qWP.toFixed(3)),
  }
}
