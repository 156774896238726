import classNames from 'classnames'
import { NextButton, Pagination, PrevButton } from 'react-headless-pagination'
import { ArrowLeftIcon, ArrowRightIcon } from '@chakra-ui/icons'
import { Button, IconButton } from '@chakra-ui/react'

interface PropsInterface {
  totalItemCount: number
  setCurrentPageCallback: (page: number) => void
  page: number
}

export const Paginator = (args: PropsInterface) => {
  const { totalItemCount, page, setCurrentPageCallback } = args

  const totalPageCount = () => {
    return Math.ceil(totalItemCount / 100) //@todo: maybe make items per page an option
  }

  return (
    <Pagination
      totalPages={totalPageCount()}
      middlePagesSiblingCount={1}
      currentPage={page - 1}
      setCurrentPage={(pageNumber: number) => setCurrentPageCallback(pageNumber + 1)}
      truncableText="..."
      truncableClassName="pg-spacer"
      edgePageCount={1}
      className={'paginator'}
    >
      <PrevButton
        as={
          <IconButton
            colorScheme={'brand'}
            style={{ float: 'left' }}
            aria-label={'back'}
            icon={<ArrowLeftIcon />}
            mr={3}
          />
        }
        className={classNames('pg-back', {
          'cursor-pointer': page !== 1,
          'pg-disabled': page <= 1,
        })}
      />

      <ul>
        <Pagination.PageButton
          as={<Button colorScheme={'brand'} mr={2} />}
          activeClassName="pg-active"
          inactiveClassName={'pg-inactive'}
        />
      </ul>

      <NextButton
        as={<IconButton colorScheme={'brand'} aria-label={'next'} icon={<ArrowRightIcon />} />}
        className={classNames('pg-next', {
          'cursor-pointer': page !== totalPageCount(),
          'pg-disabled': page === totalPageCount(),
        })}
      />
    </Pagination>
  )
}
