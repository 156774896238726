import { FindRelevantHeatPumps } from '@/features/heatPump/findRelevantHeatPumps'
import { HeatPumpSetsContent } from '@/features/heatPumpSet/HeatPumpSetsContent'
import StatisticUsageTracker, { formatTrackingData } from '@/features/statistic/StatisticUsageTracker'
import { HeatPumpInterface } from '@/store/features/heatPumpApiSlice'
import { useGetHeatPumpPackagesByPumpsQuery } from '@/store/features/heatPumpPackagesApiSlice'
import { setHeatPumpPackages } from '@/store/features/heatPumpPackagesSlice'
import { getFilterFlags } from '@/store/features/heatPumpSetFilterSlice'
import {
  getCurrencyCode,
  getCustomerId,
  getHeatingLoad,
  getLanguageCode,
  getNormTemperatur,
  getSessionId,
} from '@/store/features/userDataSlice'
import { useSendUsageStatsMutation } from '@/store/features/userStatsApiSlice'
import { useAppDispatch, useAppSelector } from '@/store/store'
import { CheckIcon } from '@chakra-ui/icons'
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  Grid,
  GridItem,
  Heading,
  Input,
  InputGroup,
  Spinner,
  Text,
} from '@chakra-ui/react'
import { useForm } from '@formiz/core'
import { isEmail } from '@formiz/validations'
import React, { useEffect, useState } from 'react'
import type { IntlShape } from 'react-intl'
import { FormattedMessage, injectIntl } from 'react-intl'

export const NoMatchesGridItem = () => {
  const [input, setInput] = useState('')
  const isCustomer = useAppSelector(getCustomerId) !== null
  const [isSentToOeg, setIsSentToOeg] = useState(false)
  const isError = !isEmail()(input)

  const myForm = useForm()

  const trackingData = formatTrackingData(
    useAppSelector(getSessionId),
    useAppSelector(getLanguageCode),
    useAppSelector(getCurrencyCode),
    useAppSelector(getCustomerId),
    'REQUEST',
    myForm,
    useAppSelector(getFilterFlags),
    [],
    useAppSelector(getNormTemperatur),
    useAppSelector(getHeatingLoad),
    !isError ? input : undefined
  )

  const [sendUsage, { isLoading, isSuccess }] = useSendUsageStatsMutation()

  const sendStats = (): void => {
    if (!isEmail()(input)) {
      return
    }
    sendUsage(trackingData)
    setIsSentToOeg(true)
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInput(e.target.value)
  }

  return (
    <GridItem key="no_matches_notice" colSpan={3}>
      <Box shadow="md">
        <Alert status="warning">
          <AlertIcon />
          <AlertTitle>
            <FormattedMessage id="Es konnten keine passenden Pakete gefunden werden" />
          </AlertTitle>
          <AlertDescription>
            <Text>
              <FormattedMessage id="Es tut uns leid, dass wir keine passenden Pakete finden konnten. Bitte lassen Sie uns wissen, wie wir Ihnen weiterhelfen können, indem Sie Kontakt mit unserem Vertrieb aufnehmen. Wir werden unser Bestes tun, um eine für Sie geeignete Lösung zu finden." />
              <br />
              {isCustomer ? (
                <Button
                  colorScheme={isSentToOeg ? 'green' : 'brand'}
                  onClick={() => {
                    sendStats()
                  }}
                  isDisabled={isSuccess || isSentToOeg}
                  isLoading={isLoading}
                  fontSize={'md'}
                  size={'md'}
                  borderRadius={'0px'}
                >
                  {isSentToOeg ? <CheckIcon /> : <FormattedMessage id={'Anfrage absenden'} />}
                </Button>
              ) : null}
            </Text>
          </AlertDescription>
        </Alert>
        {!isCustomer ? (
          <Box w="100%" p={4}>
            <FormControl isInvalid={isError}>
              <FormHelperText mb={2}>
                <FormattedMessage id="Bitte geben Sie eine gültige E-Mail-Adresse ein, damit wir Sie kontaktieren können" />
              </FormHelperText>
              <InputGroup>
                <Input
                  className={'reset-styles'}
                  type="email"
                  value={input}
                  onChange={handleInputChange}
                  placeholder={'contact@example.net'}
                  isDisabled={isSentToOeg}
                  size="sm"
                />
              </InputGroup>
              {isError ? (
                <FormErrorMessage>
                  <FormattedMessage id="Die angegebene E-Mail-Adresse ist ungültig" />
                </FormErrorMessage>
              ) : null}
            </FormControl>
            <Button
              colorScheme={isSentToOeg ? 'green' : 'brand'}
              mt={4}
              onClick={() => {
                if (!isError) {
                  sendStats()
                }
              }}
              isDisabled={isError || input === '' || isSentToOeg}
              isLoading={isLoading}
              fontSize={'md'}
              size={'md'}
              borderRadius={'0px'}
            >
              {isSentToOeg ? <CheckIcon /> : <FormattedMessage id={'Jetzt Anfrage stellen'} />}
            </Button>
          </Box>
        ) : null}
      </Box>
    </GridItem>
  )
}

const HeatPumpSetFilterDataProvider = ({ pumps, intl }: { pumps: HeatPumpInterface[]; intl: IntlShape }) => {
  const dispatch = useAppDispatch()

  const relevantPumps = FindRelevantHeatPumps({
    heatPumps: pumps,
  })

  const skipLoad = relevantPumps.length === 0
  const { data: apiResultHeatPumpPacks, isSuccess } = useGetHeatPumpPackagesByPumpsQuery(relevantPumps, {
    skip: skipLoad,
    refetchOnMountOrArgChange: true,
  })

  useEffect(() => {
    if (isSuccess) {
      dispatch(setHeatPumpPackages({ heatPumpPackages: apiResultHeatPumpPacks, relevantPumps: relevantPumps }))
    }
  }, [isSuccess, apiResultHeatPumpPacks, relevantPumps, dispatch])

  const content = []

  if (skipLoad) {
    // no heat-pumps
    content.push(<StatisticUsageTracker key="tracking" reason={'NO-PUMPS'} mountTracking={true} />)
    content.push(<NoMatchesGridItem key="no_matches_grid_item" />)
  } else {
    if (!isSuccess) {
      // loading, fetching bla
      content.push(
        <GridItem key="packages_loader" area="content" colSpan={3}>
          <Spinner />
        </GridItem>
      )
    } else if (apiResultHeatPumpPacks.length === 0) {
      // success but empty
      content.push(<StatisticUsageTracker key="tracking" reason={'NO-PACKAGES'} mountTracking={true} />)
      content.push(<NoMatchesGridItem key="no_matches_grid_item" />)
    }
  }

  return (
    <Grid
      templateAreas={`"header header"
                  "nav main"
                  "nav footer"`}
      gridTemplateRows={'auto 1fr'}
      gridTemplateColumns={'250px 1fr'}
      h="auto"
      gap="1"
      className="heat-pump-summary-wrapper"
    >
      <GridItem key={'header'} area={'header'} /* bg="blue.300"*/>
        <Heading size={'2xl'} padding={'16px'} color={'brand.subHeadline'} textAlign={'center'}>
          <FormattedMessage id="Empfohlene Pakete" />
        </Heading>
      </GridItem>
      {content.length > 0 ? content : <HeatPumpSetsContent intl={intl} />}
    </Grid>
  )
}

export default injectIntl(HeatPumpSetFilterDataProvider)
