import { HeatingLoadBuildingResult } from '../../../calculateBuildingHeatLoad'
import { calculateFactorSperr } from '../../../factor/calculateFactorSperr'
import { calculateLoadFromConsumedGas } from '../../../fromKnownConsume/calculateLoadFromConsumedGas'
import { calculateLoadFromConsumedOil } from '../../../fromKnownConsume/calculateLoadFromConsumedOil'
import { calculateLoadFromConsumedWp } from '../../../fromKnownConsume/calculateLoadFromConsumedWp'
import { getEnergySavingFactors } from './../../../factor/getEnergySavingFactors'

interface PropertiesInterface {
  normTempOutside: number
  energySavingOptions: string[]
  hoursDisabled: number
  currentHeaterType?: 'gas' | 'oil' | 'wp' | 'other'
  qH?: number
  knownGasOrOilOrPowerConsume?: number
}

export const calculateHeatingLoadPumpModernisationSingleFamily = ({
  normTempOutside,
  energySavingOptions,
  hoursDisabled,
  currentHeaterType,
  qH,
  knownGasOrOilOrPowerConsume,
}: PropertiesInterface): HeatingLoadBuildingResult => {
  console.log('calculateHeatingLoadPumpModernisationSingleFamily', {
    normTempOutside: normTempOutside,
    energySavingOptions: energySavingOptions,
    hoursDisabled: hoursDisabled,
    currentHeaterType: currentHeaterType,
    qH: qH,
    knownGasOrOilOrPowerConsume: knownGasOrOilOrPowerConsume,
  })
  // dämmung
  const energySavingFactors = getEnergySavingFactors({ energySavingOptions, isMultiFamily: false })
  const fWindows = energySavingFactors.fWindows
  const fRoof = energySavingFactors.fRoof
  const fWalls = energySavingFactors.fWalls

  const fSperr = calculateFactorSperr({ hoursDisabled })

  let resultQwp = 0

  // 14) kunde kennt gebäudeheizlast (qH) (1-50kW)
  // 17) kunde kennt gebäudeheizlast (qH) (1-50kW)
  // 20) kunde kennt gebäudeheizlast (qH) (1-50kW)
  // 23) kunde kennt gebäudeheizlast (qH) (1-50kW)
  if (qH && !knownGasOrOilOrPowerConsume) {
    resultQwp = qH * fWindows * fRoof * fWalls * fSperr
  }

  if ('gas' === currentHeaterType) {
    // 15) Ich kenne den Gasverbrauch m³ / 10,3kWh/m³ (500-30000 kWh/jahr)
    // bei Erdgas Mittelwert = 10,3 kWh/m³ -> -15% Verlust  ηKessel= 0,85  und tvoll = 2100 h/a
    if (knownGasOrOilOrPowerConsume) {
      resultQwp = calculateLoadFromConsumedGas({
        knownGasConsume: knownGasOrOilOrPowerConsume,
        fRoof: fRoof,
        fWalls: fWalls,
        fWindows: fWindows,
        fSperr: fSperr,
        tVoll: 1800,
      }).kW
    }
  }

  if ('oil' === currentHeaterType) {
    // 18)  Ich kenne den Ölverbrauch (500 - 8000 Liter/Jahr)
    // bei Heizöl EL = 10 kWh/l -> -15% Verlust ηKessel = 0,85 und tvoll = 2100 h/a
    if (knownGasOrOilOrPowerConsume) {
      resultQwp = calculateLoadFromConsumedOil({
        knownOilConsume: knownGasOrOilOrPowerConsume,
        fRoof: fRoof,
        fWalls: fWalls,
        fWindows: fWindows,
        fSperr: fSperr,
        tVoll: 1800,
      }).kW
    }
  }

  if ('wp' === currentHeaterType) {
    // 21) Ich kenne den Wärmepumpen - Stromverbrauch 500 - 25000 kWh/Jahr
    if (knownGasOrOilOrPowerConsume) {
      resultQwp = calculateLoadFromConsumedWp({ knownWpConsume: knownGasOrOilOrPowerConsume, tVoll: 1800 }).kW
    }
  }

  if ('other' === currentHeaterType) {
    // 24) Ich kenne den Wärmeenergieverbrauch - 500 - 80000 kWh/Jahr
    if (knownGasOrOilOrPowerConsume) {
      resultQwp = calculateLoadFromConsumedWp({ knownWpConsume: knownGasOrOilOrPowerConsume, tVoll: 1800 }).kW
    }
  }

  return {
    kW: parseFloat(resultQwp.toFixed(3)),
  }
}
