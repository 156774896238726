import StatsDetailsModal from '@/features/dashboard/StatsDetailsModal'
import { UserStatsInterface } from '@/store/features/userStatsApiSlice'
import { Switch, Table, TableContainer, Tbody, Td, Th, Thead, Tr, useBoolean } from '@chakra-ui/react'
import { FormattedDate, FormattedTime } from 'react-intl'

export const StatsTableContainer = ({ userStatsData }: { userStatsData: UserStatsInterface[] }) => {
  const [flagFilterOeg, setFlagFilterOeg] = useBoolean(true)

  return (
    <TableContainer>
      Nutzer mit OEG IP Anzeigen? <Switch id="filter-oeg" onChange={setFlagFilterOeg.toggle} />
      <Table variant="striped">
        <Thead>
          <Tr>
            <Th>Datum/Uhrzeit</Th>
            <Th>Sitzung</Th>
            <Th>Kundennummer</Th>
            <Th>Sprache</Th>
            <Th>Infos</Th>
            <Th></Th>
          </Tr>
        </Thead>
        <Tbody>
          {userStatsData &&
            userStatsData.map((userStat: UserStatsInterface, index: number) => {
              const resultData = userStat.formValues[2] ?? []

              // filter OEG/LAN ips
              if (flagFilterOeg && userStat.ip.startsWith('192.')) {
                // lan ip
                return null
              }
              return (
                <Tr key={index}>
                  <Td>
                    <FormattedDate value={userStat.created} /> <FormattedTime value={userStat.created} />
                  </Td>
                  <Td>
                    {userStat.sessionId} [{userStat.ip}]
                  </Td>
                  <Td>{userStat.customerId}</Td>
                  <Td>{userStat.languageCode}</Td>
                  <Td>
                    Heizlast: {resultData?.heatingLoad ?? ''}
                    <br />
                    Norm Temperatur: {resultData?.normTemperatur ?? ''}
                    <br />
                    Pakete: {resultData?.packageProductIds?.length ?? ''}
                  </Td>
                  <Td>
                    <StatsDetailsModal key={index} userStats={userStat} />
                  </Td>
                </Tr>
              )
            })}
        </Tbody>
      </Table>
    </TableContainer>
  )
}
