/// <reference types="vite-svg-loader" />
import { FormFieldNameEnum } from '@/features/fields/FormFieldNameEnum'
import { addFormGroup } from '@/store/features/formGroupsSlice'
import { useAppDispatch } from '@/store/store'
import { FormizStep, useForm } from '@formiz/core'
import { UseFieldProps } from '@formiz/core/dist/types/field.types'
import { useEffect } from 'react'
import { injectIntl } from 'react-intl'
import { IntlShape } from 'react-intl/src/types'
import IconNo from '../../../assets/icons/oeg-konfigurator-icon-nein.svg'
import IconYesSolar from '../../../assets/icons/oeg-konfigurator-icon-zusaetzlich.svg'
import { SelectSingleOptionWithRadioCards } from '../../fields/SelectSingleOptionWithRadioCards'
import { BuildingTypesKeysEnum } from '../StepChooseBuildingType'
import { ConfiguratorModesKeysEnum } from '../StepChooseProjectType'

interface StepWarmWaterModernisationPropsInterface extends UseFieldProps {
  intl: IntlShape
}

const StepWarmWaterModernisation = (props: StepWarmWaterModernisationPropsInterface) => {
  const { name, intl } = props
  const label = intl.formatMessage({ id: 'Bisherige Trinkwassererwärmung' })
  const myForm = useForm()

  const isVisible =
    myForm.flatValues[FormFieldNameEnum.selectBuildingType] !== BuildingTypesKeysEnum.nonResidentialBuilding &&
    myForm.flatValues[FormFieldNameEnum.selectProjectType] === ConfiguratorModesKeysEnum.modernisation &&
    myForm.flatValues[FormFieldNameEnum.selectUseToGenerateWarmWater] === 'yes'

  const dispatch = useAppDispatch()
  useEffect(() => {
    if (isVisible) {
      dispatch(
        addFormGroup({
          name: name,
          label: label,
          fields: [FormFieldNameEnum.selectCurrentUseOfSolarToGenerateWarmWater],
        })
      )
    }
  }, [dispatch, isVisible, label, name])

  return (
    <FormizStep name={name} label={label} isEnabled={isVisible} data-testid={name}>
      <SelectSingleOptionWithRadioCards
        label={intl.formatMessage({ id: 'Wird zurzeit bereits Solarthermie genutzt?' })}
        required={intl.formatMessage({ id: 'Bitte wählen' })}
        autoSubmitStep={true}
        name={FormFieldNameEnum.selectCurrentUseOfSolarToGenerateWarmWater}
        options={[
          {
            imageComponent: IconYesSolar,
            value: 'yes',
            label: intl.formatMessage({ id: 'Ja' }),
          },
          {
            imageComponent: IconNo,
            value: 'no',
            label: intl.formatMessage({ id: 'Nein' }),
          },
        ]}
      />
    </FormizStep>
  )
}

export default injectIntl(StepWarmWaterModernisation)
