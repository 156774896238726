import { FormFieldNameEnum } from '@/features/fields/FormFieldNameEnum'
import { extractHeatingLoadQSpezFromFormValues } from '@/features/heatingLoad/ExtractHeatingLoadQSpezFromFormValues'
import { formValuesHelper } from '@/features/helper/formValuesHelper'
import { calculateHeatingLoadKnownLoadModernisationMultiFamily } from '@/features/math/heatingLoadPump/mutliFamily/modernisation/calculateHeatingLoadKnownLoadModernisationMultiFamily'
import { calculateHeatingLoadKnownLoadModernisationMultiFamilyWithWarmWater } from '@/features/math/heatingLoadPump/mutliFamily/modernisation/calculateHeatingLoadKnownLoadModernisationMultiFamilyWithWarmWater'
import { calculateHeatingLoadPumpModernisationKnownConsumeMultiFamily } from '@/features/math/heatingLoadPump/mutliFamily/modernisation/calculateHeatingLoadPumpModernisationKnownConsumeMultiFamily'
import { calculateHeatingLoadPumpModernisationKnownConsumeMultiFamilyWithWarmWater } from '@/features/math/heatingLoadPump/mutliFamily/modernisation/calculateHeatingLoadPumpModernisationKnownConsumeMultiFamilyWithWarmWater'
import { calculateHeatLoadNoKnownMultiFamily } from '@/features/math/heatingLoadPump/mutliFamily/newBuilding/calculateHeatLoadNoKnownMultiFamily'
import { calculateHeatLoadNoKnownMultiFamilyWithWarmWater } from '@/features/math/heatingLoadPump/mutliFamily/newBuilding/calculateHeatLoadNoKnownMultiFamilyWithWarmWater'
import { calculateHeatingLoadKnownLoadMultiFamily } from '@/features/math/heatingLoadPump/mutliFamily/newBuilding/calculateHeatingLoadKnownLoadMultiFamily'
import { calculateHeatingLoadKnownLoadMultiFamilyWithWarmWater } from '@/features/math/heatingLoadPump/mutliFamily/newBuilding/calculateHeatingLoadKnownLoadMultiFamilyWithWarmWater'
import { calculateHeatingLoadNonResidentialWithWarmWater } from '@/features/math/heatingLoadPump/nonResidential/calculateHeatingLoadNonResidentialWithWarmWater'
import { calculateHeatingLoadPumpByHabitableSpaceAndEnergyNeed } from '@/features/math/heatingLoadPump/singleFamily/modernisation/calculateHeatingLoadPumpByHabitableSpaceAndEnergyNeed'
import { calculateHeatingLoadPumpByHabitableSpaceAndEnergyNeedAndWarmWater } from '@/features/math/heatingLoadPump/singleFamily/modernisation/calculateHeatingLoadPumpByHabitableSpaceAndEnergyNeedAndWarmWater'
import { calculateHeatingLoadPumpModernisationSingleFamilyWithWarmWaterFromKnownConsume } from '@/features/math/heatingLoadPump/singleFamily/modernisation/calculateHeatingLoadPumpModernisationSingleFamilyWithWarmWaterFromKnownConsume'
import { calculateHeatLoadNoKnownSingleFamily } from '@/features/math/heatingLoadPump/singleFamily/newBuilding/calculateHeatLoadNoKnownSingleFamily'
import { calculateHeatLoadNoKnownSingleFamilyWithWarmWater } from '@/features/math/heatingLoadPump/singleFamily/newBuilding/calculateHeatLoadNoKnownSingleFamilyWithWarmWater'
import { calculateHeatingLoadKnownLoadSingleFamily } from '@/features/math/heatingLoadPump/singleFamily/newBuilding/calculateHeatingLoadKnownLoadSingleFamily'
import { calculateHeatingLoadKnownLoadSingleFamilyWithWarmWater } from '@/features/math/heatingLoadPump/singleFamily/newBuilding/calculateHeatingLoadKnownLoadSingleFamilyWithWarmWater'
import { calculateWarmWaterHeatingLoadMultiFamilyHouse } from '@/features/math/warmWater/calculateWarmWaterHeatingLoadMultiFamilyHouse'
import { calculateWarmWaterHeatingLoadSingleFamilyHouse } from '@/features/math/warmWater/calculateWarmWaterHeatingLoadSingleFamilyHouse'
import { UseFormValues } from '@formiz/core/dist/types/form.types'
import type { IntlShape } from 'react-intl'
import { calculateHeatingLoadNonResidential } from '../math/heatingLoadPump/nonResidential/calculateHeatingLoadNonResidential'
import { calculateHeatingLoadPumpModernisationSingleFamily } from '../math/heatingLoadPump/singleFamily/modernisation/calculateHeatingLoadPumpModernisationSingleFamily'
import { calculateHeatingLoadPumpModernisationSingleFamilyWithWarmWater } from '../math/heatingLoadPump/singleFamily/modernisation/calculateHeatingLoadPumpModernisationSingleFamilyWithWarmWater'

export const calculateHeatingLoadFromFormValues = ({
  myForm,
  normTempOutside,
  intl,
}: {
  normTempOutside: number
  myForm: UseFormValues
  intl: IntlShape
}): number => {
  const invalidateFieldAndSendUserToStep = (fieldName: string, consoleError: string): number => {
    console.error(consoleError)
    const errorFields: { [key: string]: string } = {}
    errorFields[fieldName] = intl.formatMessage({ id: 'Bitte korrigieren Sie die Eingabe' })
    myForm.invalidateFields(errorFields)
    const stepWithError = myForm.getFieldStepName(fieldName)
    if (stepWithError) {
      myForm.goToStep(stepWithError)
    }

    return -1
  }

  const {
    currentHeaterTypeValue,
    energySavingCheckboxGroup,
    habitableSpaceValue,
    hoursDisabled,
    isBuildingMultiFamily,
    isBuildingSingleFamily,
    isBuildingTypeNonResidentialBuilding,
    isHeatPumpGenerateWarmWater,
    isModeModernisation,
    isModeRebuild,
    isSourceHeatingLoadByConsumption,
    isSourceHeatingLoadCalculation,
    isSourceHeatingLoadIsKnown,
    isWithSolar,
    knownGasOrOilOrPowerConsume,
    numberOfPersonsPerBuilding,
    qH,
    totalNumberOfPersons,
    numberOfResidentialUnits,
    numberOfPersonsPerResidentialUnit,
    warmWaterNumberOfPersonsPerResidence,
    warmWaterNumberHeatingLoadQwwDay,
    warmWaterNumberHeatingLoadQwwDayHours,
  } = formValuesHelper({ myForm })

  const qSpezValue = extractHeatingLoadQSpezFromFormValues({ form: myForm })

  console.debug({
    qSpezValue: qSpezValue,
    currentHeaterTypeValue: currentHeaterTypeValue,
    energySavingCheckboxGroup: energySavingCheckboxGroup,
    habitableSpaceValue: habitableSpaceValue,
    hoursDisabled: hoursDisabled,
    isBuildingMultiFamily: isBuildingMultiFamily,
    isBuildingSingleFamily: isBuildingSingleFamily,
    isBuildingTypeNonResidentialBuilding: isBuildingTypeNonResidentialBuilding,
    isHeatPumpGenerateWarmWater: isHeatPumpGenerateWarmWater,
    isModeModernisation: isModeModernisation,
    isModeRebuild: isModeRebuild,
    isSourceHeatingLoadByConsumption: isSourceHeatingLoadByConsumption,
    isSourceHeatingLoadCalculation: isSourceHeatingLoadCalculation,
    isSourceHeatingLoadIsKnown: isSourceHeatingLoadIsKnown,
    isWithSolar: isWithSolar,
    knownGasOrOilOrPowerConsume: knownGasOrOilOrPowerConsume,
    numberOfPersonsPerBuilding: numberOfPersonsPerBuilding,
    qH: qH,
    totalNumberOfPersons: totalNumberOfPersons,
    numberOfResidentialUnits: numberOfResidentialUnits,
    numberOfPersonsPerResidentialUnit: numberOfPersonsPerResidentialUnit,
    warmWaterNumberHeatingLoadQwwDay: warmWaterNumberHeatingLoadQwwDay,
    warmWaterNumberHeatingLoadQwwDayHours: warmWaterNumberHeatingLoadQwwDayHours,
    warmWaterNumberOfPersonsPerResidence: warmWaterNumberOfPersonsPerResidence,
  })

  // single family
  if (isBuildingSingleFamily && isModeModernisation && isSourceHeatingLoadCalculation) {
    if (!qSpezValue || qSpezValue < 1) {
      return invalidateFieldAndSendUserToStep(
        FormFieldNameEnum.buildingConstructionYear,
        'this needs qSpezValue! case: isBuildingSingleFamily && isModeModernisation && isSourceHeatingLoadCalculation - sending user to step'
      )
    }

    if (!habitableSpaceValue) {
      return invalidateFieldAndSendUserToStep(
        FormFieldNameEnum.habitableSpace,
        'this needs habitableSpaceValue! case: isBuildingSingleFamily && isModeModernisation && isSourceHeatingLoadCalculation - sending user to step'
      )
    }

    if (isHeatPumpGenerateWarmWater) {
      return calculateHeatingLoadPumpByHabitableSpaceAndEnergyNeedAndWarmWater({
        habitableSpace: habitableSpaceValue,
        qSpez: qSpezValue,
        normTempOutside: normTempOutside,
        hoursDisabled: hoursDisabled,
        energySavingOptions: energySavingCheckboxGroup,
        qWW: calculateWarmWaterHeatingLoadSingleFamilyHouse({
          warmWaterNumberOfPersonsPerBuilding: numberOfPersonsPerBuilding,
          isWithSolar: isWithSolar,
        }).kW,
      }).kW
    } else {
      return calculateHeatingLoadPumpByHabitableSpaceAndEnergyNeed({
        habitableSpace: habitableSpaceValue,
        qSpez: qSpezValue,
        normTempOutside: normTempOutside,
        hoursDisabled: hoursDisabled,
        energySavingOptions: energySavingCheckboxGroup,
      }).kW
    }
  }

  if (isBuildingSingleFamily && isModeModernisation && isSourceHeatingLoadIsKnown) {
    if (isHeatPumpGenerateWarmWater) {
      // with warm water
      if ((typeof qH === 'undefined' || qH < 1) && !currentHeaterTypeValue) {
        return invalidateFieldAndSendUserToStep(
          FormFieldNameEnum.selectCurrentHeatingSystemType,
          'this needs currentHeaterTypeValue! case: isBuildingSingleFamily && isModeModernisation && isSourceHeatingLoadIsKnown - sending user to step'
        )
      }

      return calculateHeatingLoadPumpModernisationSingleFamilyWithWarmWater({
        hoursDisabled: hoursDisabled,
        currentHeaterType: currentHeaterTypeValue,
        energySavingOptions: energySavingCheckboxGroup,
        normTempOutside: normTempOutside,
        totalNumberOfPersons: numberOfPersonsPerBuilding,
        qH: qH,
        isHeatPumpGenerateWarmWater: isHeatPumpGenerateWarmWater,
      }).kW
    } else {
      // no warm water
      return calculateHeatingLoadPumpModernisationSingleFamily({
        hoursDisabled: hoursDisabled,
        currentHeaterType: currentHeaterTypeValue,
        energySavingOptions: energySavingCheckboxGroup,
        normTempOutside: normTempOutside,
        knownGasOrOilOrPowerConsume: knownGasOrOilOrPowerConsume,
        qH: qH /*parseFloat(myForm.flatValues.knownBuildingHeatingLoadValue),*/,
      }).kW
    }
  }

  if (isBuildingSingleFamily && isModeModernisation && isSourceHeatingLoadByConsumption) {
    if (!knownGasOrOilOrPowerConsume) {
      return invalidateFieldAndSendUserToStep(
        FormFieldNameEnum.knownGasOrOilOrPowerConsumeValue,
        'this needs knownGasOrOilOrPowerConsume! case: isBuildingSingleFamily && isModeModernisation && isSourceHeatingLoadByConsumption - send user to step'
      )
    }
    if (!currentHeaterTypeValue) {
      return invalidateFieldAndSendUserToStep(
        FormFieldNameEnum.selectCurrentHeatingSystemType,
        'this needs currentHeaterTypeValue! case: isBuildingSingleFamily && isModeModernisation && isSourceHeatingLoadByConsumption - send user to step'
      )
    }
    if (isHeatPumpGenerateWarmWater) {
      // with warm water
      return calculateHeatingLoadPumpModernisationSingleFamilyWithWarmWaterFromKnownConsume({
        hoursDisabled: hoursDisabled,
        currentHeaterType: currentHeaterTypeValue,
        energySavingOptions: energySavingCheckboxGroup,
        normTempOutside: normTempOutside,
        knownGasOrOilOrPowerConsume: knownGasOrOilOrPowerConsume,
        qWW: calculateWarmWaterHeatingLoadSingleFamilyHouse({
          warmWaterNumberOfPersonsPerBuilding: totalNumberOfPersons,
          isWithSolar: isWithSolar,
        }).kW,
      }).kW
    } else {
      // no warm water
      return calculateHeatingLoadPumpModernisationSingleFamily({
        hoursDisabled: hoursDisabled,
        currentHeaterType: currentHeaterTypeValue,
        energySavingOptions: energySavingCheckboxGroup,
        normTempOutside: normTempOutside,
        knownGasOrOilOrPowerConsume: knownGasOrOilOrPowerConsume,
      }).kW
    }
  }

  if (isBuildingSingleFamily && isModeRebuild && isSourceHeatingLoadCalculation) {
    if (!qSpezValue || qSpezValue < 1) {
      return invalidateFieldAndSendUserToStep(
        FormFieldNameEnum.buildingConstructionYear,
        'this needs qSpezValue! case: isBuildingSingleFamily && isModeRebuild && isSourceHeatingLoadCalculation - sending user to step'
      )
    }

    if (!habitableSpaceValue || habitableSpaceValue < 1) {
      return invalidateFieldAndSendUserToStep(
        FormFieldNameEnum.habitableSpace,
        'this needs habitableSpaceValue! case: isBuildingSingleFamily && isModeRebuild && isSourceHeatingLoadCalculation - sending user to step'
      )
    }

    if (isHeatPumpGenerateWarmWater) {
      return calculateHeatLoadNoKnownSingleFamilyWithWarmWater({
        normTempOutside: normTempOutside, // °C
        hoursDisabled: hoursDisabled,
        qSpez: qSpezValue, // W/m²
        habitableSpace: habitableSpaceValue, // m²
        qWW: calculateWarmWaterHeatingLoadSingleFamilyHouse({
          warmWaterNumberOfPersonsPerBuilding: totalNumberOfPersons,
          isWithSolar: isWithSolar,
        }).kW,
      }).kW
    } else {
      return calculateHeatLoadNoKnownSingleFamily({
        normTempOutside: normTempOutside, // °C
        hoursDisabled: hoursDisabled,
        qSpez: qSpezValue, // W/m²
        habitableSpace: habitableSpaceValue, // m²
      }).kW
    }
  }

  if (isBuildingSingleFamily && isModeRebuild && isSourceHeatingLoadIsKnown) {
    if (typeof qH === 'undefined' || qH < 1) {
      return invalidateFieldAndSendUserToStep(
        FormFieldNameEnum.knownBuildingHeatingLoadValue,
        'this needs qH! case: isBuildingSingleFamily && isModeRebuild && isSourceHeatingLoadIsKnown - sending user to step'
      )
    }

    if (isHeatPumpGenerateWarmWater) {
      return calculateHeatingLoadKnownLoadSingleFamilyWithWarmWater({
        hoursDisabled: hoursDisabled,
        qH_BuildingHeatingLoad: qH,
        qWW: calculateWarmWaterHeatingLoadSingleFamilyHouse({
          warmWaterNumberOfPersonsPerBuilding: numberOfPersonsPerBuilding,
          isWithSolar: isWithSolar,
        }).kW,
      }).kW
    } else {
      return calculateHeatingLoadKnownLoadSingleFamily({
        hoursDisabled: hoursDisabled,
        qH_BuildingHeatingLoad: qH,
      }).kW
    }
  }

  // multi family
  if (isBuildingMultiFamily && isModeModernisation && isSourceHeatingLoadCalculation) {
    if (typeof qSpezValue === 'undefined' || qSpezValue < 1) {
      return invalidateFieldAndSendUserToStep(
        FormFieldNameEnum.buildingConstructionYear,
        'this needs qSpezValue! case: isBuildingMultiFamily && isModeModernisation && isSourceHeatingLoadCalculation - sending user to step'
      )
    }
    if (!habitableSpaceValue || habitableSpaceValue < 1) {
      return invalidateFieldAndSendUserToStep(
        FormFieldNameEnum.habitableSpace,
        'this needs habitableSpaceValue! case: isBuildingMultiFamily && isModeModernisation && isSourceHeatingLoadCalculation - sending user to step'
      )
    }

    if (isHeatPumpGenerateWarmWater) {
      if (typeof numberOfResidentialUnits === 'undefined' || numberOfResidentialUnits < 1) {
        return invalidateFieldAndSendUserToStep(
          FormFieldNameEnum.numberOfResidentialUnits,
          'this needs numberOfResidentialUnits! case: isBuildingMultiFamily && isModeModernisation && isSourceHeatingLoadCalculation'
        )
      }
      if (typeof warmWaterNumberOfPersonsPerResidence === 'undefined' || warmWaterNumberOfPersonsPerResidence < 1) {
        return invalidateFieldAndSendUserToStep(
          FormFieldNameEnum.warmWaterNumberOfPersonsPerResidence,
          'this needs warmWaterNumberOfPersonsPerResidence! case: isBuildingMultiFamily && isModeModernisation && isSourceHeatingLoadCalculation'
        )
      }
      return calculateHeatLoadNoKnownMultiFamilyWithWarmWater({
        normTempOutside: normTempOutside, // °C
        hoursDisabled: hoursDisabled,
        qSpez: qSpezValue, // W/m²
        habitableSpace: habitableSpaceValue, // m²
        qWW: calculateWarmWaterHeatingLoadMultiFamilyHouse({
          numberOfResidentialUnits: numberOfResidentialUnits,
          warmWaterNumberOfPersonsPerResidence: warmWaterNumberOfPersonsPerResidence,
          isWithSolar: isWithSolar,
        }).kW,
      }).kW
    } else {
      return calculateHeatLoadNoKnownMultiFamily({
        normTempOutside: normTempOutside, // °C
        hoursDisabled: hoursDisabled,
        qSpez: qSpezValue, // W/m²
        habitableSpace: habitableSpaceValue, // m²
      }).kW
    }
  }

  if (isBuildingMultiFamily && isModeModernisation && isSourceHeatingLoadIsKnown) {
    if (typeof qH === 'undefined' || qH < 1) {
      return invalidateFieldAndSendUserToStep(
        FormFieldNameEnum.knownBuildingHeatingLoadValue,
        'this needs qH! case: isBuildingMultiFamily && isModeModernisation && isSourceHeatingLoadIsKnown - sending user to step'
      )
    }

    if (isHeatPumpGenerateWarmWater) {
      if (typeof numberOfResidentialUnits === 'undefined' || numberOfResidentialUnits < 1) {
        return invalidateFieldAndSendUserToStep(
          FormFieldNameEnum.numberOfResidentialUnits,
          'this needs numberOfResidentialUnits! case: isBuildingMultiFamily && isModeModernisation && isSourceHeatingLoadIsKnown with heating'
        )
      }
      if (typeof warmWaterNumberOfPersonsPerResidence === 'undefined' || warmWaterNumberOfPersonsPerResidence < 1) {
        return invalidateFieldAndSendUserToStep(
          FormFieldNameEnum.warmWaterNumberOfPersonsPerResidence,
          'this needs warmWaterNumberOfPersonsPerResidence! case: isBuildingMultiFamily && isModeModernisation && isSourceHeatingLoadIsKnown with heating'
        )
      }

      return calculateHeatingLoadKnownLoadModernisationMultiFamilyWithWarmWater({
        hoursDisabled: hoursDisabled,
        energySavingOptions: energySavingCheckboxGroup,
        normTempOutside: normTempOutside,
        qWW: calculateWarmWaterHeatingLoadMultiFamilyHouse({
          warmWaterNumberOfPersonsPerResidence: warmWaterNumberOfPersonsPerResidence,
          numberOfResidentialUnits: numberOfResidentialUnits,
          isWithSolar: isWithSolar,
        }).kW,
        qH: qH,
      }).kW
    } else {
      return calculateHeatingLoadKnownLoadModernisationMultiFamily({
        hoursDisabled: hoursDisabled,
        energySavingOptions: energySavingCheckboxGroup,
        normTempOutside: normTempOutside,
        qH: qH,
      }).kW
    }
  }

  // #85
  if (isBuildingMultiFamily && isModeModernisation && isSourceHeatingLoadByConsumption) {
    if (!knownGasOrOilOrPowerConsume || knownGasOrOilOrPowerConsume < 1) {
      return invalidateFieldAndSendUserToStep(
        FormFieldNameEnum.knownGasOrOilOrPowerConsumeValue,
        'this needs knownGasOrOilOrPowerConsume! case: isBuildingMultiFamily - isModeModernisation - isSourceHeatingLoadByConsumption'
      )
    }
    if (!currentHeaterTypeValue) {
      return invalidateFieldAndSendUserToStep(
        FormFieldNameEnum.selectCurrentHeatingSystemType,
        'this needs currentHeaterTypeValue! case: isBuildingMultiFamily - isModeModernisation - isSourceHeatingLoadByConsumption'
      )
    }
    if (isHeatPumpGenerateWarmWater) {
      // with warm water
      if (typeof numberOfResidentialUnits === 'undefined' || numberOfResidentialUnits < 1) {
        return invalidateFieldAndSendUserToStep(
          FormFieldNameEnum.numberOfResidentialUnits,
          'this needs numberOfResidentialUnits! case: isBuildingMultiFamily - isModeModernisation - isSourceHeatingLoadByConsumption - sending user to step'
        )
      }
      if (typeof warmWaterNumberOfPersonsPerResidence === 'undefined' || warmWaterNumberOfPersonsPerResidence < 1) {
        return invalidateFieldAndSendUserToStep(
          FormFieldNameEnum.warmWaterNumberOfPersonsPerResidence,
          'this needs warmWaterNumberOfPersonsPerResidence! case: isBuildingMultiFamily - isModeModernisation - isSourceHeatingLoadByConsumption - sending user to step'
        )
      }
      return calculateHeatingLoadPumpModernisationKnownConsumeMultiFamilyWithWarmWater({
        hoursDisabled: hoursDisabled,
        currentHeaterType: currentHeaterTypeValue,
        energySavingOptions: energySavingCheckboxGroup,
        knownGasOrOilOrPowerConsume: knownGasOrOilOrPowerConsume,
        qWW: calculateWarmWaterHeatingLoadMultiFamilyHouse({
          warmWaterNumberOfPersonsPerResidence: warmWaterNumberOfPersonsPerResidence,
          numberOfResidentialUnits: numberOfResidentialUnits,
          isWithSolar: isWithSolar,
        }).kW,
      }).kW
    } else {
      // no warm water
      return calculateHeatingLoadPumpModernisationKnownConsumeMultiFamily({
        hoursDisabled: hoursDisabled,
        currentHeaterType: currentHeaterTypeValue,
        energySavingOptions: energySavingCheckboxGroup,
        knownGasOrOilOrPowerConsume: knownGasOrOilOrPowerConsume,
      }).kW
    }
  }

  if (isBuildingMultiFamily && isModeRebuild && isSourceHeatingLoadIsKnown) {
    if (typeof qH === 'undefined' || qH < 1) {
      return invalidateFieldAndSendUserToStep(
        FormFieldNameEnum.knownBuildingHeatingLoadValue,
        'this needs qH! case: isBuildingMultiFamily && isModeRebuild && isSourceHeatingLoadIsKnown - sending user to step'
      )
    }

    if (isHeatPumpGenerateWarmWater) {
      if (typeof numberOfResidentialUnits === 'undefined' || numberOfResidentialUnits < 1) {
        return invalidateFieldAndSendUserToStep(
          FormFieldNameEnum.numberOfResidentialUnits,
          'this needs numberOfResidentialUnits! case: isBuildingMultiFamily && isModeRebuild && isSourceHeatingLoadIsKnown with ww - sending user to step'
        )
      }
      if (typeof warmWaterNumberOfPersonsPerResidence === 'undefined' || warmWaterNumberOfPersonsPerResidence < 1) {
        return invalidateFieldAndSendUserToStep(
          FormFieldNameEnum.warmWaterNumberOfPersonsPerResidence,
          'this needs warmWaterNumberOfPersonsPerResidence! case: isBuildingMultiFamily && isModeRebuild && isSourceHeatingLoadIsKnown with ww - sending user to step'
        )
      }

      return calculateHeatingLoadKnownLoadMultiFamilyWithWarmWater({
        hoursDisabled: hoursDisabled,
        qWW: calculateWarmWaterHeatingLoadMultiFamilyHouse({
          warmWaterNumberOfPersonsPerResidence: warmWaterNumberOfPersonsPerResidence,
          numberOfResidentialUnits: numberOfResidentialUnits,
          isWithSolar: isWithSolar,
        }).kW,
        qH_BuildingHeatingLoad: qH,
      }).kW
    } else {
      return calculateHeatingLoadKnownLoadMultiFamily({
        hoursDisabled: hoursDisabled,
        qH_BuildingHeatingLoad: qH,
      }).kW
    }
  }

  if (isBuildingMultiFamily && isModeRebuild && isSourceHeatingLoadCalculation) {
    if (typeof qSpezValue === 'undefined' || qSpezValue < 1) {
      return invalidateFieldAndSendUserToStep(
        FormFieldNameEnum.buildingConstructionYear,
        'this needs qSpezValue! case: isBuildingMultiFamily && isModeRebuild && isSourceHeatingLoadCalculation - sending user to step'
      )
    }
    if (!habitableSpaceValue || habitableSpaceValue < 1) {
      return invalidateFieldAndSendUserToStep(
        FormFieldNameEnum.habitableSpace,
        'this needs habitableSpaceValue! case: isBuildingMultiFamily && isModeRebuild && isSourceHeatingLoadCalculation - sending user to step'
      )
    }
    if (isHeatPumpGenerateWarmWater) {
      if (!warmWaterNumberOfPersonsPerResidence || warmWaterNumberOfPersonsPerResidence < 1) {
        return invalidateFieldAndSendUserToStep(
          FormFieldNameEnum.warmWaterNumberOfPersonsPerResidence,
          'this needs warmWaterNumberOfPersonsPerResidence! case: isBuildingMultiFamily && isModeRebuild && isSourceHeatingLoadCalculation - sending user to step'
        )
      }
      if (!numberOfResidentialUnits || numberOfResidentialUnits < 1) {
        return invalidateFieldAndSendUserToStep(
          FormFieldNameEnum.numberOfResidentialUnits,
          'this needs numberOfResidentialUnits! case: isBuildingMultiFamily && isModeRebuild && isSourceHeatingLoadCalculation - sending user to step'
        )
      }
      return calculateHeatLoadNoKnownMultiFamilyWithWarmWater({
        normTempOutside: normTempOutside, // °C
        hoursDisabled: hoursDisabled,
        qSpez: qSpezValue, // W/m²
        habitableSpace: habitableSpaceValue, // m²
        qWW: calculateWarmWaterHeatingLoadMultiFamilyHouse({
          numberOfResidentialUnits: numberOfResidentialUnits,
          warmWaterNumberOfPersonsPerResidence: warmWaterNumberOfPersonsPerResidence,
          isWithSolar: isWithSolar,
        }).kW,
      }).kW
    } else {
      return calculateHeatLoadNoKnownMultiFamily({
        normTempOutside: normTempOutside, // °C
        hoursDisabled: hoursDisabled,
        qSpez: qSpezValue, // W/m²
        habitableSpace: habitableSpaceValue, // m²
      }).kW
    }
  }

  if (!isBuildingTypeNonResidentialBuilding) {
    return invalidateFieldAndSendUserToStep(
      FormFieldNameEnum.selectBuildingType,
      'invalid mode, should be nonResidentialBuilding at this point'
    )
  }

  // non residential
  if (!qH || qH < 1) {
    return invalidateFieldAndSendUserToStep(FormFieldNameEnum.habitableSpace, 'non residential building need known qH')
  }

  if (isHeatPumpGenerateWarmWater) {
    if (!warmWaterNumberHeatingLoadQwwDay || warmWaterNumberHeatingLoadQwwDay < 1) {
      return invalidateFieldAndSendUserToStep(
        FormFieldNameEnum.warmWaterNumberHeatingLoadQwwDay,
        'non residential building need known warmWaterNumberHeatingLoadQwwDay'
      )
    }
    if (!warmWaterNumberHeatingLoadQwwDayHours) {
      return invalidateFieldAndSendUserToStep(
        FormFieldNameEnum.warmWaterNumberHeatingLoadQwwDayHours,
        'non residential building need known warmWaterNumberHeatingLoadQwwDayHours'
      )
    }
    return calculateHeatingLoadNonResidentialWithWarmWater({
      hoursDisabled: hoursDisabled,
      qH_BuildingHeatingLoad: qH,
      qWWTag: warmWaterNumberHeatingLoadQwwDay,
      qWWTagHours: warmWaterNumberHeatingLoadQwwDayHours,
    }).kW
  } else {
    return calculateHeatingLoadNonResidential({
      hoursDisabled: hoursDisabled,
      qH_BuildingHeatingLoad: qH,
    }).kW
  }
}
