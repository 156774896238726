import { useField } from '@formiz/core'
import { UseFieldProps } from '@formiz/core/dist/types/field.types'
import { useEffect, useState } from 'react'
import AsyncSelect from 'react-select/async'
import { FormGroup, FormGroupPropsInterface } from './FormGroup'

interface SelectFieldAsyncPropsInterface extends UseFieldProps {
  label?: string
  errorMessage?: string
  helper?: string
  placeholder?: string
  showError?: boolean
  loadOptions?: (inputValue: string, callback: (options: object[]) => object[]) => Promise<unknown>
  defaultOptions?: boolean
}

export const SelectFieldAsync = (props: SelectFieldAsyncPropsInterface) => {
  const { errorMessage, id, isValid, isSubmitted, resetKey, setValue, value, otherProps } = useField(props)
  const { name, defaultValue, required, children, label, placeholder, helper, loadOptions } = otherProps
  const [isTouched, setIsTouched] = useState(false)
  const showError = !isValid && (isTouched || isSubmitted)

  useEffect(() => {
    setValue(defaultValue)
  }, [setValue, defaultValue])

  useEffect(() => {
    setIsTouched(false)
  }, [resetKey])

  const formGroupProps: FormGroupPropsInterface = {
    errorMessage,
    helper,
    id,
    isRequired: !!required,
    label,
    showError,
    children,
  }

  return (
    <FormGroup {...formGroupProps} className={'select__' + name}>
      <AsyncSelect
        name={name}
        value={value}
        placeholder={placeholder}
        cacheOptions
        loadOptions={loadOptions}
        aria-invalid={showError}
        aria-describedby={!isValid ? `${id}-error` : undefined}
        defaultOptions
        onBlur={() => setIsTouched(true)}
        onChange={(value) => {
          return setValue(value)
        }}
        required={required}
        isClearable={true}
        isSearchable={true}
        className={'select__field'}
        classNamePrefix={'select_field__'}
      />
    </FormGroup>
  )
}
