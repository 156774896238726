import { HeatingLoadBuildingResult } from '../../../calculateBuildingHeatLoad'
import { calculateFactorSperr } from '../../../factor/calculateFactorSperr'
import { getEnergySavingFactors } from '../../../factor/getEnergySavingFactors'
import { calculateLoadFromConsumedGas } from '../../../fromKnownConsume/calculateLoadFromConsumedGas'
import { calculateLoadFromConsumedOil } from '../../../fromKnownConsume/calculateLoadFromConsumedOil'
import { calculateLoadFromConsumedWp } from '../../../fromKnownConsume/calculateLoadFromConsumedWp'

interface PropertiesInterface {
  normTempOutside: number
  energySavingOptions: string[]
  hoursDisabled: number
  currentHeaterType?: 'gas' | 'oil' | 'wp' | 'other'
  currentWarmWaterHeaterType?: 'gas' | 'gas-solar' | 'oil' | 'oil-solar' | 'wp' | 'wp-solar' | 'water-heater' | 'other'
  currentWarmWaterHeatingSystemConsume?: number
  totalNumberOfPersons: number
  qH?: number
  knownGasOrOilOrPowerConsume?: number
  isHeatPumpGenerateWarmWater?: boolean
}

export const calculateHeatingLoadPumpModernisationSingleFamilyWithWarmWater = ({
  totalNumberOfPersons,
  normTempOutside,
  energySavingOptions,
  hoursDisabled,
  currentHeaterType,
  currentWarmWaterHeaterType,
  currentWarmWaterHeatingSystemConsume,
  qH,
  knownGasOrOilOrPowerConsume,
  isHeatPumpGenerateWarmWater,
}: PropertiesInterface): HeatingLoadBuildingResult => {
  console.log('calculateHeatingLoadPumpModernisationSingleFamilyWithWarmWater', {
    totalNumberOfPersons: totalNumberOfPersons,
    normTempOutside: normTempOutside,
    energySavingOptions: energySavingOptions,
    hoursDisabled: hoursDisabled,
    currentHeaterType: currentHeaterType,
    currentWarmWaterHeaterType: currentWarmWaterHeaterType,
    currentWarmWaterHeatingSystemConsume: currentWarmWaterHeatingSystemConsume,
    qH: qH,
    knownGasOrOilOrPowerConsume: knownGasOrOilOrPowerConsume,
    isHeatPumpGenerateWarmWater: isHeatPumpGenerateWarmWater,
  })
  const energySavingFactors = getEnergySavingFactors({ energySavingOptions, isMultiFamily: false })
  const fWindows = energySavingFactors.fWindows
  const fRoof = energySavingFactors.fRoof
  const fWalls = energySavingFactors.fWalls

  const fSperr = calculateFactorSperr({ hoursDisabled })

  const qwTag = 200 // [W]
  // QWW[W] = Pers.ges[Zahl] x qWTag[W]
  const qww = totalNumberOfPersons * qwTag

  let fww = 1
  if (
    isHeatPumpGenerateWarmWater ||
    currentWarmWaterHeaterType === 'wp-solar' ||
    currentWarmWaterHeaterType === 'oil-solar' ||
    currentWarmWaterHeaterType === 'gas-solar'
  ) {
    fww = 0.5
  }

  let resultQwp = 0

  // 2) kunde kennt gebäudeheizlast (qH) (1-50kW)
  // 5) kunde kennt gebäudeheizlast (qH) (1-50kW)
  // 8) kunde kennt gebäudeheizlast (qH) (1-50kW)
  // 11) kunde kennt gebäudeheizlast (qH) (1-50kW)
  if (qH && !knownGasOrOilOrPowerConsume) {
    resultQwp = fSperr * qH * fWindows * fRoof * fWalls * 1000 + qww * fww
    resultQwp = resultQwp / 1000
  }

  if ('gas' === currentHeaterType) {
    // 3) Ich kenne den Gasverbrauch m³ / 10,3kWh/m³ (500-30000 kWh/jahr)
    // bei Erdgas Mittelwert = 10,3 kWh/m³ -> -15% Verlust  ηKessel= 0,85  und tvoll = 2100 h/a
    if (!qH && knownGasOrOilOrPowerConsume) {
      resultQwp = calculateLoadFromConsumedGas({
        knownGasConsume: knownGasOrOilOrPowerConsume,
        fRoof: fRoof,
        fWalls: fWalls,
        fWindows: fWindows,
        fSperr: fSperr,
        tVoll: 2100,
      }).kW
    }
  }

  if ('oil' === currentHeaterType) {
    // 6)  Ich kenne den Ölverbrauch (500 - 8000 Liter/Jahr)
    // bei Heizöl EL = 10 kWh/l -> -15% Verlust ηKessel = 0,85 und tvoll = 2100 h/a
    if (knownGasOrOilOrPowerConsume) {
      resultQwp = calculateLoadFromConsumedOil({
        knownOilConsume: knownGasOrOilOrPowerConsume,
        fRoof: fRoof,
        fWalls: fWalls,
        fWindows: fWindows,
        fSperr: fSperr,
        tVoll: 2100,
      }).kW
    }
  }

  if ('wp' === currentHeaterType) {
    // 9) Ich kenne den Wärmepumpen - Stromverbrauch 500 - 25000 kWh/Jahr
    if (knownGasOrOilOrPowerConsume) {
      resultQwp = calculateLoadFromConsumedWp({ knownWpConsume: knownGasOrOilOrPowerConsume, tVoll: 2100 }).kW
    }
  }

  if ('other' === currentHeaterType) {
    // 12) Ich kenne den Wärmeenergieverbrauch - 500 - 80000 kWh/Jahr
    if (knownGasOrOilOrPowerConsume) {
      resultQwp = calculateLoadFromConsumedWp({ knownWpConsume: knownGasOrOilOrPowerConsume, tVoll: 2100 }).kW
    }
  }

  return {
    kW: parseFloat(resultQwp.toFixed(3)),
  }
}
