export const calculateWarmWaterHeatingPersons = ({
  warmWaterNumberOfPersonsPerResidence,
  numberOfResidentialUnits = 1,
}: {
  warmWaterNumberOfPersonsPerResidence: number
  numberOfResidentialUnits: number
}): number => {
  const result = warmWaterNumberOfPersonsPerResidence * numberOfResidentialUnits

  console.log('calculateWarmWaterHeatingPersons', {
    warmWaterNumberOfPersonsPerResidence: warmWaterNumberOfPersonsPerResidence,
    numberOfResidentialUnits: numberOfResidentialUnits,
    result: result,
  })
  return result
}
