import { scrollParentWindowToTop } from '@/features/helper/iFrameHelper'
import TranslationsHelper from '@/features/helper/translationsHelper'
import { ValueItemInterface } from '@/features/layout/content/GroupValuesCard'
import { extractValuesFromGroup } from '@/features/layout/content/GroupValuesGrid'
import { StepMatrixInterface, StepMatrixResultInterface } from '@/features/layout/footer/StepperWrapper'
import { getFormGroups } from '@/store/features/formGroupsSlice'
import { useAppSelector } from '@/store/store'
import { ArrowRightIcon } from '@chakra-ui/icons'
import {
  AspectRatio,
  Box,
  Link,
  List,
  ListIcon,
  ListItem,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  Stack,
} from '@chakra-ui/react'
import { useForm } from '@formiz/core'

interface PropertiesInterface {
  stepMatrix: StepMatrixInterface
}

export const StepperWithValueLinks = (props: PropertiesInterface) => {
  const spacing = 4
  const { stepMatrix } = props

  const form = useForm()
  const output = []
  const groups = useAppSelector(getFormGroups)

  const stepMatrixResult: StepMatrixResultInterface = {}
  form.steps?.map((step) => {
    for (const [groupId, value] of Object.entries(stepMatrix)) {
      if (value.stepNames.includes(step.name)) {
        if (typeof stepMatrixResult[value.groupName] === 'undefined') {
          stepMatrixResult[value.groupName] = []
        }
        stepMatrixResult[value.groupName].push({ step: step, groupId: parseInt(groupId) })
      }
    }
  })

  // build groups
  for (const [matrixGroupIdValue, matrixGroup] of Object.entries(stepMatrix)) {
    let isOneVisited = false
    for (const [groupName, stepsInGroup] of Object.entries(stepMatrixResult)) {
      if (groupName !== matrixGroup.groupName) {
        continue
      }
      stepsInGroup.every((stepItem) => {
        if (stepItem.step.isVisited) {
          isOneVisited = true
          return false
        }
        return true
      })
    }

    let isOneCurrent = false
    for (const [groupName, stepsInGroup] of Object.entries(stepMatrixResult)) {
      if (groupName !== matrixGroup.groupName) {
        continue
      }
      stepsInGroup.every((stepItem) => {
        if (stepItem.step.isCurrent) {
          isOneCurrent = true
          return false
        }
        return true
      })
    }

    const stepStepStatus = () => {
      if (isOneVisited && !isOneCurrent) {
        return 'isVisited'
      }
      if (isOneCurrent) {
        return 'isCurrent'
      }
      // if (!isOneVisited) {
      // }
      return 'isNotVisited'
    }
    const inactiveProps = !isOneVisited
      ? {
          'data-step': 'isInactive',
          bg: 'brand.100',
          color: 'brand.400',
        }
      : {}

    const visitedProps: object =
      isOneVisited && !isOneCurrent
        ? {
            'data-step': 'isVisited',
            bg: 'brand.100',
            color: 'brand.500',
            borderColor: 'currentColor',
            as: 'button',
            type: 'button',
            // onClick: () => form.goToStep(step.name),
            _hover: {
              bg: 'brand.100',
              color: 'brand.700',
              borderColor: 'brand.500',
            },
            _focus: {
              boxShadow: 'outline',
            },
          }
        : {}

    const currentProps = isOneCurrent
      ? {
          zIndex: 1,
          'data-step': 'isCurrent',
          bg: 'brand.500',
          color: 'white',
        }
      : {}

    // extract values
    const values: ValueItemInterface[] = extractValuesFromGroup(groups, matrixGroup, form)

    if (parseInt(matrixGroupIdValue) === 5) {
      values.push({
        name: matrixGroup.groupName,
        stepName: 'summary',
        fieldName: 'Ergebnis',
        value: 'none',
        isDisabled: !isOneVisited,
      })
    }
    output.push(
      <AspectRatio key={matrixGroupIdValue} w="5em" ratio={1} data-stepgroup={stepStepStatus()}>
        <>
          <Popover trigger={'hover'} placement={'top'}>
            <PopoverTrigger>
              <Box
                zIndex={0}
                className={'stepper-step'}
                data-step={stepStepStatus()}
                borderRadius="full"
                border="2px solid transparent"
                fontWeight={isOneCurrent || isOneVisited ? 'bold' : undefined}
                outline="none"
                fontSize="xs"
                overflow="visible"
                overflowWrap={'normal'}
                transition="0.2s"
                alignContent="center"
                _after={
                  parseInt(matrixGroupIdValue) !== 0
                    ? {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        mt: '-1px',
                        mr: '2px',
                        top: '50%',
                        right: '100%',
                        bg: isOneCurrent || isOneVisited ? 'brand.500' : 'brand.100',
                        h: '2px',
                        w: spacing,
                      }
                    : undefined
                }
                {...inactiveProps}
                {...visitedProps}
                {...currentProps}
              >
                {/*{groupName}*/}
              </Box>
            </PopoverTrigger>
            <Portal>
              <PopoverContent minW={'250px'}>
                <PopoverArrow />
                <PopoverHeader bgColor={'brand.500'} color={'#fff'} textTransform={'uppercase'} fontWeight={'bold'}>
                  {matrixGroup.groupName}
                </PopoverHeader>
                {values.length > 0 ? (
                  <PopoverBody>
                    <List spacing={2}>
                      {values.map((valueItem, index) => {
                        return (
                          <ListItem key={index} fontSize={'xs'} mb={2}>
                            <ListIcon as={ArrowRightIcon} color="brand.default" />
                            <Link
                              onClick={() => {
                                if (valueItem.isDisabled) {
                                  return
                                }

                                scrollParentWindowToTop()
                                return form.goToStep(valueItem.stepName)
                              }}
                            >
                              <Box as={'span'} className={'grid-item-headline'}>
                                <TranslationsHelper value={valueItem.fieldName} />
                              </Box>
                              {valueItem.value === 'none' ? ' ' : ': '}
                              <Box as={'span'} className={'grid-item-value'}>
                                <TranslationsHelper value={valueItem.value} />
                                {valueItem.unit ? valueItem.unit : ''}
                              </Box>
                            </Link>
                          </ListItem>
                        )
                      })}
                    </List>
                  </PopoverBody>
                ) : null}
              </PopoverContent>
            </Portal>
          </Popover>
        </>
      </AspectRatio>
    )
  }

  return (
    <Stack
      direction="row"
      display="flex"
      textAlign={'center'}
      alignItems="center"
      justifyContent="center"
      spacing={spacing}
      className={'stepper-steps-container'}
    >
      {output}
    </Stack>
  )
}
