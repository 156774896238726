import type { UseRadioProps } from '@chakra-ui/react'
import { Box, useRadio } from '@chakra-ui/react'
import React from 'react'

export interface RadioOption {
  imageSrc?: string
  imageComponent?: React.Component | string
  value: string
  label: string
  isDisabled?: boolean | undefined
}

export const RadioCard = (props: UseRadioProps & { children: React.ReactNode }) => {
  const { getInputProps, getCheckboxProps } = useRadio(props)
  const { isDisabled, name } = props

  const input = getInputProps()
  const checkbox = getCheckboxProps()

  return (
    <Box as="label" data-testid={name}>
      <input {...input} disabled={isDisabled} />
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      <Box
        {...checkbox}
        cursor="pointer"
        borderWidth="1px"
        borderRadius="md"
        boxShadow="md"
        _checked={{
          bg: 'brand.600',
          color: 'white',
          borderColor: 'brand.600',
        }}
        _focus={{
          boxShadow: 'outline',
        }}
        px={5}
        py={3}
      >
        {props.children}
      </Box>
    </Box>
  )
}
