interface PropertiesInterface {
  energySavingOptions: string[]
  isMultiFamily: boolean
}

interface ResultInterface {
  fWindows: number
  fRoof: number
  fWalls: number
}

export const getEnergySavingFactors = ({
  energySavingOptions,
  isMultiFamily = false,
}: PropertiesInterface): ResultInterface => {
  const checkInArray = (value: string) => energySavingOptions.some((arrayValue) => arrayValue == value)

  const fWindows = checkInArray('energySavingWindows') ? (isMultiFamily ? 0.8 : 0.85) : 1
  const fRoof = checkInArray('energySavingRoof') ? 0.86 : 1
  const fWalls = checkInArray('energySavingWalls') ? (isMultiFamily ? 0.75 : 0.81) : 1

  return {
    fWindows: fWindows,
    fRoof: fRoof,
    fWalls: fWalls,
  }
}
