import { HeatingLoadBuildingResult } from '../../../calculateBuildingHeatLoad'
import { calculateFactorSperr } from '../../../factor/calculateFactorSperr'
import { getEnergySavingFactors } from './../../../factor/getEnergySavingFactors'

interface PropertiesInterface {
  normTempOutside: number
  energySavingOptions: string[]
  hoursDisabled: number
  qH: number
  qWW: number
}

export const calculateHeatingLoadKnownLoadModernisationMultiFamilyWithWarmWater = ({
  normTempOutside,
  energySavingOptions,
  hoursDisabled,
  qH,
  qWW,
}: PropertiesInterface): HeatingLoadBuildingResult => {
  console.log('calculateHeatingLoadKnownLoadModernisationMultiFamilyWithWarmWater', {
    normTempOutside: normTempOutside,
    energySavingOptions: energySavingOptions,
    hoursDisabled: hoursDisabled,
    qH: qH,
    qWW: qWW,
  })
  // dämmung
  const energySavingFactors = getEnergySavingFactors({ energySavingOptions, isMultiFamily: true })
  const fWindows = energySavingFactors.fWindows
  const fRoof = energySavingFactors.fRoof
  const fWalls = energySavingFactors.fWalls

  const fSperr = calculateFactorSperr({ hoursDisabled })

  let resultQwp = 0
  console.debug({ qWW: qWW, qH: qH, fWindows: fWindows, fRoof: fRoof, fWalls: fWalls, fSperr: fSperr })
  resultQwp = (qH * 1000 * fWindows * fRoof * fWalls * fSperr) / 1000
  resultQwp += qWW

  return {
    kW: parseFloat(resultQwp.toFixed(3)),
  }
}
