export enum FormFieldNameEnum {
  selectProjectType = 'selectProjectType',
  selectBuildingType = 'selectBuildingType',
  numberOfResidentialUnits = 'numberOfResidentialUnits',
  buildingConstructionYear = 'buildingConstructionYear',
  energySavingCheckboxGroup = 'energySavingCheckboxGroup',
  knownNormTemp = 'knownNormTemp',
  knownGasOrOilOrPowerConsumeValueIncludesWarmWater = 'knownGasOrOilOrPowerConsumeValueIncludesWarmWater',
  knownGasOrOilOrPowerConsumeValue = 'knownGasOrOilOrPowerConsumeValue',
  selectUseToGenerateWarmWater = 'selectUseToGenerateWarmWater',
  warmWaterNumberOfPersonsPerBuilding = 'warmWaterNumberOfPersonsPerBuilding',
  warmWaterNumberOfPersonsPerResidence = 'warmWaterNumberOfPersonsPerResidence',
  warmWaterNumberHeatingLoadQwwDay = 'warmWaterNumberHeatingLoadQwwDay',
  warmWaterNumberHeatingLoadQwwDayHours = 'warmWaterNumberHeatingLoadQwwDayHours',
  selectCurrentUseOfSolarToGenerateWarmWater = 'selectCurrentUseOfSolarToGenerateWarmWater',
  selectCurrentWarmWaterHeatingSystemType = 'selectCurrentWarmWaterHeatingSystemType',
  selectCurrentWarmWaterHeatingSystemConsume = 'selectCurrentWarmWaterHeatingSystemConsume',
  selectCurrentHeatingSystemType = 'selectCurrentHeatingSystemType',
  heatingLoadSource = 'heatingLoadSource',
  normTemp = 'normTemp',
  selectUseSolarHeatingSupport = 'selectUseSolarHeatingSupport',
  selectUseWarmWaterSolarHeatingSupport = 'selectUseWarmWaterSolarHeatingSupport',
  selectUseAreaCooling = 'selectUseAreaCooling',
  knownBuildingHeatingLoadValue = 'knownBuildingHeatingLoadValue',
  habitableSpace = 'habitableSpace',
  energyEfficiency = 'energyEfficiency',
  selectUseToGenerateSolarEnergy = 'selectUseToGenerateSolarEnergy',
  selectUseEnergyWithTimeLocks = 'selectUseEnergyWithTimeLocks',
  energyWithTimeLocksHours = 'energyWithTimeLocksHours',
  normTempFinder = 'normTempFinder',
}
