import { EnergySavingCheckboxGroupValuesEnum } from '@/features/fields/EnergySavingCheckboxGroup'
import { FormFieldNameEnum } from '@/features/fields/FormFieldNameEnum'
import { GroupValuesCard, ValueItemInterface } from '@/features/layout/content/GroupValuesCard'
import { StepMatrixGroupsInterface, StepMatrixInterface } from '@/features/layout/footer/StepperWrapper'
import { GroupsInterface, getFormGroups } from '@/store/features/formGroupsSlice'
import { useAppSelector } from '@/store/store'
import { SimpleGrid } from '@chakra-ui/react'
import { useForm } from '@formiz/core'
import { UseFormValues } from '@formiz/core/dist/types/form.types'
import { isArray } from 'chart.js/helpers'

interface PropertiesInterface {
  stepMatrix: StepMatrixInterface
}

export const extractValuesFromGroup = (
  groups: GroupsInterface,
  matrixGroup: StepMatrixGroupsInterface,
  form: UseFormValues
): ValueItemInterface[] => {
  const checkValueIsUseLess = (value: unknown): boolean => {
    return typeof value === 'undefined' || null === value || '' === value
  }
  const values: ValueItemInterface[] = []
  for (const [stepName, stepFields] of Object.entries(groups)) {
    if (!matrixGroup.stepNames.includes(stepName)) {
      continue
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (typeof stepFields.fields !== 'object') {
      continue
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    for (const [index, fieldName] of Object.entries(stepFields.fields)) {
      if (typeof fieldName !== 'string') {
        continue
      }

      let flatValue = form.flatValues[fieldName]
      if (checkValueIsUseLess(flatValue)) {
        continue
      }

      if (fieldName === FormFieldNameEnum.knownNormTemp) {
        // do not display yes /no , only value
        continue
      }

      // units
      let unitOfValue = undefined
      if (
        fieldName === FormFieldNameEnum.energyWithTimeLocksHours ||
        fieldName === FormFieldNameEnum.warmWaterNumberHeatingLoadQwwDayHours
      ) {
        // todo add helper
        unitOfValue = 'h'
      }

      if (fieldName === FormFieldNameEnum.normTemp) {
        // todo add helper
        unitOfValue = '°C'
      }

      if (fieldName === FormFieldNameEnum.normTempFinder) {
        flatValue = flatValue.value
        unitOfValue = '°C'
      }

      if (
        fieldName === FormFieldNameEnum.knownBuildingHeatingLoadValue ||
        fieldName === FormFieldNameEnum.warmWaterNumberHeatingLoadQwwDay
      ) {
        // todo add helper
        unitOfValue = 'kW'
      }

      // special cases
      if (fieldName === FormFieldNameEnum.energySavingCheckboxGroup) {
        if (
          isArray(flatValue) &&
          flatValue.length === 1 &&
          flatValue[0] !== EnergySavingCheckboxGroupValuesEnum.hasEnergySavingNone
        ) {
          flatValue = 'yes'
        } else {
          flatValue = 'no'
        }
      }

      // add to values to group
      // console.debug(matrixGroup.groupName, index)
      // console.log(stepName, flatValue, fieldName, unitOfValue)

      values.push({
        name: matrixGroup.groupName,
        value: flatValue,
        stepName: stepName,
        fieldName: fieldName,
        unit: unitOfValue,
      })
    }
  }

  return values
}

export const GroupValuesGrid = (props: PropertiesInterface) => {
  const { stepMatrix } = props
  const form = useForm({ subscribe: 'fields' })

  const output = []

  const groups = useAppSelector(getFormGroups)
  // build groups
  for (const [matrixGroupIdValue, matrixGroup] of Object.entries(stepMatrix)) {
    if (parseInt(matrixGroupIdValue) === 5) {
      // skip summary
      continue
    }

    // extract values
    const values = extractValuesFromGroup(groups, matrixGroup, form)

    output.push(<GroupValuesCard key={matrixGroupIdValue} headline={matrixGroup.groupName} values={values} />)
  }

  return (
    <SimpleGrid
      spacing={4}
      className="heat-pump-step-summary-content"
      templateColumns="repeat(auto-fill, minmax(18%, 1fr))"
    >
      {output}
    </SimpleGrid>
  )
}
