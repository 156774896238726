import { HeatPumpInterface } from '@/store/features/heatPumpApiSlice'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react'

export interface HeatPumpPackageInterface {
  id: number
  productExternalId: string
  productId: number
  heatPumpExternalId: string
  heatPumpId: number
  heatRod: string
  filterOptionHeating: boolean
  filterOptionHeatingSupport: boolean
  filterOptionCooling: boolean
  filterOptionDrinkingWaterHeating: boolean
  filterOptionAdditionalDrinkingWaterHeating: boolean
  filterOptionTankLyingDown: boolean
  filterOptionMoreThanOneTank: boolean
  filterOptionPurePlusFreshWaterHeatExchanger: boolean
  filterOptionSgOptimized: boolean
  comment: string
  heatingRodPower: number
  heatingRodProductId: number
  maxPersonsDrinkingWater: number
}

export const heatPumpPackagesApiSlice = createApi({
  reducerPath: 'heatPumpSetsApiSlice',
  baseQuery: fetchBaseQuery({
    baseUrl: import.meta.env.VITE_API_ENDPOINT_HEAT_PUMP_SETS,
  }),
  tagTypes: ['HeatPumpSet'],
  keepUnusedDataFor: 999999,
  endpoints: (builder) => ({
    getHeatPumpPackages: builder.query<HeatPumpPackageInterface[], void>({
      query: () => {
        return {
          method: 'GET',
          url: '?page=',
          headers: {
            accept: 'application/json',
          },
        }
      },
      providesTags: (result) =>
        result
          ? [...result.map(({ id }) => ({ type: 'HeatPumpSet' as const, id })), { type: 'HeatPumpSet', id: 'LIST' }]
          : [{ type: 'HeatPumpSet', id: 'LIST' }],
    }),
    getHeatPumpPackagesByPumps: builder.query<HeatPumpPackageInterface[], HeatPumpInterface[]>({
      query: (heatPumps: HeatPumpInterface[]) => {
        return {
          method: 'GET',
          url: '?page=&filterHeatPumpExternalId=' + heatPumps.map((item) => item.oegNumber).join(','),
          headers: {
            accept: 'application/json',
          },
        }
      },
      providesTags: (result) =>
        result
          ? [...result.map(({ id }) => ({ type: 'HeatPumpSet' as const, id })), { type: 'HeatPumpSet', id: 'LIST' }]
          : [{ type: 'HeatPumpSet', id: 'LIST' }],
    }),
  }),
})

export const { useGetHeatPumpPackagesQuery, useGetHeatPumpPackagesByPumpsQuery } = heatPumpPackagesApiSlice
