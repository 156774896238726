import type { IntlShape } from 'react-intl'
import { injectIntl } from 'react-intl'
import { HeatPumpInterface, HeatPumpMatrixValueInterface } from '../../store/features/heatPumpApiSlice'
import {
  HeatPumpHeatingCapacityChart,
  HeatPumpHeatingCapacityChartDataMatrixInterface,
} from './HeatPumpHeatingCapacityChart'

interface DisplayOptionsInterface {
  showHeatPumpTempsAt35?: boolean
  showHeatPumpTempsAt45?: boolean
  showHeatPumpTempsAt55?: boolean
  showHeatPumpTempsAt70?: boolean
  showOnlyPump: string
}
const HeatPumpCharts = (props: {
  userInputHeatingLoad: number
  userInputNormTemp: number
  heatPumps: HeatPumpInterface[]
  options: DisplayOptionsInterface
  intl: IntlShape
}) => {
  // build matrix for chart
  const heatPumpTempsAt35: HeatPumpHeatingCapacityChartDataMatrixInterface[] = []
  const heatPumpTempsAt45: HeatPumpHeatingCapacityChartDataMatrixInterface[] = []
  const heatPumpTempsAt55: HeatPumpHeatingCapacityChartDataMatrixInterface[] = []
  const heatPumpTempsAt70: HeatPumpHeatingCapacityChartDataMatrixInterface[] = []

  const heatPumpChartDataSet_650001333: HeatPumpHeatingCapacityChartDataMatrixInterface[] = []
  const heatPumpChartDataSet_650001332: HeatPumpHeatingCapacityChartDataMatrixInterface[] = []
  const heatPumpChartDataSet_650001331: HeatPumpHeatingCapacityChartDataMatrixInterface[] = []
  const heatPumpChartDataSet_650001330: HeatPumpHeatingCapacityChartDataMatrixInterface[] = []

  const onlyMinimalLoadValues = (values: HeatPumpMatrixValueInterface[]) => {
    return values.filter((value) => value.isMinimalLoad)
  }

  const onlyNormalLoadValues = (values: HeatPumpMatrixValueInterface[]) => {
    return values.filter((value) => !value.isMinimalLoad)
  }

  const colors: { [key: string]: string } = {
    '650001333': 'rgb(91,0,0)',
    '650001332': 'rgb(220,69,69)',
    '650001331': 'rgb(71,110,58)',
    '650001330': 'rgb(2,25,99)',
  }

  const colorsLines: { [key: string]: string } = {
    '70': 'rgb(124,51,31)',
    '55': 'rgb(220,69,69)',
    '45': 'rgb(71,110,58)',
    '35': 'rgb(3,38,145)',
  }
  if (props.heatPumps.length) {
    props.heatPumps.map((pump) => {
      if (pump.oegNumber !== props.options.showOnlyPump) {
        return
      }

      // 35°
      if (props.options.showHeatPumpTempsAt35) {
        heatPumpTempsAt35.push({
          label: pump.oegNumber,
          heatPumps: onlyNormalLoadValues(pump.values['35']),
          borderColor: colors[pump.oegNumber],
          backgroundColor: colors[pump.oegNumber],
          useMinValueLineStyle: false,
        })

        heatPumpTempsAt35.push({
          label: pump.oegNumber,
          heatPumps: onlyMinimalLoadValues(pump.values['35']),
          borderColor: colors[pump.oegNumber],
          backgroundColor: colors[pump.oegNumber],
          useMinValueLineStyle: true,
        })
      }
      // 45°
      if (props.options.showHeatPumpTempsAt45) {
        heatPumpTempsAt45.push({
          label: pump.oegNumber,
          heatPumps: onlyNormalLoadValues(pump.values['45']),
          borderColor: colors[pump.oegNumber],
          backgroundColor: colors[pump.oegNumber],
          useMinValueLineStyle: false,
        })
        heatPumpTempsAt45.push({
          label: pump.oegNumber,
          heatPumps: onlyMinimalLoadValues(pump.values['45']),
          borderColor: colors[pump.oegNumber],
          backgroundColor: colors[pump.oegNumber],
          useMinValueLineStyle: true,
        })
      }

      // 55°
      if (props.options.showHeatPumpTempsAt55) {
        heatPumpTempsAt55.push({
          label: pump.oegNumber,
          heatPumps: onlyNormalLoadValues(pump.values['55']),
          borderColor: colors[pump.oegNumber],
          backgroundColor: colors[pump.oegNumber],
          useMinValueLineStyle: false,
        })
        heatPumpTempsAt55.push({
          label: pump.oegNumber,
          heatPumps: onlyMinimalLoadValues(pump.values['55']),
          borderColor: colors[pump.oegNumber],
          backgroundColor: colors[pump.oegNumber],
          useMinValueLineStyle: true,
        })
      }

      // 70°
      if (props.options.showHeatPumpTempsAt55) {
        heatPumpTempsAt70.push({
          label: pump.oegNumber,
          heatPumps: onlyNormalLoadValues(pump.values['70']),
          borderColor: colors[pump.oegNumber],
          backgroundColor: colors[pump.oegNumber],
          useMinValueLineStyle: false,
        })
        heatPumpTempsAt70.push({
          label: pump.oegNumber,
          heatPumps: onlyMinimalLoadValues(pump.values['70']),
          borderColor: colors[pump.oegNumber],
          backgroundColor: colors[pump.oegNumber],
          useMinValueLineStyle: true,
        })
      }

      // heatPumpChartDataSet_XXX
      if (pump.oegNumber === '650001333') {
        heatPumpChartDataSet_650001333.push({
          label: 'max 35°',
          heatPumps: onlyNormalLoadValues(pump.values['35']),
          borderColor: colorsLines['35'],
          backgroundColor: colorsLines['35'],
          useMinValueLineStyle: false,
        })
        heatPumpChartDataSet_650001333.push({
          label: 'max 45°',
          heatPumps: onlyNormalLoadValues(pump.values['45']),
          borderColor: colorsLines['45'],
          backgroundColor: colorsLines['45'],
          useMinValueLineStyle: false,
        })
        heatPumpChartDataSet_650001333.push({
          label: 'max 55°',
          heatPumps: onlyNormalLoadValues(pump.values['55']),
          borderColor: colorsLines['55'],
          backgroundColor: colorsLines['55'],
          useMinValueLineStyle: false,
        })
        heatPumpChartDataSet_650001333.push({
          label: 'max 70°',
          heatPumps: onlyNormalLoadValues(pump.values['70']),
          borderColor: colorsLines['70'],
          backgroundColor: colorsLines['70'],
          useMinValueLineStyle: false,
        })

        heatPumpChartDataSet_650001333.push({
          label: 'min 35°',
          heatPumps: onlyMinimalLoadValues(pump.values['35']),
          borderColor: colorsLines['35'],
          backgroundColor: colorsLines['35'],
          useMinValueLineStyle: true,
        })
        heatPumpChartDataSet_650001333.push({
          label: 'min 45°',
          heatPumps: onlyMinimalLoadValues(pump.values['45']),
          borderColor: colorsLines['45'],
          backgroundColor: colorsLines['45'],
          useMinValueLineStyle: true,
        })
        heatPumpChartDataSet_650001333.push({
          label: 'min 55°',
          heatPumps: onlyMinimalLoadValues(pump.values['55']),
          borderColor: colorsLines['55'],
          backgroundColor: colorsLines['55'],
          useMinValueLineStyle: true,
        })
        heatPumpChartDataSet_650001333.push({
          label: 'min 70°',
          heatPumps: onlyMinimalLoadValues(pump.values['70']),
          borderColor: colorsLines['70'],
          backgroundColor: colorsLines['70'],
          useMinValueLineStyle: true,
        })
      }
      if (pump.oegNumber === '650001332') {
        heatPumpChartDataSet_650001332.push({
          label: 'max 35°',
          heatPumps: onlyNormalLoadValues(pump.values['35']),
          borderColor: colorsLines['35'],
          backgroundColor: colorsLines['35'],
          useMinValueLineStyle: false,
        })
        heatPumpChartDataSet_650001332.push({
          label: 'max 45°',
          heatPumps: onlyNormalLoadValues(pump.values['45']),
          borderColor: colorsLines['45'],
          backgroundColor: colorsLines['45'],
          useMinValueLineStyle: false,
        })
        heatPumpChartDataSet_650001332.push({
          label: 'max 55°',
          heatPumps: onlyNormalLoadValues(pump.values['55']),
          borderColor: colorsLines['55'],
          backgroundColor: colorsLines['55'],
          useMinValueLineStyle: false,
        })
        heatPumpChartDataSet_650001332.push({
          label: 'max 70°',
          heatPumps: onlyNormalLoadValues(pump.values['70']),
          borderColor: colorsLines['70'],
          backgroundColor: colorsLines['70'],
          useMinValueLineStyle: false,
        })

        heatPumpChartDataSet_650001332.push({
          label: 'min 35°',
          heatPumps: onlyMinimalLoadValues(pump.values['35']),
          borderColor: colorsLines['35'],
          backgroundColor: colorsLines['35'],
          useMinValueLineStyle: true,
        })
        heatPumpChartDataSet_650001332.push({
          label: 'min 45°',
          heatPumps: onlyMinimalLoadValues(pump.values['45']),
          borderColor: colorsLines['45'],
          backgroundColor: colorsLines['45'],
          useMinValueLineStyle: true,
        })
        heatPumpChartDataSet_650001332.push({
          label: 'min 55°',
          heatPumps: onlyMinimalLoadValues(pump.values['55']),
          borderColor: colorsLines['55'],
          backgroundColor: colorsLines['55'],
          useMinValueLineStyle: true,
        })
        heatPumpChartDataSet_650001332.push({
          label: 'min 70°',
          heatPumps: onlyMinimalLoadValues(pump.values['70']),
          borderColor: colorsLines['70'],
          backgroundColor: colorsLines['70'],
          useMinValueLineStyle: true,
        })
      }
      if (pump.oegNumber === '650001331') {
        heatPumpChartDataSet_650001331.push({
          label: 'max 35°',
          heatPumps: onlyNormalLoadValues(pump.values['35']),
          borderColor: colorsLines['35'],
          backgroundColor: colorsLines['35'],
          useMinValueLineStyle: false,
        })
        heatPumpChartDataSet_650001331.push({
          label: 'max 45°',
          heatPumps: onlyNormalLoadValues(pump.values['45']),
          borderColor: colorsLines['45'],
          backgroundColor: colorsLines['45'],
          useMinValueLineStyle: false,
        })
        heatPumpChartDataSet_650001331.push({
          label: 'max 55°',
          heatPumps: onlyNormalLoadValues(pump.values['55']),
          borderColor: colorsLines['55'],
          backgroundColor: colorsLines['55'],
          useMinValueLineStyle: false,
        })
        heatPumpChartDataSet_650001331.push({
          label: 'max 70°',
          heatPumps: onlyNormalLoadValues(pump.values['70']),
          borderColor: colorsLines['70'],
          backgroundColor: colorsLines['70'],
          useMinValueLineStyle: false,
        })

        heatPumpChartDataSet_650001331.push({
          label: 'min 35°',
          heatPumps: onlyMinimalLoadValues(pump.values['35']),
          borderColor: colorsLines['35'],
          backgroundColor: colorsLines['35'],
          useMinValueLineStyle: true,
        })
        heatPumpChartDataSet_650001331.push({
          label: 'min 45°',
          heatPumps: onlyMinimalLoadValues(pump.values['45']),
          borderColor: colorsLines['45'],
          backgroundColor: colorsLines['45'],
          useMinValueLineStyle: true,
        })
        heatPumpChartDataSet_650001331.push({
          label: 'min 55°',
          heatPumps: onlyMinimalLoadValues(pump.values['55']),
          borderColor: colorsLines['55'],
          backgroundColor: colorsLines['55'],
          useMinValueLineStyle: true,
        })
        heatPumpChartDataSet_650001331.push({
          label: 'min 70°',
          heatPumps: onlyMinimalLoadValues(pump.values['70']),
          borderColor: colorsLines['70'],
          backgroundColor: colorsLines['70'],
          useMinValueLineStyle: true,
        })
      }
      if (pump.oegNumber === '650001330') {
        heatPumpChartDataSet_650001330.push({
          label: 'max 35°',
          heatPumps: onlyNormalLoadValues(pump.values['35']),
          borderColor: colorsLines['35'],
          backgroundColor: colorsLines['35'],
          useMinValueLineStyle: false,
        })
        heatPumpChartDataSet_650001330.push({
          label: 'max 45°',
          heatPumps: onlyNormalLoadValues(pump.values['45']),
          borderColor: colorsLines['45'],
          backgroundColor: colorsLines['45'],
          useMinValueLineStyle: false,
        })
        heatPumpChartDataSet_650001330.push({
          label: 'max 55°',
          heatPumps: onlyNormalLoadValues(pump.values['55']),
          borderColor: colorsLines['55'],
          backgroundColor: colorsLines['55'],
          useMinValueLineStyle: false,
        })
        heatPumpChartDataSet_650001330.push({
          label: 'max 70°',
          heatPumps: onlyNormalLoadValues(pump.values['70']),
          borderColor: colorsLines['70'],
          backgroundColor: colorsLines['70'],
          useMinValueLineStyle: false,
        })

        heatPumpChartDataSet_650001330.push({
          label: 'min 35°',
          heatPumps: onlyMinimalLoadValues(pump.values['35']),
          borderColor: colorsLines['35'],
          backgroundColor: colorsLines['35'],
          useMinValueLineStyle: true,
        })
        heatPumpChartDataSet_650001330.push({
          label: 'min 45°',
          heatPumps: onlyMinimalLoadValues(pump.values['45']),
          borderColor: colorsLines['45'],
          backgroundColor: colorsLines['45'],
          useMinValueLineStyle: true,
        })
        heatPumpChartDataSet_650001330.push({
          label: 'min 55°',
          heatPumps: onlyMinimalLoadValues(pump.values['55']),
          borderColor: colorsLines['55'],
          backgroundColor: colorsLines['55'],
          useMinValueLineStyle: true,
        })
        heatPumpChartDataSet_650001330.push({
          label: 'min 70°',
          heatPumps: onlyMinimalLoadValues(pump.values['70']),
          borderColor: colorsLines['70'],
          backgroundColor: colorsLines['70'],
          useMinValueLineStyle: true,
        })
      }
    })
  }

  return (
    <>
      {heatPumpTempsAt35.length > 0 ? (
        <HeatPumpHeatingCapacityChart
          chartTitle={''}
          normOutsideTemp={props.userInputNormTemp}
          heatingLoad={props.userInputHeatingLoad}
          heatPumpsTempMatrixSets={heatPumpTempsAt35}
          dynamicMaxY={false}
        />
      ) : null}
      {heatPumpTempsAt45.length > 0 ? (
        <HeatPumpHeatingCapacityChart
          chartTitle={''}
          normOutsideTemp={props.userInputNormTemp}
          heatingLoad={props.userInputHeatingLoad}
          heatPumpsTempMatrixSets={heatPumpTempsAt45}
          dynamicMaxY={false}
        />
      ) : null}
      {heatPumpTempsAt55.length > 0 ? (
        <HeatPumpHeatingCapacityChart
          chartTitle={''}
          normOutsideTemp={props.userInputNormTemp}
          heatingLoad={props.userInputHeatingLoad}
          heatPumpsTempMatrixSets={heatPumpTempsAt55}
          dynamicMaxY={false}
        />
      ) : null}
      {heatPumpTempsAt70.length > 0 ? (
        <HeatPumpHeatingCapacityChart
          chartTitle={''}
          normOutsideTemp={props.userInputNormTemp}
          heatingLoad={props.userInputHeatingLoad}
          heatPumpsTempMatrixSets={heatPumpTempsAt70}
          dynamicMaxY={false}
        />
      ) : null}
      {heatPumpChartDataSet_650001333.length > 0 ? (
        <HeatPumpHeatingCapacityChart
          chartTitle={''}
          normOutsideTemp={props.userInputNormTemp}
          heatingLoad={props.userInputHeatingLoad}
          heatPumpsTempMatrixSets={heatPumpChartDataSet_650001333}
          dynamicMaxY={true}
        />
      ) : null}
      {heatPumpChartDataSet_650001332.length > 0 ? (
        <HeatPumpHeatingCapacityChart
          chartTitle={''}
          normOutsideTemp={props.userInputNormTemp}
          heatingLoad={props.userInputHeatingLoad}
          heatPumpsTempMatrixSets={heatPumpChartDataSet_650001332}
          dynamicMaxY={true}
        />
      ) : null}
      {heatPumpChartDataSet_650001331.length > 0 ? (
        <HeatPumpHeatingCapacityChart
          chartTitle={''}
          normOutsideTemp={props.userInputNormTemp}
          heatingLoad={props.userInputHeatingLoad}
          heatPumpsTempMatrixSets={heatPumpChartDataSet_650001331}
          dynamicMaxY={true}
        />
      ) : null}
      {heatPumpChartDataSet_650001330.length > 0 ? (
        <HeatPumpHeatingCapacityChart
          chartTitle={''}
          normOutsideTemp={props.userInputNormTemp}
          heatingLoad={props.userInputHeatingLoad}
          heatPumpsTempMatrixSets={heatPumpChartDataSet_650001330}
          dynamicMaxY={true}
        />
      ) : null}
    </>
  )
}

export default injectIntl(HeatPumpCharts)
