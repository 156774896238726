import { FormizStep } from '@formiz/core'
import { UseFieldProps } from '@formiz/core/dist/types/field.types'
import { injectIntl } from 'react-intl'
import { IntlShape } from 'react-intl/src/types'
import IconYesRoomCooling from '../../assets/icons/oeg-konfigurator-icon-kuehlen.svg'
import IconYesAdditional from '../../assets/icons/oeg-konfigurator-icon-zusaetzlich.svg'

import { FormFieldNameEnum } from '@/features/fields/FormFieldNameEnum'
import { addFormGroup } from '@/store/features/formGroupsSlice'
import { setFilterOption } from '@/store/features/heatPumpSetFilterSlice'
import { useAppDispatch } from '@/store/store'
import { useEffect } from 'react'
import IconNo from '../../assets/icons/oeg-konfigurator-icon-nein.svg'
import { SelectSingleOptionWithRadioCards } from '../fields/SelectSingleOptionWithRadioCards'

interface StepChooseAdditionalFunctionalityPropsInterface extends UseFieldProps {
  intl: IntlShape
  isVisible: boolean
}

const StepChooseAdditionalFunctionality = (props: StepChooseAdditionalFunctionalityPropsInterface) => {
  const { name, isVisible, intl } = props
  const label = intl.formatMessage({ id: 'Optionale funktionen' })

  const dispatch = useAppDispatch()
  useEffect(() => {
    if (isVisible) {
      dispatch(
        addFormGroup({
          name: name,
          label: label,
          fields: [FormFieldNameEnum.selectUseSolarHeatingSupport, FormFieldNameEnum.selectUseAreaCooling],
        })
      )
    }
  }, [dispatch, isVisible, name, label])

  return (
    <FormizStep name={name} label={label} isEnabled={isVisible} data-testid={name}>
      <SelectSingleOptionWithRadioCards
        label={intl.formatMessage({
          id: 'Soll das System zur Nutzung einer Heizungsunterstützung (z.B. Solarthermie, Pelletheizung, ...) vorbereitet sein?',
        })}
        required={intl.formatMessage({
          id: 'Soll das System zur Nutzung einer Heizungsunterstützung (z.B. Solarthermie, Pelletheizung, ...) vorbereitet sein?',
        })}
        name={FormFieldNameEnum.selectUseSolarHeatingSupport}
        injectOnChange={(value) => {
          dispatch(
            setFilterOption({
              name: 'filterOptionHeatingSupport',
              value: value === 'yes',
            })
          )
        }}
        options={[
          {
            imageComponent: IconYesAdditional,
            value: 'yes',
            label: intl.formatMessage({ id: 'Ja' }),
          },
          {
            imageComponent: IconNo,
            value: 'no',
            label: intl.formatMessage({ id: 'Nein' }),
          },
        ]}
      />

      <SelectSingleOptionWithRadioCards
        label={intl.formatMessage({ id: 'Soll das System auch zur Raumkühlung verwendet werden?' })}
        required={intl.formatMessage({ id: 'Soll das System auch zur Raumkühlung verwendet werden?' })}
        name={FormFieldNameEnum.selectUseAreaCooling}
        injectOnChange={(value) => {
          dispatch(
            setFilterOption({
              name: 'filterOptionCooling',
              value: value === 'yes',
            })
          )
        }}
        options={[
          {
            imageComponent: IconYesRoomCooling,
            value: 'yes',
            label: intl.formatMessage({ id: 'Ja' }),
          },
          {
            imageComponent: IconNo,
            value: 'no',
            label: intl.formatMessage({ id: 'Nein' }),
          },
        ]}
      />
    </FormizStep>
  )
}

export default injectIntl(StepChooseAdditionalFunctionality)
