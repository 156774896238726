import { useEffect, useState } from 'react'

const PageVisibility = () => {
  const hasDocument = typeof document !== 'undefined'
  const isSupported = hasDocument && Boolean(document.addEventListener)

  const [isVisible, setIsVisible] = useState(document.visibilityState !== 'hidden')

  useEffect(() => {
    if (isSupported) {
      const handler = () => {
        setIsVisible(document.visibilityState !== 'hidden')
      }
      document.addEventListener('visibilitychange', handler)

      return () => {
        document.removeEventListener('visibilitychange', handler)
      }
    }
  }, [isSupported])

  return isVisible
}

export default PageVisibility
