import { useEffect, useState } from 'react'

const PageWillLeave = () => {
  const hasDocument = typeof document !== 'undefined'
  const isSupported = hasDocument && Boolean(document.addEventListener)

  const [willLeave, setWillLeave] = useState(false)

  useEffect(() => {
    if (isSupported) {
      const handler = () => {
        setWillLeave(true)
      }
      document.addEventListener('beforeunload', handler)

      return () => {
        document.removeEventListener('beforeunload', handler)
      }
    }
  }, [isSupported, setWillLeave])

  return willLeave
}

export default PageWillLeave
