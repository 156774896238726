import { StepMatrixInterface } from '@/features/layout/footer/StepperWrapper'
import type { FormValues } from '@formiz/core/src/types/form.types'

export const formResetter = (
  stepMatrix: StepMatrixInterface,
  currentValues: FormValues,
  stepGroupIdsToReset: number[],
  skipCurrentFieldName: string | undefined,
  value: string,
  callback: (newValues: FormValues, value: string) => void
) => {
  stepGroupIdsToReset.forEach((groupId) => {
    stepMatrix[groupId].fieldNames.forEach((fieldName) => {
      if (typeof currentValues[fieldName] !== 'undefined') {
        if (skipCurrentFieldName && fieldName !== skipCurrentFieldName) {
          currentValues[fieldName] = null
        }

        if (typeof skipCurrentFieldName === 'undefined') {
          currentValues[fieldName] = null
        }
      }
    })
  })

  callback(currentValues, value)
}
