import { dualPointRanges } from '@/features/heatPump/dualPointRanges'
import { HeatPumpInterface, HeatPumpMatrixCalculationValueInterface } from '@/store/features/heatPumpApiSlice'
import { getHeatingLoad, getNormTemperatur } from '@/store/features/userDataSlice'
import { useAppSelector } from '@/store/store'
import { calculateHeatPumpPowerConsume } from '../math/calculateHeatPumpPowerConsume'

export const FindRelevantHeatPumps = (props: { heatPumps: HeatPumpInterface[] }): HeatPumpInterface[] => {
  const userInputNormTemp = useAppSelector(getNormTemperatur)
  const userInputHeatingLoad = useAppSelector(getHeatingLoad)

  const { heatPumps } = props
  const dualPointRange = dualPointRanges({ normOutsideTempValue: userInputNormTemp })

  const relevantHeatPump: HeatPumpInterface[] = []

  heatPumps.map((heatPump: HeatPumpInterface) => {
    heatPump.valuesRelevantForPower.map((value: HeatPumpMatrixCalculationValueInterface) => {
      const calculatedHeatPumpPowerConsume = calculateHeatPumpPowerConsume({
        heatingCapacityInKwAtSub2Degrees: value.heatingCapacityAtSub2Degrees,
        heatingCapacityInKwAtSub7Degrees: value.heatingCapacityAtSub7Degrees,
        heatingCapacityInKwAtSub10Degrees: value.heatingCapacityAtSub10Degrees,
        heatingCapacityInKwAtSub15Degrees: value.heatingCapacityAtSub15Degrees,
        buildingHeatingLoad: userInputHeatingLoad,
        valueOfNormOutsideTemp: userInputNormTemp,
      })

      // console.log(heatPump.oegNumber, value.maxPreheatValue, calculatedHeatPumpPowerConsume)
      // Geeignete Kombination aus Wärmepumpe und Vorlauftemperatur (-7 < x < -2)
      // =WENN(UND(-7<$'Eingabe und Diagramme'.$Y$25;$'Eingabe und Diagramme'.$Y$25<-2);1;"")
      // -7 < x && X < -2 = OKOK
      if (calculatedHeatPumpPowerConsume.preHeatingLoad.x < dualPointRange.x1) {
        return null
      }
      if (calculatedHeatPumpPowerConsume.preHeatingLoad.x > dualPointRange.x2) {
        return null
      }

      //  Geeigneter Heizstab (0 < Pzusatz < 9)
      if (calculatedHeatPumpPowerConsume.pAdditional < 0) {
        return null
      }
      if (calculatedHeatPumpPowerConsume.pAdditional > 9) {
        return null
      }

      if (-1 === relevantHeatPump.findIndex((pump) => pump.oegNumber === heatPump.oegNumber)) {
        relevantHeatPump.push(heatPump)
      }
    })
  })

  return relevantHeatPump
}
