import HeatPumpSetFilterDataProvider from '@/features/heatPumpSet/HeatPumpSetFilterDataProvider'
import { GroupValuesGrid } from '@/features/layout/content/GroupValuesGrid'
import { StepMatrixInterface } from '@/features/layout/footer/StepperWrapper'
import DebugSummary from '@/features/summary/DebugSummary'
import InfoSummary from '@/features/summary/InfoSummary'
import { useGetHeatPumpsQuery } from '@/store/features/heatPumpApiSlice'
import { getHeatingLoad, isDebugEnabled } from '@/store/features/userDataSlice'
import { useAppSelector } from '@/store/store'
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Heading,
  Spinner,
  VisuallyHiddenInput,
} from '@chakra-ui/react'
import { FormattedMessage } from 'react-intl'

interface PropertiesInterface {
  stepMatrix: StepMatrixInterface
  isDesktop: boolean
}

const StepSummaryContent = (props: PropertiesInterface) => {
  const { stepMatrix, isDesktop } = props

  const heatingLoadValue = useAppSelector(getHeatingLoad)
  const debugEnabled = useAppSelector(isDebugEnabled)

  const { data: pumps, isLoading, isSuccess, isFetching } = useGetHeatPumpsQuery()

  if (!isSuccess || isLoading || isFetching) {
    return <Spinner />
  }

  return (
    <>
      <VisuallyHiddenInput
        data-testid={'heatingLoadResult'}
        name={'heatingLoadResultValue'}
        hidden={true}
        defaultValue={heatingLoadValue}
      />

      <Box border={'1px'} borderColor={'lightgrey'} className="heat-pump-step-summary-wrapper">
        <Accordion allowToggle={!isDesktop} defaultIndex={isDesktop ? 0 : undefined}>
          <AccordionItem>
            <AccordionButton>
              <Heading size={'xl'} flex="1" padding={'16px'} color={'brand.subHeadline'} textAlign={'center'}>
                <FormattedMessage id="Ihre Konfiguration" />
              </Heading>
            </AccordionButton>

            <AccordionPanel pb={4}>
              <GroupValuesGrid stepMatrix={stepMatrix} />
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </Box>

      <InfoSummary />
      <HeatPumpSetFilterDataProvider pumps={pumps} />

      {debugEnabled ? <DebugSummary /> : null}
    </>
  )
}

export default StepSummaryContent
