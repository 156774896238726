import { CheckIcon, WarningIcon } from '@chakra-ui/icons'
import {
  Box,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Heading,
  Input,
  InputGroup,
  InputRightElement,
  Spinner,
} from '@chakra-ui/react'
import { useField } from '@formiz/core'
import { UseFieldProps } from '@formiz/core/dist/types/field.types'
import React, { useEffect, useState } from 'react'

interface EditableFiledWithWrapperPropsInterface extends UseFieldProps {
  label: string
  headline?: string
  type: string
  placeholder?: string
  injectedOnChange?: (value: string | number) => void
  isDisabled?: boolean
  value?: string | number
  minValue?: number | string | undefined
  maxValue?: number | string | undefined
}

export const EditableFiledWithWrapper = (props: EditableFiledWithWrapperPropsInterface) => {
  const { errorMessage, id, isValid, isSubmitted, isValidating, isPristine, resetKey, setValue, value, otherProps } =
    useField(props)
  const { required, name, isDisabled, minValue, maxValue, placeholder, validations, ...rest } = props
  const { label, headline, type, helper, injectedOnChange } = otherProps
  const [isFocused, setIsFocused] = useState(false)
  const showError = !isValid && !isFocused && (!isPristine || isSubmitted)

  useEffect(() => {
    setIsFocused(false)
  }, [resetKey])

  const otherPropsFiltered = { ...rest }
  delete otherPropsFiltered.injectedOnChange

  const formGroupProps = {
    helper,
    id,
    isRequired: !!required,
    name,
    ...otherPropsFiltered,
  }

  return (
    <Box rounded={'none'} mb={7} className={'editable-field'}>
      {headline ? (
        <Heading
          mb={'5 !important'}
          fontSize="xl"
          className={'editable-field-headline-' + name}
          colorScheme={'brand'}
          color={'brand.headline'}
        >
          {headline}
        </Heading>
      ) : null}
      <FormControl
        variant="floating"
        mb="6"
        isInvalid={showError || (!isValid && !isPristine)}
        {...formGroupProps}
        data-testid={name}
        className={'editable-field'}
        rounded={'none'}
      >
        <InputGroup textAlign={'center'} border={isValid && !isFocused ? '2px solid #1c62a8' : ''}>
          <Input
            as={Input}
            textAlign={'center'}
            // placeholder=" "
            placeholder={placeholder}
            key={resetKey}
            id={id}
            name={name}
            // size={'lg'}
            // textAlign={'center'}
            type={type || 'text'}
            required={!!required}
            value={value ?? ''}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            validations={validations || undefined}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setValue(e.target.value)
              console.log(name, e.target.value)
              if (typeof injectedOnChange === 'function') {
                injectedOnChange(e.target.value)
              }
            }}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                setIsFocused(false)
              }
            }}
            colorScheme="brand"
            isDisabled={isDisabled ?? false}
            min={minValue || undefined}
            max={maxValue || undefined}
            aria-invalid={showError}
            aria-describedby={!isValid ? `${id}-error` : undefined}
            className={'editable-field__input'}
            rounded={'none'}
          />
          {/* It is important that the Label comes after the Control due to css selectors */}
          {!!label && (
            <FormLabel htmlFor={id} className={'editable-field__label'}>
              {label}
            </FormLabel>
          )}
          {!isFocused && isValid ? (
            <InputRightElement>
              <CheckIcon color="brand.checkIconColor" />
            </InputRightElement>
          ) : null}

          {!isPristine && !isFocused && !isValid ? (
            <InputRightElement>
              <WarningIcon mr="2" color={'red.500'} />
            </InputRightElement>
          ) : null}

          {(isFocused || isSubmitted) && isValidating ? (
            <InputRightElement>
              <Spinner size="sm" flex="none" />
            </InputRightElement>
          ) : null}
        </InputGroup>

        {helper ? <FormHelperText>{helper}</FormHelperText> : null}
        {showError ? (
          <FormErrorMessage id={`${id}-error`}>
            <WarningIcon mr="2" />
            {errorMessage}
          </FormErrorMessage>
        ) : null}
      </FormControl>
    </Box>
  )
}
