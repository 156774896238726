import { useEffect } from 'react'
import type { IntlShape } from 'react-intl'
import { FormattedMessage, injectIntl } from 'react-intl'

const NoIframeRedirect = (props: { intl: IntlShape; language: string }) => {
  const getLinkByLanguage = (language: string) => {
    switch (language.toLowerCase()) {
      case 'de':
        return 'https://www.oeg.net/de/tools/waermepumpe'
      case 'fr':
        return 'https://www.oeg.net/fr/tools/pompe-a-chaleur'
      case 'nl':
        return 'https://www.oeg.net/nl/tools/warmtepomp'
      case 'it':
        return 'https://www.oeg.net/it/tools/pompa-di-calore'
      default:
        return 'https://www.oeg.net/en/tools/heat-pump'
    }
  }

  const link = getLinkByLanguage(props.language)
  const seconds = 3

  useEffect(() => {
    const timeout = setTimeout(() => {
      // 👇️ redirects to an external URL
      window.location.replace(link)
    }, seconds * 1000)

    return () => clearTimeout(timeout)
  }, [link])

  return (
    <>
      <FormattedMessage id="Sie werden in {s} Sekunden weitergeleitet" values={{ s: seconds }} />
      ...
    </>
  )
}

export default injectIntl(NoIframeRedirect)
