import HeatPumpSetFilterDataProvider from '@/features/heatPumpSet/HeatPumpSetFilterDataProvider'
import { HeatPumpInterface, selectAllPumps, useGetHeatPumpsQuery } from '@/store/features/heatPumpApiSlice'
import { setHeatingLoad, setNormTemperatur } from '@/store/features/userDataSlice'
import { useAppDispatch, useAppSelector } from '@/store/store'
import { Center, Spinner } from '@chakra-ui/react'
import { useEffect } from 'react'

const AppDebug = () => {
  const { isLoading, isFetching } = useGetHeatPumpsQuery()

  const pumps: HeatPumpInterface[] | undefined = useAppSelector(selectAllPumps)
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(setNormTemperatur({ normTemp: -9 }))
    dispatch(setHeatingLoad({ heatingLoad: 8 }))
  }, [dispatch])

  if (isLoading || isFetching || typeof pumps === 'undefined' || pumps.length <= 0) {
    return (
      <Center h="calc(70vh)">
        <Spinner boxSize={100} thickness="10px" speed="0.85s" color="brand.500" />
      </Center>
    )
  }

  return <HeatPumpSetFilterDataProvider pumps={pumps} />
}

export default AppDebug
