import { HeatingLoadBuildingResult } from '../../../calculateBuildingHeatLoad'
import { calculateFactorSperr } from '../../../factor/calculateFactorSperr'
import { getEnergySavingFactors } from '../../../factor/getEnergySavingFactors'
import { calculateLoadFromConsumedGas } from '../../../fromKnownConsume/calculateLoadFromConsumedGas'
import { calculateLoadFromConsumedOil } from '../../../fromKnownConsume/calculateLoadFromConsumedOil'
import { calculateLoadFromConsumedWp } from '../../../fromKnownConsume/calculateLoadFromConsumedWp'

interface PropertiesInterface {
  normTempOutside: number
  energySavingOptions: string[]
  hoursDisabled: number
  currentHeaterType: 'gas' | 'oil' | 'wp' | 'other'
  knownGasOrOilOrPowerConsume: number
  qWW: number
}

export const calculateHeatingLoadPumpModernisationSingleFamilyWithWarmWaterFromKnownConsume = ({
  normTempOutside,
  energySavingOptions,
  hoursDisabled,
  currentHeaterType,
  knownGasOrOilOrPowerConsume,
  qWW,
}: PropertiesInterface): HeatingLoadBuildingResult => {
  console.log('calculateHeatingLoadPumpModernisationSingleFamilyWithWarmWaterFromKnownConsume', {
    normTempOutside: normTempOutside,
    energySavingOptions: energySavingOptions,
    hoursDisabled: hoursDisabled,
    currentHeaterType: currentHeaterType,
    knownGasOrOilOrPowerConsume: knownGasOrOilOrPowerConsume,
    qWW: qWW,
  })
  const energySavingFactors = getEnergySavingFactors({ energySavingOptions, isMultiFamily: false })
  const fWindows = energySavingFactors.fWindows
  const fRoof = energySavingFactors.fRoof
  const fWalls = energySavingFactors.fWalls

  const fSperr = calculateFactorSperr({ hoursDisabled })

  let resultQwp = 0

  if ('gas' === currentHeaterType) {
    // 3) Ich kenne den Gasverbrauch m³ / 10,3kWh/m³ (500-30000 kWh/jahr)
    // bei Erdgas Mittelwert = 10,3 kWh/m³ -> -15% Verlust  ηKessel= 0,85  und tvoll = 2100 h/a
    resultQwp = calculateLoadFromConsumedGas({
      knownGasConsume: knownGasOrOilOrPowerConsume,
      fRoof: fRoof,
      fWalls: fWalls,
      fWindows: fWindows,
      fSperr: fSperr,
      tVoll: 2100,
    }).kW
  }

  if ('oil' === currentHeaterType) {
    // 6)  Ich kenne den Ölverbrauch (500 - 8000 Liter/Jahr)
    // bei Heizöl EL = 10 kWh/l -> -15% Verlust ηKessel = 0,85 und tvoll = 2100 h/a
    resultQwp = calculateLoadFromConsumedOil({
      knownOilConsume: knownGasOrOilOrPowerConsume,
      fRoof: fRoof,
      fWalls: fWalls,
      fWindows: fWindows,
      fSperr: fSperr,
      tVoll: 2100,
    }).kW
  }

  if ('wp' === currentHeaterType) {
    // 9) Ich kenne den Wärmepumpen - Stromverbrauch 500 - 25000 kWh/Jahr
    resultQwp = calculateLoadFromConsumedWp({ knownWpConsume: knownGasOrOilOrPowerConsume, tVoll: 2100 }).kW
  }

  if ('other' === currentHeaterType) {
    // 12) Ich kenne den Wärmeenergieverbrauch - 500 - 80000 kWh/Jahr
    resultQwp = calculateLoadFromConsumedWp({ knownWpConsume: knownGasOrOilOrPowerConsume, tVoll: 2100 }).kW
  }

  // add warm water consume (kW)
  resultQwp += qWW

  return {
    kW: parseFloat(resultQwp.toFixed(3)),
  }
}
