import { HeatingLoadBuildingResult } from '@/features/math/calculateBuildingHeatLoad'
import { calculateFactorOutsideTempCorrection } from '@/features/math/factor/calculateFactorOutsideTempCorrection'
import { calculateFactorSperr } from '@/features/math/factor/calculateFactorSperr'
import { getEnergySavingFactors } from '@/features/math/factor/getEnergySavingFactors'

interface PropertiesInterface {
  normTempOutside: number
  habitableSpace: number
  qSpez: number
  hoursDisabled: number
  energySavingOptions: string[]
}

/**
 * 13) automatische Berechnung
 * 16) automatische Berechnung
 * 19) automatische Berechnung
 * 22) automatische Berechnung
 */
export const calculateHeatingLoadPumpByHabitableSpaceAndEnergyNeed = ({
  habitableSpace,
  qSpez,
  energySavingOptions,
  normTempOutside,
  hoursDisabled,
}: PropertiesInterface): HeatingLoadBuildingResult => {
  const energySavingFactors = getEnergySavingFactors({ energySavingOptions, isMultiFamily: false })
  const fWindows = energySavingFactors.fWindows
  const fRoof = energySavingFactors.fRoof
  const fWalls = energySavingFactors.fWalls

  const fSperr = calculateFactorSperr({ hoursDisabled })

  const fATKorr = calculateFactorOutsideTempCorrection({ normTempOutside: normTempOutside })

  const resultQwp = (qSpez * habitableSpace * fATKorr * fWindows * fRoof * fWalls * fSperr) / 1000

  return {
    kW: parseFloat(resultQwp.toFixed(3)),
  }
}
