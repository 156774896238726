/**
 * Korrektur des Einflusses der Normaußentemp. auf die Heizlast: QH
 * Die Abgefragte Normaußentemperatur muss auf den nächstgrößeren Zahlenwert gerundet werden. Bsp.: -10,5°C wird auf -11°C gerundet.
 * qspez wird für eine Normaußentemperatur von -16°C gerechnet. Bei wärmeren Außentemperaturen wir mit einem Korrekturfaktor gerechnet.
 * Dabei gilt: 1K Temperaturunterschied ergibt eine Verringerung der Heizleistung um 2%. Bei o.g. Bsp. wäre das Faktor fATKorr. = 0,9 || ((-16°C) - (-11°C)) = 5K  || 5x2 = 10% -> f=0,9
 *
 * @param normTempOutside {number}
 *
 * @return number
 */
export const calculateFactorOutsideTempCorrection = ({ normTempOutside }: { normTempOutside: number }): number => {
  const fixedBase = -16

  const k = (fixedBase - normTempOutside) * -1

  const rs = 1 - (k * 2) / 100

  return parseFloat(rs.toFixed(2))
}
