/// <reference types="vite-svg-loader" />
import { FormFieldNameEnum } from '@/features/fields/FormFieldNameEnum'
import { SelectNumberOfResidentialUnits } from '@/features/fields/SelectNumberOfResidentialUnits'
import { addFormGroup } from '@/store/features/formGroupsSlice'
import { useAppDispatch } from '@/store/store'
import { FormizStep, useForm } from '@formiz/core'
import { UseFieldProps } from '@formiz/core/dist/types/field.types'
import { useEffect } from 'react'
import { injectIntl } from 'react-intl'
import { IntlShape } from 'react-intl/src/types'
import IconBuildingTypeSingleFamilyHouse from '../../assets/icons/oeg-konfigurator-icon-einfamilienhaus.svg'
import IconBuildingTypeMultiFamilyHouse from '../../assets/icons/oeg-konfigurator-icon-mehrfamilienhaus.svg'
import IconBuildingTypeNonResidentialBuilding from '../../assets/icons/oeg-konfigurator-icon-nichtwohngebaeude.svg'
import { SelectSingleOptionWithRadioCards } from '../fields/SelectSingleOptionWithRadioCards'

export enum BuildingTypesKeysEnum {
  singleFamilyHouse = 'singleFamilyHouse',
  multiFamilyHouse = 'multiFamilyHouse',
  nonResidentialBuilding = 'nonResidentialBuilding',
}

interface StepChooseBuildingTypePropsInterface extends UseFieldProps {
  intl: IntlShape
  isVisible: boolean

  label: string
}

const StepChooseBuildingType = (props: StepChooseBuildingTypePropsInterface) => {
  const { name, label, isVisible, intl } = props
  const myForm = useForm()
  const dispatch = useAppDispatch()

  const isMultiFamilyBuilding =
    myForm.flatValues[FormFieldNameEnum.selectBuildingType] === BuildingTypesKeysEnum.multiFamilyHouse

  useEffect(() => {
    if (isVisible) {
      const fields = [FormFieldNameEnum.selectBuildingType]

      if (isMultiFamilyBuilding) {
        fields.push(FormFieldNameEnum.numberOfResidentialUnits)
      }

      dispatch(
        addFormGroup({
          name: name,
          label: label,
          fields: fields,
        })
      )
    }
  }, [dispatch, isVisible, name, label, isMultiFamilyBuilding])

  return (
    <FormizStep name={name} label={label} isEnabled={isVisible} data-testid={name}>
      <SelectSingleOptionWithRadioCards
        autoSubmitStepCallback={(value) =>
          value === BuildingTypesKeysEnum.singleFamilyHouse || value === BuildingTypesKeysEnum.nonResidentialBuilding
        }
        allowChange={false}
        label={intl.formatMessage({ id: 'Um welche Art Gebäude handelt es sich?' })}
        required={intl.formatMessage({ id: 'Bitte wählen' })}
        name={FormFieldNameEnum.selectBuildingType}
        options={[
          {
            imageComponent: IconBuildingTypeSingleFamilyHouse,
            value: BuildingTypesKeysEnum.singleFamilyHouse,
            label: intl.formatMessage({ id: 'Einfamilienhaus' }),
          },
          {
            imageComponent: IconBuildingTypeMultiFamilyHouse,
            value: BuildingTypesKeysEnum.multiFamilyHouse,
            label: intl.formatMessage({ id: 'Mehrfamilienhaus' }),
          },
          {
            imageComponent: IconBuildingTypeNonResidentialBuilding,
            value: BuildingTypesKeysEnum.nonResidentialBuilding,
            label: intl.formatMessage({ id: 'Nichtwohngebäude' }),
          },
        ]}
      />

      {isMultiFamilyBuilding ? <SelectNumberOfResidentialUnits intl={intl} /> : null}
    </FormizStep>
  )
}

export default injectIntl(StepChooseBuildingType)
