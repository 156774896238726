export const calculateWarmWaterHeatingLoadSingleFamilyHouse = ({
  warmWaterNumberOfPersonsPerBuilding: persons,
  isWithSolar,
}: {
  warmWaterNumberOfPersonsPerBuilding: number
  isWithSolar: boolean
}): { kW: number; W: number } => {
  // qWTag = 200W pP
  const qWTag = 200
  // single:  QWW[W] = Pers.ges[Zahl] x qWTag[W]
  let fWW = 1
  if (isWithSolar) {
    fWW = 0.5
  }
  const qWW = persons * qWTag * fWW

  return {
    W: qWW,
    kW: qWW / 1000,
  }
}
