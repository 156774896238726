import { IsDesktopDevice } from '@/features/layout/DetectDeviceParams'
import { StepMatrixInterface } from '@/features/layout/footer/StepperWrapper'
import StepSummaryContent from '@/features/summary/StepSummaryContent'
import { filtersAreUsed, resetUserFilter } from '@/store/features/heatPumpSetFilterSlice'
import { setHeatingLoad, setHeatingLoadOriginal, setNormTemperatur } from '@/store/features/userDataSlice'
import { useAppDispatch, useAppSelector } from '@/store/store'
import { useBoolean } from '@chakra-ui/react'
import { FormizStep, useForm } from '@formiz/core'
import { UseFieldProps } from '@formiz/core/dist/types/field.types'
import * as Sentry from '@sentry/browser'
import { useCallback, useEffect } from 'react'
import type { IntlShape } from 'react-intl'
import { calculateHeatingLoadFromFormValues } from '../heatingLoad/CalculateHeatingLoadFromFormValues'

interface StepSummaryPropsInterface extends UseFieldProps {
  intl: IntlShape
  stepMatrix: StepMatrixInterface
}

const StepSummary = (props: StepSummaryPropsInterface) => {
  const { name, stepMatrix, intl } = props
  const myFormInStep = useForm()
  const dispatch = useAppDispatch()
  const [isCalculated, setIsCalculated] = useBoolean(false)
  const isDesktop = IsDesktopDevice()

  const calculate = useCallback(() => {
    const extractNormTempFromFrom = (): number => {
      let normTempValue = null

      if (typeof myFormInStep.flatValues.normTemp === 'number') {
        normTempValue = myFormInStep.flatValues.normTemp
      } else if (
        myFormInStep.flatValues.normTempFinder &&
        typeof myFormInStep.flatValues.normTempFinder.value === 'string'
      ) {
        normTempValue = parseFloat(myFormInStep.flatValues.normTempFinder.value)
      }

      if (normTempValue === null) {
        Sentry.captureException(new Error('can not detect norm outside temperatur, fallback to -11.0'))
        normTempValue = -11.0
      }

      return normTempValue
    }
    const normTempValue: number = extractNormTempFromFrom()

    let heatingLoadValue: number = calculateHeatingLoadFromFormValues({
      myForm: myFormInStep,
      normTempOutside: normTempValue,
      intl: intl,
    })

    dispatch(setNormTemperatur({ normTemp: normTempValue }))
    dispatch(setHeatingLoadOriginal({ heatingLoad: heatingLoadValue }))

    // // SHOPM-2163 // fake crap to show results for new single-family houses
    if (heatingLoadValue <= 6.5) {
      heatingLoadValue = 6.5
    }
    dispatch(setHeatingLoad({ heatingLoad: heatingLoadValue }))

    setIsCalculated.on()
  }, [myFormInStep, dispatch, setIsCalculated, intl])

  useEffect(() => {
    if (myFormInStep.currentStep?.name === name) {
      console.log('useEffect calc', myFormInStep)
      calculate()
    }
  }, [myFormInStep, calculate, name])

  const filterWereUsedResult: boolean = useAppSelector(filtersAreUsed)

  useEffect(() => {
    return () => {
      // on component unmount reset filter (changes on finished configuration)
      if (myFormInStep.currentStep?.name === name && filterWereUsedResult) {
        console.info('[-] clear existing user filter: summary step')
        dispatch(resetUserFilter())
      }
    }
  }, [dispatch, filterWereUsedResult, myFormInStep, name])

  return (
    <FormizStep name={name} label={intl.formatMessage({ id: 'Zusammenfassung' })} isEnabled={true} data-testid={name}>
      {/* heizlast berechnen, pumpen filtern */}
      {myFormInStep.currentStep?.name === name && isCalculated ? (
        <StepSummaryContent stepMatrix={stepMatrix} isDesktop={isDesktop} />
      ) : null}
    </FormizStep>
  )
}

export default StepSummary
